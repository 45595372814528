import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './DestinationMobile.module.css';
import DestinationMobileHero from './DestinationMobileHero';
import DestinationHero from './DestinationHero';
import { rentalOptions } from '../../utils/constants';
import DestinationMobileGroup from './DestinationMobileGroup';
import AboutDestinationMobile from './AboutDestinationMobile';
import OtherDestinationsMobile from './OtherDestinationsMobile';
import AboutAirGarageSection from './AboutAirGarageSection';
import Faq from './FAQ';
import MetaTags from '../MetaTags/MetaTags';
import Amenities from '../Spots/Amenities';
import Reviews from './Reviews';
import Footer from './Footer';

const DestinationMobile = (props: any) => {
    const [activeSpot, setActiveSpot] = useState(null);
    const { getSearchResultCoords, coords, navigateToResults, rentalType, setRentalType, destination } = props;
    const { slug, imageUrl, secondImageUrl, name, address, spots, nearbyDestinations, description, reviews, cities } =
        destination;

    const spotsArray = spots;

    const screenSizeIsWeb = useMediaQuery('(min-width:1024px)');

    return (
        <div style={{ minWidth: '100vw !important' }}>
            <MetaTags
                title={`Find affordable and safe parking near ${name}`}
                description={`Find the cheapest parking lots around ${name}, ${address}. For Cheap, Monthly or Hourly parking, Airgarage has the best spots near ${name}`}
                url={`parking.airgarage.com/search/${slug}`}
                image={imageUrl}
            />
            {screenSizeIsWeb ? (
                <DestinationHero
                    name={name}
                    getSearchResultCoords={getSearchResultCoords}
                    setRentalType={setRentalType}
                    navigateToResults={navigateToResults}
                    coords={coords}
                    rentalType={rentalType}
                    spotsArray={spotsArray}
                    activeSpot={activeSpot}
                    setActiveSpot={setActiveSpot}
                />
            ) : (
                <DestinationMobileHero
                    name={name}
                    getSearchResultCoords={getSearchResultCoords}
                    setRentalType={setRentalType}
                    navigateToResults={navigateToResults}
                    coords={coords}
                    rentalType={rentalType}
                    spotsArray={spotsArray}
                    activeSpot={activeSpot}
                    setActiveSpot={setActiveSpot}
                />
            )}
            <div className={styles.container}>
                <Container maxWidth="lg">
                    <div className={styles.spotsGroup}>
                        <DestinationMobileGroup
                            title={'Top rated spots'}
                            subTitle={''}
                            spots={spots}
                            coords={coords}
                            rentalType={rentalOptions[0].value}
                        />

                        <AboutAirGarageSection />

                        <DestinationMobileGroup
                            title={destination.type === 'airport' ? 'Airport Parking' : 'Hourly Parking'}
                            subTitle={'Pay by the hour for shorter trips like going downtown or to the game.'}
                            spots={spots}
                            coords={coords}
                            rentalType={rentalOptions[0].value}
                        />

                        <Amenities />

                        <DestinationMobileGroup
                            title={'Monthly Parking'}
                            subTitle={'Get a longer term space perfect for school or work.'}
                            spots={spots}
                            coords={coords}
                            rentalType={rentalOptions[1].value}
                        />
                    </div>

                    <AboutDestinationMobile
                        title={name}
                        imgUrl={imageUrl}
                        aboutContent={description}
                        address={address}
                    />
                </Container>

                <section className={styles.coverImageSection}>
                    {secondImageUrl && (
                        <>
                            <img
                                src={secondImageUrl}
                                style={{ height: '300px', width: '100vw', objectFit: 'cover' }}
                                alt={`${name}-cover`}
                            />
                            <div className={styles.coverImageTextContainer}>
                                <h5 className={styles.coverImageText}>Easy, Quick and modern parking lots around</h5>
                                <h2 className={`${styles.coverImageText} ${styles.coverImageHeaderText}`}>{name}</h2>
                            </div>
                        </>
                    )}
                    <Reviews reviews={reviews} />
                </section>

                <Container maxWidth="lg">
                    <Faq destination={name} closestParkingSpot={spotsArray[0]} />
                    {nearbyDestinations && <OtherDestinationsMobile nearbyDestinations={nearbyDestinations} />}
                </Container>
                <Footer cities={cities} nearbyDestinations={nearbyDestinations} />
            </div>
        </div>
    );
};

export default DestinationMobile;
