import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Header, Grid, Button, Dropdown, Image } from 'semantic-ui-react';
import { getSearchResultCoordsAction, navigateToResultsAction } from 'actions/actions';
import { withRouter } from 'react-router-dom';
import SearchBar from 'components/SearchBar/SearchBar';
import fieldStyles from 'components/FormFields/FormFields.module.css';
import FeaturedCity from 'components/Spots/FeaturedCity';
import Footer from 'components/Footer/Footer';
import { rentalOptions } from 'utils/constants';
import MetaTags from 'components/MetaTags/MetaTags';

const SearchModuleContainer = (props: any) => {
    const { getSearchResultCoords, coords, navigateToResults } = props;
    const [rentalType, setRentalType] = useState('monthly');

    const cities = [
        { name: 'Los Angeles', image: require('assets/hollywood.png'), spots: 15 },
        { name: 'Omaha', image: require('assets/omaha.png'), spots: 14 },
        { name: 'Ann Arbor', image: require('assets/a2.png'), spots: 12 },
        { name: 'Tempe', image: require('assets/cacti.png'), spots: 21 },
    ];

    return (
        <>
            <Card fluid>
                <Grid padded stackable>
                    <MetaTags
                        title={'Find and Book Parking with AirGarage'}
                        description={
                            'AirGarage has partnered with thousands of parking facilities nationwide to help you find cheap hourly, daily, monthly, airport, and long term parking options.'
                        }
                    />

                    <Image src={require('assets/AirGarage.png')} style={{ height: 50 }} />
                    <Grid.Row centered>
                        <Grid.Column width={16}>
                            <Header as="h1" content="Find parking" textAlign="center" />
                            <p style={{ textAlign: 'center' }}>100s of spots available near you.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={2} />
                        <Grid.Column width={6}>
                            <Header as="h5" className={fieldStyles.fieldName}>
                                Location
                            </Header>
                            <SearchBar getSearchResultCoords={getSearchResultCoords} />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Header as="h5" className={fieldStyles.fieldName}>
                                Type
                            </Header>
                            <Dropdown
                                selection
                                fluid
                                placeholder="Monthly"
                                options={rentalOptions}
                                onChange={(e, data: any) => setRentalType(data.value)}
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Button
                                content="search"
                                fluid
                                onClick={() => navigateToResults(coords, rentalType)}
                                style={{ marginTop: 25 }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered>
                        <Grid.Column width={16}>
                            <Header as="h3" content="Popular searches" textAlign="center" />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={2} />
                        {cities.map((city, index) => {
                            return (
                                <FeaturedCity
                                    image={city.image}
                                    city={city.name}
                                    countOfSpots={city.spots}
                                    key={index}
                                    getSearchResultCoords={getSearchResultCoords}
                                    navigateToResults={navigateToResults}
                                    coords={coords}
                                />
                            );
                        })}
                    </Grid.Row>
                </Grid>
            </Card>
            <Footer />
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.search.errorMessage,
        loading: state.search.loading,
        coords: state.search.coords,
    };
}

const mapDispatchToProps = {
    getSearchResultCoords: getSearchResultCoordsAction,
    navigateToResults: navigateToResultsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchModuleContainer));
