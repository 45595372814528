import React, { ChangeEvent } from 'react';
import { Form, Header } from 'semantic-ui-react';
import styles from './ValidationCodeField.module.css';
import formStyles from '../FormFields.module.css';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
    handleInput: (event: ChangeEvent<HTMLInputElement>) => void;
    inputs: any;
    error: null | string;
    spot: string;
}

const ValidationCodeField: React.FC<Props> = ({ handleInput, inputs, error, spot }) => {
    return (
        <div>
            <Form.Field>
                <Header as="h5" className={formStyles.fieldName}>
                    Validation Code
                </Header>
                <div className={styles.validateCodeContainer}>
                    <p className={styles.retrieveCodeMessage}>Retrieve this code from {spot} staff</p>
                    <Form.Input
                        placeholder="Validation Code"
                        name="code"
                        onChange={handleInput}
                        required
                        value={inputs.code}
                        icon={'tag'}
                        iconPosition="left"
                        error={error}
                    />
                </div>
            </Form.Field>
        </div>
    );
};

export default ValidationCodeField;
