import { delay, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    ADD_CAR_TO_RENTAL_FAILED,
    ADD_CAR_TO_RENTAL_REQUESTED,
    ADD_CAR_TO_RENTAL_SUCCESS,
    AIRPORT_SPOTS_FAILED,
    AIRPORT_SPOTS_REQUESTED,
    AIRPORT_SPOTS_SUCCESS,
    APPLY_PROMO_CODE_FAILED,
    APPLY_PROMO_CODE_REQUESTED,
    APPLY_PROMO_CODE_SUCCESS,
    AUTHENTICATE_FAILED,
    AUTHENTICATE_REQUESTED,
    AUTHENTICATE_SUCCESS,
    AUTO_PROMO_FAILED,
    AUTO_PROMO_REQUESTED,
    AUTO_PROMO_SUCCESS,
    BLACKOUT_DATES_LIST_FAILED,
    BLACKOUT_DATES_LIST_REQUESTED,
    BLACKOUT_DATES_LIST_SUCCESS,
    BOOK_RENTAL_FAILED,
    BOOK_RENTAL_REQUESTED,
    BOOK_RENTAL_SUCCESS,
    CARD_UPDATE_FAILED,
    CARD_UPDATE_REQUESTED,
    CARD_UPDATE_SUCCESS,
    CHECK_IN_RENTAL_FAILED,
    CHECK_IN_RENTAL_REQUESTED,
    CHECK_IN_RENTAL_SUCCESS,
    CLEAR_SPOT_REQUESTED,
    CLEAR_SPOT_SUCCESS,
    CREATE_CUSTOM_INVOICE_FAILED,
    CREATE_CUSTOM_INVOICE_REQUESTED,
    CREATE_CUSTOM_INVOICE_SUCCESS,
    DESTINATION_FAILED,
    DESTINATION_LIST_FAILED,
    DESTINATION_LIST_REQUESTED,
    DESTINATION_LIST_SUCCESS,
    DESTINATION_REQUESTED,
    DESTINATION_SUCCESS,
    END_RENTAL_FAILED,
    END_RENTAL_REQUESTED,
    END_RENTAL_SUCCESS,
    ENTERPRISE_DETAILS_FAILED,
    ENTERPRISE_DETAILS_REQUESTED,
    ENTERPRISE_DETAILS_SUCCESS,
    KIOSK_RESTARTED,
    NAVIGATE_TO_RESULTS_FAILED,
    NAVIGATE_TO_RESULTS_REQUESTED,
    NAVIGATE_TO_RESULTS_SUCCESS,
    PROFILE_FAILED,
    PROFILE_REQUESTED,
    PROFILE_SUCCESS,
    REMOVE_CAR_FROM_RENTAL_FAILED,
    REMOVE_CAR_FROM_RENTAL_REQUESTED,
    REMOVE_CAR_FROM_RENTAL_SUCCESS,
    RENTAL_FAILED,
    RENTAL_REQUESTED,
    RENTAL_SUCCESS,
    RENTAL_BY_CONFIRMATION_ID_FAILED,
    RENTAL_BY_CONFIRMATION_ID_REQUESTED,
    RENTAL_BY_CONFIRMATION_ID_SUCCESS,
    RENTALS_FAILED,
    RENTALS_REQUESTED,
    RENTALS_SUCCESS,
    SEARCH_COORDS_FAILED,
    SEARCH_COORDS_REQUESTED,
    SEARCH_COORDS_SUCCESS,
    SEND_FEEDBACK_FAILED,
    SEND_FEEDBACK_REQUESTED,
    SEND_FEEDBACK_SUCCESS,
    SET_REMINDER_FAILED,
    SET_REMINDER_REQUESTED,
    SET_REMINDER_SUCCESS,
    SPOT_FAILED,
    SPOT_REQUESTED,
    SPOT_SUCCESS,
    SPOTS_FAILED,
    SPOTS_REQUESTED,
    SPOTS_SUCCESS,
    SUBMIT_CONTACT_FORM_FAILED,
    SUBMIT_CONTACT_FORM_REQUESTED,
    SUBMIT_CONTACT_FORM_SUCCESS,
    UPDATE_RENTAL_FAILED,
    UPDATE_RENTAL_REQUESTED,
    UPDATE_RENTAL_SUCCESS,
    USER_DETAILS_FAILED,
    USER_DETAILS_REQUESTED,
    USER_DETAILS_SUCCESS,
    VALIDATE_VISITOR_FAILED,
    VALIDATE_VISITOR_REQUESTED,
    VALIDATE_VISITOR_SUCCESS,
} from '../actions/constants';
import RentalService from '../services/RentalService';
import ReminderService from '../services/ReminderService';
import SpotsService from '../services/SpotsService';
import ProfileService from '../services/ProfileService';
import PromoCodeService from '../services/PromoCodeService';
import AuthService from '../services/AuthService';
import ContactFormService from '../services/ContactFormService';
import InvoiceService from '../services/InvoiceService';
import DestinationService from '../services/DestinationService';
import VisitorService from 'services/VisitorService';
import Cookies from 'js-cookie';
import { authTokenState, carsForRentalState, spotPkState, spotState } from '../selectors';
import CarService from '../services/CarService';
import PaymentService from '../services/PaymentService';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { getBlackoutDatesList, maxDate } from '../utils/helpers';
import { RentType } from '../utils/constants';
import { Car } from 'utils/types';
import LogRocketService from 'services/LogRocketService';
import ENV from 'env';

function assert(condition: boolean, failureMessage = 'Invalid information provided') {
    if (!condition) {
        throw Error(failureMessage);
    }
}

function* getSpots({ payload }: any) {
    const { coords, rentalType } = payload;
    try {
        const spots = yield SpotsService.getSpots(coords, rentalType);
        yield put({ type: SPOTS_SUCCESS, payload: { spots: spots } });
    } catch (error) {
        yield put({ type: SPOTS_FAILED, message: error.toString() || 'Failed' });
    }
}

function* getAirportSpots({ payload }: any) {
    const { classification } = payload;
    try {
        const spots = yield SpotsService.getAirportSpots(classification);
        yield put({ type: AIRPORT_SPOTS_SUCCESS, payload: { spots: spots } });
    } catch (error) {
        yield put({ type: AIRPORT_SPOTS_FAILED, message: error.toString() || 'Failed' });
    }
}

function* getSpot({ payload }: any) {
    try {
        const { id } = payload;
        const spot = yield SpotsService.getSpot(id);
        yield put({ type: SPOT_SUCCESS, payload: { spot: spot } });
    } catch (error) {
        yield put({ type: SPOT_FAILED, message: error.toString() || 'No spot found' });
        yield put(push('/spots'));
    }
}

function* clearSpot() {
    yield put({ type: CLEAR_SPOT_SUCCESS, payload: { spot: null } });
}

function* getRentals({ payload }: any) {
    try {
        const authToken = yield select(authTokenState);
        const { id, confirmationId } = payload;
        if (confirmationId) {
            const rentals: Promise<any> = yield RentalService.getRentalByConfirmationId(confirmationId, authToken);
            yield put({ type: RENTALS_SUCCESS, payload: { rentals: [rentals] } });
        } else if (id) {
            const rentals: Promise<any> = yield RentalService.getRental(id, authToken);
            yield put({ type: RENTALS_SUCCESS, payload: { rentals: rentals } });
        } else {
            const response: Response = yield RentalService.newGetRentals();
            if (response.status === 401) {
                yield put({ type: RENTALS_FAILED });
                yield put(push('/spots'));
            } else {
                const results = yield response.json();
                yield put({ type: RENTALS_SUCCESS, payload: { rentals: results['results'] } });
            }
        }
    } catch (error) {
        yield put({ type: RENTALS_FAILED, message: error.toString() || 'Failed' });
    }
}

function* getRental({ payload }: any) {
    try {
        const { id } = payload;
        if (id) {
            const rental = yield RentalService.getRental(id);
            yield put({ type: RENTAL_SUCCESS, payload: { rental: rental } });
        }
    } catch (error) {
        yield put({ type: RENTAL_FAILED, message: error.toString() || 'Failed' });
    }
}

function* getRentalByConfirmationId({ payload }: any) {
    try {
        const { confirmationId } = payload;
        if (confirmationId) {
            const rental = yield RentalService.getRentalByConfirmationId(confirmationId);
            const { confirmation_id, uuid, renter_phone, cars } = rental;
            yield put({ type: RENTAL_BY_CONFIRMATION_ID_SUCCESS, payload: { rental: rental } });
            if (renter_phone !== 'anonymous@user.com' && cars.length >= 1) {
                if (!confirmation_id) {
                    console.log('redirecting');
                    yield put(push('/?rental=' + uuid));
                }
            }
        }
    } catch (error) {
        yield put({ type: RENTAL_BY_CONFIRMATION_ID_FAILED, message: error.toString() || 'Failed' });
    }
}

function* getProfile({ payload }: any) {
    try {
        const authToken = yield Cookies.get('airgarage_auth');
        if (authToken) {
            const profile = yield ProfileService.getProfile(authToken);
            yield put({ type: PROFILE_SUCCESS, payload: { profile: profile } });
        } else {
            window.location.href = ENV.DASHBOARD_DOMAIN;
        }
    } catch (error) {
        Cookies.remove('airgarage_auth', { path: '/', expires: 2000 });
        Cookies.remove('airgarage_auth', { domain: 'airgara.ge', path: '/', expires: 2000 });
        Cookies.remove('airgarage_auth', { domain: 'airgarage.com', path: '/', expires: 2000 });

        yield put({ type: PROFILE_FAILED, message: error.toString() || 'Failed' });
    }
}

function* addCarToRental({ payload }: any) {
    try {
        const { rentalPk, car, editingCar } = payload;
        const authToken = yield Cookies.get('airgarage_auth');
        const existingCars: any = yield select(carsForRentalState);
        const newCar: any = yield CarService.getOrCreateCar(car, authToken);
        const carPks = [newCar.pk, ...existingCars[rentalPk]];
        yield updateRental({ payload: { pk: rentalPk, body: { cars: carPks, editing_car: editingCar } } });
        yield put({ type: ADD_CAR_TO_RENTAL_SUCCESS });
    } catch (error) {
        yield put({ type: ADD_CAR_TO_RENTAL_FAILED, message: error.toString() || 'Failed' });
    }
}

function* removeCarFromRental({ payload }: any) {
    try {
        const { rentalPk, carPk } = payload;
        const carsForRentals = yield select(carsForRentalState);
        const carPks = carsForRentals[rentalPk];
        const indexToRemove = carPks.indexOf(carPk);
        carPks.splice(indexToRemove, 1);
        yield updateRental({ payload: { pk: rentalPk, body: { cars: carPks } } });
        yield put({ type: REMOVE_CAR_FROM_RENTAL_SUCCESS });
    } catch (error) {
        yield put({ type: REMOVE_CAR_FROM_RENTAL_FAILED, message: error.toString() || 'Failed' });
    }
}

function* updateRental({ payload }: any) {
    try {
        const authToken = yield Cookies.get('airgarage_auth');
        const rentalKey = payload.pk;
        const data = { ...payload.body };
        yield RentalService.updateRental(rentalKey, data, authToken);
        const response = yield RentalService.newGetRentals();
        const { results } = yield response.json();
        yield put({ type: UPDATE_RENTAL_SUCCESS, payload: { rentals: results } });
    } catch (error) {
        yield put({ type: UPDATE_RENTAL_FAILED, message: error.toString() || 'Failed' });
    }
}

function* endRental({ payload }: any) {
    try {
        const authToken = yield Cookies.get('airgarage_auth');
        const rentalKey = payload.pk;
        yield RentalService.endRental(rentalKey, authToken);
        const rentals = yield RentalService.newGetRentals();
        const { results } = yield rentals.json();
        yield put({ type: END_RENTAL_SUCCESS, payload: { rentals: results } });
    } catch (error) {
        yield put({ type: END_RENTAL_FAILED, message: error.toString() || 'Failed' });
    }
}

function* bookRental({ payload }: any) {
    const { inputs, cardElement, stripe, cars, source, ref, shortTerm, rentalType } = payload;
    const spot = yield select(spotState);
    const spotPk = spot.pk;
    try {
        const authToken = yield Cookies.get('airgarage_auth');
        Sentry.setUser({ username: inputs.username });
        LogRocketService.identify(inputs.username);
        const startDate = inputs.start ? moment(inputs.start).unix() : null;
        Sentry.setContext('user plates', { username: inputs.username, plate: cars });
        const endDate = inputs.end ? moment(inputs.end).set({ hour: 12, minute: 0 }).unix() : null;
        const data: any = {
            promo: inputs.promo,
            username: inputs.username,
            spot: spotPk,
            start_date: startDate,
            end_date: endDate,
            source: source || 'webpay',
            ref: ref,
            short_term: shortTerm,
            rental_type: rentalType,
            token: null,
            phone: inputs.phone,
            phone_country_code: inputs.phoneCode,
        };
        if (cardElement) {
            const result = yield PaymentService.createToken(cardElement, stripe);
            assert(result.token, result.error);
            data.token = result.token.id;
        }
        const hasCar = cars.length > 0 && !(cars.length === 1 && cars[0].plate === '');
        const carsPk: string[] = hasCar ? yield CarService.getCarsPkFromPlates(cars, authToken) : [];
        data.cars = carsPk;
        const response = yield RentalService.bookRental(data, authToken);
        assert(response.uuid !== null);
        yield put({ type: BOOK_RENTAL_SUCCESS });
        yield put(push('/?rental=' + response.uuid));
        if (data.source === 'kiosk') {
            yield delay(20000);
            const payload = { pk: data.spot };
            yield put({ type: KIOSK_RESTARTED, payload: payload });
        }
    } catch (error) {
        yield put({ type: BOOK_RENTAL_FAILED, message: error.toString() || 'Error: Try again later!' });
    }
}

function* createCustomInvoice({ payload }: any) {
    const { inputs } = payload;
    const spotPk = yield select(spotPkState);
    try {
        const startDate = inputs.start ? moment(inputs.start).unix() : null;
        const endDate = inputs.end ? moment(inputs.end).set({ hour: 23, minute: 59 }).unix() : null;
        const source = inputs.source ? inputs.source : `${inputs.type}Form`;
        const billingType = inputs.type ? inputs.type : 'Log';
        const confirmationID = inputs.source && inputs.description.toUpperCase();
        const rentalType = inputs.is_enterprise
            ? RentType.enterprise
            : inputs.rental_type
            ? inputs.rental_type
            : inputs.type === 'Subscription'
            ? RentType.monthly
            : RentType.daily;
        const data: any = {
            commission: inputs.commission,
            description: inputs.description,
            price: inputs.price,
            spot: spotPk,
            start_date: startDate,
            end_date: endDate,
            source: source,
            billing_type: billingType,
            username: inputs.username,
            email: inputs.username,
            confirmation_id: confirmationID,
            rental_type: rentalType,
            enterprise_details: inputs.enterprise_detail,
            payment_methods: inputs.payment_methods,
        };
        const hasCar = inputs.cars.length > 0 && !(inputs.cars.length === 1 && inputs.cars[0].plate === '');
        const carsPk: string[] = hasCar ? yield CarService.getCarsPkFromPlates(inputs.cars) : [];
        data.cars = carsPk;
        const response = yield InvoiceService.createCustomInvoice(data);
        yield put({ type: CREATE_CUSTOM_INVOICE_SUCCESS, payload: { pk: response.pk } });
    } catch (error) {
        yield put({
            type: CREATE_CUSTOM_INVOICE_FAILED,
            message: error.toString() || 'Failed to create custom invoice',
        });
    }
}

function* getUserDetails({ payload }: any) {
    const { phone, pin } = payload;
    try {
        const authToken = yield AuthService.authenticate(phone, pin);
        const creditCard = yield PaymentService.getCreditCard(authToken);
        const car = yield CarService.getRecentPlate(authToken);
        if (authToken) {
            if (process.env.NODE_ENV === 'development') {
                Cookies.set('airgarage_auth', authToken, { path: '/', expires: 2000 });
            } else {
                Cookies.set('airgarage_auth', authToken, { domain: 'airgara.ge', path: '/', expires: 2000 });
                Cookies.set('airgarage_auth', authToken, { domain: 'airgarage.com', path: '/', expires: 2000 });
            }
        }
        yield put({ type: USER_DETAILS_SUCCESS, payload: { creditCard, car } });
    } catch (error) {
        yield put({ type: USER_DETAILS_FAILED, message: error.toString() || 'Failed' });
    }
}

function* authenticate({ payload }: any) {
    let username = payload.username;
    username = decodeURIComponent(username);
    const pin = payload.pin;
    const slot_uuid = payload.slot_uuid;
    try {
        const authToken = yield Cookies.get('airgarage_auth');
        const response = yield AuthService.authenticateOrSendNewLink(username, pin, slot_uuid);
        if (response.status === 410 && !authToken) {
            const { detail } = yield response.json();
            yield put({ type: AUTHENTICATE_FAILED, message: detail });
            yield put(push('/expired'));
        }

        if (response.ok) {
            const { token } = yield response.json();
            if (token) {
                if (process.env.NODE_ENV === 'development') {
                    Cookies.set('airgarage_auth', token, { path: '/', expires: 2000 });
                } else {
                    Cookies.set('airgarage_auth', token, { domain: 'airgara.ge', path: '/', expires: 2000 });
                    Cookies.set('airgarage_auth', token, { domain: 'airgarage.com', path: '/', expires: 2000 });
                }
            }
            yield put({ type: AUTHENTICATE_SUCCESS, payload: { token } });
        } else if (authToken) {
            yield put({ type: AUTHENTICATE_SUCCESS, payload: { token: authToken } });
        }
    } catch (error) {
        yield put({ type: AUTHENTICATE_FAILED, message: error.toString() || 'Failed' });
    }
}

function* restartKiosk({ payload }: any) {
    const { pk } = payload;
    Cookies.remove('airgarage_auth', { path: '/', expires: 2000 });
    Cookies.remove('airgarage_auth', { domain: 'airgara.ge', path: '/', expires: 2000 });
    Cookies.remove('airgarage_auth', { domain: 'airgarage.com', path: '/', expires: 2000 });
    yield put(push('/spot/' + pk + '?source=kiosk'));
}

function* updateCard({ payload }: any) {
    try {
        const { cardElement, stripe } = payload;
        const authToken = yield Cookies.get('airgarage_auth');
        const creditCard = yield PaymentService.addCreditCard(cardElement, stripe, authToken);
        yield put({ type: CARD_UPDATE_SUCCESS, payload: { creditCard } });
    } catch (error) {
        yield put({ type: CARD_UPDATE_FAILED, message: error.toString() || 'Failed to update card' });
    }
}

function* applyPromoCode({ payload }: any) {
    try {
        const { promoCode, rentalPk } = payload;
        const authToken = yield Cookies.get('airgarage_auth');
        yield PromoCodeService.applyPromoCode({ promo_code: promoCode, slotID: rentalPk }, authToken);
        const rentals = yield RentalService.getRental(rentalPk, authToken);
        yield put({ type: APPLY_PROMO_CODE_SUCCESS, payload: { rentals } });
    } catch (error) {
        yield put({ type: APPLY_PROMO_CODE_FAILED, message: error.toString() || 'Failed' });
    }
}

function* autoPromoCode({ payload }: any) {
    const { spotId, promo } = payload;
    const authToken = yield select(authTokenState);
    try {
        const response = yield PromoCodeService.autoPromoCode({ spot: spotId, promo: promo }, authToken);
        const result = yield response.json();
        if (response.status === 400) {
            if (result.data && result.data.slot_uuid) {
                yield put(push('/?rental=' + result.data.slot_uuid));
                yield put({
                    type: AUTO_PROMO_FAILED,
                    message: result.result.toString() || 'Failed',
                    payload: result.data,
                });
            } else if (result.data) {
                yield put(push(`/spot/${spotId}`));
                yield put({
                    type: AUTO_PROMO_FAILED,
                    message: result.result.toString() || 'Failed',
                    payload: result.data,
                });
            } else {
                yield put(push(`/spot/${spotId}`));
                yield put({ type: AUTO_PROMO_FAILED, message: result.toString() || 'Failed' });
            }
        }
        if (response.status === 429) {
            yield put(push(`/spot/${spotId}`));
            yield put({ type: AUTO_PROMO_FAILED, message: 'Too many requests.' });
        }
        if (response.status === 201) {
            yield put(push('/?rental=' + result.data.slot_uuid));
            yield put({ type: AUTO_PROMO_SUCCESS, payload: result.data });
        }
        if (response.status === 200) {
            yield put({ type: AUTO_PROMO_SUCCESS, payload: result.data });
            yield put(push(`/spot/${spotId}/?promo=${result.data.promo_code}`));
        }
    } catch (error) {
        yield put({ type: AUTO_PROMO_FAILED, message: error.toString() || 'Failed' });
        yield put(push(`/spot/${spotId}`));
    }
}

function* sendFeedback({ payload }: any) {
    const { renterPhone, feedback, spot, rating } = payload;
    try {
        const body = JSON.stringify({
            text:
                rating +
                '-star Review' +
                '\nSource: Webpay ' +
                '\nLot: ' +
                spot +
                '\nUser: ' +
                renterPhone +
                '\nFeedback: ' +
                feedback,
        });
        yield RentalService.sendFeedback(body, rating);
        yield put({ type: SEND_FEEDBACK_SUCCESS });
    } catch (error) {
        yield put({ type: SEND_FEEDBACK_FAILED, message: error.toString() || 'Failed to send feedback' });
    }
}

function* setReminder({ payload }: any) {
    const { minutes, pk } = payload;
    try {
        const data = { time: minutes, slot: pk };
        const authToken = yield Cookies.get('airgarage_auth');
        yield ReminderService.setReminder(data, authToken);
        yield put({ type: SET_REMINDER_SUCCESS, payload: { minutes } });
    } catch (error) {
        console.log(error);
        yield put({ type: SET_REMINDER_FAILED, message: error.toString() || 'Failed to set reminder' });
    }
}

function* submitContactForm({ payload }: any) {
    try {
        const authToken = yield Cookies.get('airgarage_auth');
        yield ContactFormService.submitContactForm(payload, authToken);
        yield put({ type: SUBMIT_CONTACT_FORM_SUCCESS });
    } catch (error) {
        yield put({ type: SUBMIT_CONTACT_FORM_FAILED, message: error.toString() || 'Failed submit contact form.' });
    }
}

function* getSearchResultCoords({ payload }: any) {
    const { description } = payload;
    try {
        const results = yield geocodeByAddress(description);
        const coords = yield getLatLng(results[0]);
        yield put({ type: SEARCH_COORDS_SUCCESS, payload: { coords } });
    } catch (error) {
        yield put({ type: SEARCH_COORDS_FAILED, message: error.toString() || 'Failed to get coordinates' });
    }
}

function* navigateToResults({ payload }: any) {
    const { coords, rentalType } = payload;
    try {
        yield put({ type: NAVIGATE_TO_RESULTS_SUCCESS });
        yield put(
            push(
                `/spots/?lat=${coords.lat}&lng=${coords.lng}&rentalType=${
                    rentalType === undefined ? 'hourly ' : rentalType
                }`
            )
        );
    } catch (error) {
        yield put({ type: NAVIGATE_TO_RESULTS_FAILED, message: error.toString() || 'Failed to navigate' });
    }
}

function* getDestinationDetail({ payload }: any) {
    const { slug } = payload;

    try {
        const destination = yield DestinationService.getDestinationDetail(slug);
        yield put({ type: DESTINATION_SUCCESS, payload: { destination } });
    } catch (error) {
        yield put({ type: DESTINATION_FAILED, message: error.toString() || 'Failed to get destination' });
    }
}

function* getDestinationList() {
    try {
        const destinations = yield DestinationService.getDestinationList();
        yield put({ type: DESTINATION_LIST_SUCCESS, payload: { destinations } });
    } catch (error) {
        yield put({ type: DESTINATION_LIST_FAILED, message: error.toString() || 'Failed to load destinations' });
    }
}

function* checkInRental({ payload }: any) {
    const { inputs, rental } = payload;
    try {
        const authToken = yield Cookies.get('airgarage_auth');
        const carData: Car = inputs.cars[0];
        const car = yield CarService.getOrCreateCar(carData, authToken);
        const response = yield RentalService.updateRental(
            rental.pk,
            { cars: [car.pk], username: inputs.username },
            authToken,
            `confirmation_id=${rental.confirmation_id}`
        );
        yield put(push(`/?rental=${response.uuid}&confirmation_id=${rental.confirmation_id}`));
        yield put({ type: CHECK_IN_RENTAL_SUCCESS });
    } catch (error) {
        yield put({ type: CHECK_IN_RENTAL_FAILED, message: error.toString() || 'Failed' });
    }
}

function* getBlackoutDates({ payload }: any): Generator<any, any, any> {
    const { id } = payload;
    try {
        const bookedDates = yield getBlackoutDatesList(id);
        const minEndDate = bookedDates && bookedDates.length ? maxDate(bookedDates) : new Date();
        yield put({
            type: BLACKOUT_DATES_LIST_SUCCESS,
            payload: { bookedDates: bookedDates || [], minEndDate: minEndDate },
        });
    } catch (error) {
        yield put({
            type: BLACKOUT_DATES_LIST_FAILED,
            message: error.toString() || 'getBlackoutDates fetching failed!',
        });
    }
}

function* validateVisitor({ payload }: any): any {
    const { code, plate, phone, country, spotPk, state, validation_source } = payload;
    try {
        const visitor: any = { phone, spot: spotPk, codes: [code], validation_source, plate, state, country };
        const result = yield VisitorService.addVisitor(visitor);
        yield put({
            type: VALIDATE_VISITOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: VALIDATE_VISITOR_FAILED,
            message: error.toString() || 'Something went wrong, please try again or contact support center',
        });
    }
}

function* getEnterpriseDetails(): any {
    try {
        const result = yield InvoiceService.getEnterpriseDetails();
        yield put({
            type: ENTERPRISE_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ENTERPRISE_DETAILS_FAILED,
            message: error.toString() || 'Something went wrong, please try again or contact support center',
        });
    }
}
function* fetchSaga() {
    yield takeEvery(RENTALS_REQUESTED, getRentals);
    yield takeEvery(RENTAL_REQUESTED, getRental);
    yield takeEvery(RENTAL_BY_CONFIRMATION_ID_REQUESTED, getRentalByConfirmationId);
    yield takeEvery(SPOTS_REQUESTED, getSpots);
    yield takeEvery(AIRPORT_SPOTS_REQUESTED, getAirportSpots);
    yield takeEvery(SPOT_REQUESTED, getSpot);
    yield takeEvery(CLEAR_SPOT_REQUESTED, clearSpot);
    yield takeEvery(PROFILE_REQUESTED, getProfile);
    yield takeEvery(UPDATE_RENTAL_REQUESTED, updateRental);
    yield takeEvery(END_RENTAL_REQUESTED, endRental);
    yield takeEvery(BOOK_RENTAL_REQUESTED, bookRental);
    yield takeEvery(CREATE_CUSTOM_INVOICE_REQUESTED, createCustomInvoice);
    yield takeEvery(USER_DETAILS_REQUESTED, getUserDetails);
    yield takeEvery(KIOSK_RESTARTED, restartKiosk);
    yield takeEvery(CARD_UPDATE_REQUESTED, updateCard);
    yield takeEvery(APPLY_PROMO_CODE_REQUESTED, applyPromoCode);
    yield takeEvery(AUTO_PROMO_REQUESTED, autoPromoCode);
    yield takeEvery(REMOVE_CAR_FROM_RENTAL_REQUESTED, removeCarFromRental);
    yield takeEvery(ADD_CAR_TO_RENTAL_REQUESTED, addCarToRental);
    yield takeEvery(SEND_FEEDBACK_REQUESTED, sendFeedback);
    yield takeEvery(SET_REMINDER_REQUESTED, setReminder);
    yield takeEvery(SUBMIT_CONTACT_FORM_REQUESTED, submitContactForm);
    yield takeEvery(SEARCH_COORDS_REQUESTED, getSearchResultCoords);
    yield takeEvery(NAVIGATE_TO_RESULTS_REQUESTED, navigateToResults);
    yield takeEvery(DESTINATION_LIST_REQUESTED, getDestinationList);
    yield takeEvery(DESTINATION_REQUESTED, getDestinationDetail);
    yield takeEvery(CHECK_IN_RENTAL_REQUESTED, checkInRental);
    yield takeEvery(BLACKOUT_DATES_LIST_REQUESTED, getBlackoutDates);
    yield takeEvery(VALIDATE_VISITOR_REQUESTED, validateVisitor);
    yield takeEvery(AUTHENTICATE_REQUESTED, authenticate);
    yield takeEvery(ENTERPRISE_DETAILS_REQUESTED, getEnterpriseDetails);
}

export default fetchSaga;
