import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import styles from './SpotInfo.module.css';

const SearchHeader = (props: any) => {
    return (
        <>
            <Grid.Row>
                <Grid.Column width={10}>
                    <a href="https://airgarage.com/find-parking" className={styles.link}>
                        <Icon name="arrow left" /> Search
                    </a>
                </Grid.Column>
                <Grid.Column width={6}></Grid.Column>
            </Grid.Row>
        </>
    );
};

export default SearchHeader;
