import APIClient from './APIClient';

class AuthService extends Object {
    async authenticate(phone: string, pin: string) {
        const data = { username: phone, password: pin };
        const { token } = await APIClient.post(`auth/`, data);
        return token;
    }

    async authenticateOrSendNewLink(username: string, pin: string, slot_uuid: string) {
        const data = { username, password: pin, slot_uuid };
        const response = await APIClient.newPost(`auth_with_pin/`, data);
        return response;
    }
}

export default new AuthService();
