import React from 'react';
import { Grid, Image } from 'semantic-ui-react';

const AggregatorLogos = (props: any) => {
    return (
        <Grid padded>
            <Grid.Row columns={5}>
                <Grid.Column>
                    <Image centered size="small" src={require('../../assets/cap.png')} />
                </Grid.Column>
                <Grid.Column>
                    <Image centered size="mini" src={require('../../assets/spothero.png')} />
                </Grid.Column>
                <Grid.Column>
                    <Image centered size="small" src={require('../../assets/apr.png')} />
                </Grid.Column>
                <Grid.Column>
                    <Image centered size="tiny" src={require('../../assets/onair.png')} />
                </Grid.Column>
                <Grid.Column>
                    <Image centered size="tiny" src={require('../../assets/rwp.png')} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
export default AggregatorLogos;
