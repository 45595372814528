import React, { useEffect } from 'react';
import { Card, Divider, Message, Icon, Image, Grid, Button } from 'semantic-ui-react';
import SpotHeader from '../SpotHeader/SpotHeader';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from '../Footer/Footer';
import WebpayForm from './WebpayForm';
import { withRouter } from 'react-router-dom';
import { cleanExistingURLParams, optionsGenerator, shouldApplySurge, isLotClosed } from '../../utils/helpers';
import MetaTags from '../MetaTags/MetaTags';
import { RentType } from '../../utils/constants';
import { AG_HELP_CENTER_LINK } from '../../utils/constants';
import * as Sentry from '@sentry/react';

interface InitialInputs {
    phone: string;
    plates: Array<string>;
    start: Date | null;
    end: Date | null;
    promo: string;
}

const Webpay = (props: any) => {
    const rentalType = new URLSearchParams(props.location.search).get('rentalType');
    const {
        monthly,
        daily,
        monthlySoldOut,
        spot,
        source,
        referrer,
        phoneCode,
        airportCheckin,
        promoSuccessMessage,
        promoErrorMessage,
    } = props;
    const monthlyRental = monthly;
    const dailyRental = daily;
    const hourlyRental = !(rentalType === RentType.monthly || rentalType === RentType.daily);
    const kiosk = source === 'kiosk';
    const freeParking = spot?.dynamic_price === 0 && !monthlyRental;
    const currentReferrer = props.location.search.includes(referrer) ? '' : referrer;
    const rateOptions = optionsGenerator(spot, currentReferrer);

    useEffect(() => {
        Sentry.setTag('rental_type', rentalType);
    }, [spot, rateOptions, rentalType]);

    useEffect(() => {
        if (spot) {
            Sentry.setTag('spot_pk', spot.pk);
            Sentry.setTag('spot_uuid', spot.uuid);
        }
    }, [spot]);

    const buttonsGenerator: JSX.Element[] = rateOptions.map((option) => {
        const sameRentalType = rentalType === option.value;
        const flatRental = option.value === RentType.flatRate && hourlyRental;
        const existingURLParams = cleanExistingURLParams(props.location.search);
        const url = option.link.concat(existingURLParams);

        return (
            <Button
                active={flatRental || sameRentalType}
                as={'span'}
                key={option.text}
                style={{ fontSize: 10 }}
                onClick={() => {
                    props.history.push(url);
                }}
            >
                {option.buttonTitle}
            </Button>
        );
    });

    return (
        <>
            <MetaTags spot={spot} />
            <Grid columns={1} padded>
                <Grid.Column>
                    {kiosk && <Image src={require('assets/airwhite.png')} centered />}

                    <Card centered fluid={kiosk}>
                        <SpotHeader
                            spot={props.spot}
                            monthly={monthlyRental}
                            kiosk={kiosk}
                            daily={dailyRental}
                            referrer={referrer}
                        />
                        {promoSuccessMessage && (
                            <Message info color="green" size="tiny" header="Coupon code applied successfully" />
                        )}

                        {promoErrorMessage && (
                            <Message
                                info
                                color="red"
                                size="tiny"
                                header="Coupon code is not valid."
                                content="Please try a different coupon code."
                            />
                        )}

                        {/* Lot closure and surge messages */}
                        {!monthlyRental && props.spot?.closure_today && props.spot?.available > 0 && (
                            <Message info size="tiny" header={props.spot.closure_today} />
                        )}
                        {shouldApplySurge(props.spot, hourlyRental) && (
                            <Message
                                info
                                color="pink"
                                size="tiny"
                                header="Lot overbooked or reserved"
                                content="This lot owner is reserving remaining spaces for visitors. Prices are higher than usual"
                            />
                        )}
                        {isLotClosed(props.spot, hourlyRental) && (
                            <Message
                                info
                                color="pink"
                                size="tiny"
                                header="Lot is closed - remaining spaces are reserved"
                                content="Cars that park here will be subject to towing"
                            />
                        )}
                        {monthlySoldOut && (
                            <Message
                                info
                                color="pink"
                                size="tiny"
                                header="Monthly passes sold out"
                                content="The parking lot is sold out of monthly passes. You can still pay by the hour below."
                            />
                        )}

                        {freeParking ? (
                            <Message
                                info
                                color="green"
                                size="tiny"
                                header="Free parking"
                                content="Parking is free today. You're good to go!"
                            />
                        ) : (
                            <WebpayForm
                                monthlyRental={monthlyRental}
                                dailyRental={dailyRental}
                                hourlyRental={hourlyRental}
                                phone={props.phone}
                                defaultCar={props.defaultCar}
                                spot={props.spot}
                                promo={props.promo}
                                creditCard={props.creditCard}
                                bookRental={props.bookRental}
                                authLoading={props.authLoading}
                                error={props.error}
                                source={props.source}
                                referrer={props.referrer}
                                successMessage={props.successMessage}
                                phoneCode={phoneCode}
                                airportCheckin={airportCheckin}
                            />
                        )}

                        {!kiosk && (
                            <>
                                <Divider />
                                <a rel="noopener noreferrer" href={AG_HELP_CENTER_LINK} target="_blank">
                                    <Icon name="info circle" /> Get help{' '}
                                </a>
                                <br />
                            </>
                        )}
                    </Card>
                    {!kiosk && buttonsGenerator.length > 1 && (
                        <Card centered>
                            <h3>Available options:</h3>
                            <Card.Meta>
                                <Button.Group size="mini" compact fluid>
                                    {buttonsGenerator}
                                </Button.Group>
                            </Card.Meta>
                        </Card>
                    )}
                    {!kiosk && <Footer />}
                </Grid.Column>
            </Grid>
        </>
    );
};

export default withRouter(Webpay);
