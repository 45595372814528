import React from 'react';
import logo from '../../assets/share.png';
import styles from './VisitorForm.module.css';

interface VisitorHeaderProps {
    spotName: string;
}

const VisitorHeader = ({ spotName }: VisitorHeaderProps) => {
    return (
        <div className={styles.visitorHeaderContainer}>
            <img src={logo} className={styles.visitorHeaderLogo} alt="logo" />
            <h3 className={styles.visitorHeaderTitle}>Visitor Validation</h3>
            <p style={{ fontWeight: 700 }}>{spotName}</p>
        </div>
    );
};

export default VisitorHeader;
