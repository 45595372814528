import React from 'react';
import { Grid, Image, Header } from 'semantic-ui-react';

const FeaturedCity = (props: any) => {
    const { image, city, countOfSpots, getSearchResultCoords, navigateToResults, coords, rentalType } = props;

    const getCityResults = () => {
        getSearchResultCoords(city);
        if (coords) {
            navigateToResults(coords, rentalType);
        }
    };

    return (
        <Grid.Column width={3} centered>
            <a href="# " onClick={() => getCityResults()}>
                <Image src={image} rounded />
                <Header as="h2" content={city} textAlign="center" />
                <Header as="h4" content={`${countOfSpots} spots available`} textAlign="center" color="grey" />
            </a>
        </Grid.Column>
    );
};

export default FeaturedCity;
