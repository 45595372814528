import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Header, Image } from 'semantic-ui-react';
import Paper from '@material-ui/core/Paper';
import styles from './AboutAirgarageSection.module.css';

const AboutAirGarageSection = () => {
    return (
        <Paper variant={'outlined'} className={styles.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Image
                        className={styles.airGarageImage}
                        src={
                            'https://assets.website-files.com/5d55f1425cb6b7a18aa77528/60ad7c7bb4dc18e48c224f8e_Image%20from%20iOS%20(5)-p-500.jpeg'
                        }
                        alt={'air-garage-parking-lot'}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <div style={{ padding: '2em' }}>
                        <Header as={'h2'} size={'huge'}>
                            About AirGarage
                        </Header>
                        <p className={styles.aboutAirGarageContent}>
                            AirGarage was founded in 2017 to help find people find parking. Whether you need hourly,
                            monthly, or airport parking, you can trust AirGarage to get you to your final destination.
                            <br />
                            <br />
                            AirGarage modernizes your parking assets with one streamlined service covering enforcement,
                            payment collection, maintenance and more.
                        </p>
                        <Grid container>
                            <Grid item xs={4}>
                                <Header as={'h6'} size={'huge'} textAlign={'center'}>
                                    Over 1M
                                </Header>
                                <p className={styles.statsText}>Rentals</p>
                            </Grid>
                            <Grid item xs={4}>
                                <Header as={'h6'} size={'huge'} textAlign={'center'}>
                                    Over 170
                                </Header>
                                <p className={styles.statsText}>Live cities</p>
                            </Grid>
                            <Grid item xs={4}>
                                <Header as={'h6'} size={'huge'} textAlign={'center'}>
                                    4.8
                                </Header>
                                <p className={styles.statsText}>Average rating</p>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default AboutAirGarageSection;
