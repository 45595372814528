import React, { useState } from 'react';
import { Popup, Icon, Divider } from 'semantic-ui-react';

interface Props {
    cars: any[];
    carPkOrIndex: number;
    removeCar: (carPkOrIndex: number) => void;
    setEditing: (editing: boolean) => void;
    setSelectedCarIndexOrPk: (carPkOrIndex: number) => void;
}

const CarOptions = (props: Props) => {
    const { cars, carPkOrIndex, removeCar, setEditing, setSelectedCarIndexOrPk } = props;
    const [openPopup, setOpenPopup] = useState(false);

    return (
        <Popup
            on="click"
            pinned
            position="bottom center"
            trigger={<Icon name="pencil" style={{ float: 'right' }} />}
            open={openPopup}
            onOpen={() => setOpenPopup(true)}
            onClose={() => setOpenPopup(false)}
        >
            <Popup.Content>
                <a
                    href="# "
                    onClick={() => {
                        setEditing(true);
                        setSelectedCarIndexOrPk(carPkOrIndex);
                        setOpenPopup(false);
                    }}
                    style={{ color: 'black' }}
                >
                    Edit
                </a>
                {cars.length > 1 && (
                    <>
                        <Divider />
                        <a
                            href="# "
                            onClick={() => {
                                removeCar(carPkOrIndex);
                                setOpenPopup(false);
                            }}
                            style={{ color: 'red' }}
                        >
                            Delete
                        </a>
                    </>
                )}
            </Popup.Content>
        </Popup>
    );
};

export default CarOptions;
