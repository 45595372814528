import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import styles from './SpotInfo.module.css';

const IconLabel = (props: any) => {
    const { icon, text } = props;

    return (
        <Card>
            <Card.Content textAlign="center">
                <Icon name={icon} size="big" color="orange" />
                <h3 className={styles.listItems}>{text}</h3>
            </Card.Content>
        </Card>
    );
};

export default IconLabel;
