import React, { useState } from 'react';
import { Grid, Icon, Input, Message, Divider } from 'semantic-ui-react';

const PromoField = (props: any) => {
    const { inputs, rental, handleInput, applyPromoCode, successMessage, error } = props;
    const prefilledPromo = inputs && !!inputs.promo;
    const [showField, setShowField] = useState(prefilledPromo);
    const [promo, setPromo] = useState('');
    const addPromo = () => {
        if (rental) {
            applyPromoCode(promo, rental.pk);
        }
    };

    return rental?.promo_code ? null : (
        <>
            <Divider hidden />
            <a href="# " onClick={() => setShowField(!showField)}>
                <Icon name="tag" />
                Have a coupon code?
                <br></br>
            </a>
            <br></br>
            {showField && (
                <>
                    <Grid.Row>
                        <Input
                            icon={rental ? <Icon name="check" color="green" link onClick={() => addPromo()} /> : null}
                            placeholder="Coupon"
                            fluid
                            name="promo"
                            value={rental ? promo : inputs.promo}
                            onChange={rental ? (event) => setPromo(event.target.value) : handleInput}
                        />
                    </Grid.Row>
                    <br></br>
                </>
            )}
            {successMessage && <Message color="green"> {successMessage} </Message>}
            {error && <Message info size="tiny" color="red" header="Coupon Error" content={error} />}
        </>
    );
};

export default PromoField;
