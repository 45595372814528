import React from 'react';
import { Message } from 'semantic-ui-react';
import moment from 'moment';

const ProjectedPricing = (props: any) => {
    const { start, end, spot, cars } = props;
    const startDate = moment(start);
    const endDate = moment(end);
    const spotPrice = spot.price_daily;
    const plates = cars.length;
    const chargeByUnit = spot.charge_by_night ? 'nights' : 'days';

    const calculateProjectedPrice = (spotPrice: any, days: any) => {
        const totalPriceCents = spotPrice * days * plates;
        return (totalPriceCents / 100).toFixed(2);
    };

    const durationInDays = moment.duration(endDate.diff(startDate)).asDays() + (spot.charge_by_night ? 0 : 1);
    const projectedPrice = calculateProjectedPrice(spotPrice, durationInDays);

    return plates > 1 ? (
        <Message>
            <Message.Header>TOTAL: ${projectedPrice}</Message.Header>
            {spot.display_price_daily} x {durationInDays} {chargeByUnit} x {plates} cars = ${projectedPrice}
        </Message>
    ) : (
        <Message>
            <Message.Header>TOTAL: ${projectedPrice}</Message.Header>
            {spot.display_price_daily} x {durationInDays} {chargeByUnit} = ${projectedPrice}
        </Message>
    );
};

export default ProjectedPricing;
