import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';

const CheckInHeader = (props: any) => {
    const { spot } = props;

    return (
        <>
            <Header
                content="AIRPORT PARKING"
                color="orange"
                style={{ letterSpacing: 1, fontSize: 14 }}
                textAlign="center"
            />
            <Header content={spot ? spot.name : 'Please Select a Parking Lot'} textAlign="center" />
        </>
    );
};

export default withRouter(CheckInHeader);
