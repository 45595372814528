import { SET_REMINDER_REQUESTED, SET_REMINDER_SUCCESS, SET_REMINDER_FAILED } from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialState = {
    errorMessage: '',
    successMessage: '',
    loading: false,
};

export default function rentalsReducer(state = initialState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case SET_REMINDER_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case SET_REMINDER_SUCCESS:
            newState.loading = false;
            newState.successMessage = 'Reminder set for ' + action.payload.minutes / 60 + ' hours';
            return newState;
        case SET_REMINDER_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        default:
            return state || initialState;
    }
}
