import { AUTO_PROMO_FAILED, AUTO_PROMO_SUCCESS, AUTO_PROMO_REQUESTED } from 'actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialPromoState = {
    errorMessage: '',
    successMessage: '',
    loading: false,
    promo: {},
};

export default function promoReducer(state = initialPromoState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case AUTO_PROMO_REQUESTED:
            newState.loading = true;
            newState.error = '';
            return newState;
        case AUTO_PROMO_SUCCESS:
            newState.loading = false;
            newState.promo = action.payload;
            newState.successMessage = 'Coupon code successfully applied.';
            return newState;
        case AUTO_PROMO_FAILED:
            newState.errorMessage = action.message;
            newState.promo = action.payload;
            newState.loading = false;
            return newState;
        default:
            return state || initialPromoState;
    }
}
