import APIClient from './APIClient';

interface VisitorCreationPayload {
    codes: string[];
    phone: string;
    plate: string;
    spot: number;
    state: string;
    country: string;
    validation_source: string;
    name: string;
}

class VisitorService extends Object {
    async addVisitor(visitorInfo: VisitorCreationPayload) {
        return await APIClient.post(`visitors/`, visitorInfo);
    }

    async validateCode(code: string, spotPk: number) {
        let url = `check_visitor_code/${code}`;
        if (spotPk) url += `?spot=${spotPk}`;
        return await APIClient.get(url);
    }
}

export default new VisitorService();
