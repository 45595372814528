import React from 'react';
import { Header, Card, Grid, Image, Rating, Label, Icon, Button, Divider, Dropdown } from 'semantic-ui-react';
import styles from './SpotInfo.module.css';
import { Link } from 'react-router-dom';
import { optionsGenerator } from '../../utils/helpers';

const SpotCard = (props: any) => {
    const { spot, spotRef, rentalType, setRentalType } = props;
    const address = `${spot.address.address_line1} ${spot.address.city}, ${spot.address.state} ${spot.address.zipcode}`;
    const rentalOptionsAvailableAtSpot = optionsGenerator(spot, spotRef);
    const webpayLink: string = rentalOptionsAvailableAtSpot.find((option) => option.value === rentalType)
        ?.link as string;

    return (
        <>
            <Grid.Row>
                <Grid.Column width={5}>
                    <Image src={spot?.photo_url} rounded alt="Parking lot" size="medium" className={styles.cardImage} />
                </Grid.Column>

                <Grid.Column width={7}>
                    <Label size="medium" color="pink" content="POPULAR" />
                    <Header as="h1" content={spot.name} />
                    <Rating icon="star" maxRating={5} rating={5} disabled />
                    <h3 className={styles.listItems}>{address}</h3>
                    <a href={'https://www.google.com/maps/place/' + address} target="_blank" rel="noopener noreferrer">
                        <Icon name="map outline" />
                        Get Directions
                    </a>
                </Grid.Column>

                <Grid.Column width={4} floated="right" textAlign="center">
                    <Card fluid>
                        <h2 style={{ fontSize: 22 }}>Available options</h2>
                        <Dropdown
                            selection
                            fluid
                            options={rentalOptionsAvailableAtSpot}
                            defaultValue={rentalType}
                            onChange={(e, data: any) => setRentalType(data.value)}
                        />
                    </Card>
                    <Link to={webpayLink}>
                        <Button content="Book now" floated="right" fluid />
                    </Link>
                </Grid.Column>
            </Grid.Row>
            <Divider />
        </>
    );
};

export default SpotCard;
