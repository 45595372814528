import APIClient from './APIClient';

class ProfileService extends Object {
    async getProfile(token: string) {
        const result = await APIClient.get('v2/profiles/me', token);
        return result;
    }
}

export default new ProfileService();
