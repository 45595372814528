import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Header, Radio, Message } from 'semantic-ui-react';
import { CardElement } from '@stripe/react-stripe-js';
import fieldStyles from '../FormFields.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import { stripeOptions } from './stripeOptions';

const CreditCardField = (props: any) => {
    return (
        <Form.Field>
            <Header as="h5" className={fieldStyles.fieldName}>
                Secure Payment
            </Header>
            {props.error && !(props.creditCard && props.cardType === 'default') && (
                <Message info size="tiny" color="red" header="Card Error" content={props.error} />
            )}

            {/* Give returning users option to add new card */}
            {props.creditCard ? (
                <>
                    <Radio
                        label={props.creditCard}
                        name="radioGroup"
                        value="default"
                        checked={props.cardType === 'default'}
                        onChange={() => props.setCardType('default')}
                    />
                    <br></br>
                    <Radio
                        label="Add new card"
                        name="radioGroup"
                        value="newCard"
                        checked={props.cardType === 'newCard'}
                        onChange={() => props.setCardType('newCard')}
                    />
                    {props.cardType === 'newCard' && (
                        <div className={fieldStyles.fieldInput}>
                            <CardElement options={stripeOptions} onChange={props.onChange} />
                        </div>
                    )}
                </>
            ) : (
                <div className={fieldStyles.fieldInput}>
                    <CardElement options={stripeOptions} onChange={props.onChange} />
                </div>
            )}
        </Form.Field>
    );
};

export default withRouter(CreditCardField);
