import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Header, Divider } from 'semantic-ui-react';
import MutiplePlatesField from 'components/FormFields/MultiplePlateField';
import UsernameField from 'components/FormFields/UsernameField';
import fieldStyles from '../FormFields/FormFields.module.css';

const AggregatorIntegrationForm = (props: any) => {
    const { source, inputs, handleInput, handleValueChange, usernameError } = props;

    return (
        <>
            <UsernameField
                inputs={inputs}
                handleInput={handleInput}
                error={usernameError}
                requiredUsernameType={'email'}
            />
            <Form.Field>
                <Header as="h5" className={fieldStyles.fieldName} content="Confirmation ID" />
                <Form.Input
                    placeholder={'AA000000'}
                    name="description"
                    onChange={handleInput}
                    value={inputs.description}
                    required
                />
            </Form.Field>

            <MutiplePlatesField
                handleInput={handleValueChange}
                inputs={inputs}
                allowNoPlate={!!source}
                invoice={false}
            />

            <Divider hidden />
        </>
    );
};

export default withRouter(AggregatorIntegrationForm);
