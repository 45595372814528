import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { autoPromoAction } from '../actions/actions';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

const AutoPromoModuleContainer = (props: any) => {
    const params = queryString.parse(props.location.search);
    const { promo } = params;
    const spotId = props.match.params.spotId;
    const { autoPromo, loading } = props;

    useEffect(() => {
        autoPromo(spotId, promo);
    }, [spotId, promo, autoPromo]);

    return (
        <>
            {loading && (
                <Loader inverted active>
                    Applying Coupon Code
                </Loader>
            )}
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        loading: state.promo.loading,
        spot: state.spots.spot,
    };
}

const mapDispatchToProps = {
    autoPromo: autoPromoAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutoPromoModuleContainer));
