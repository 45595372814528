// These are the required environment variables, we need to throw an error if they are not set
if (
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_API_BASE') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_STRIPE_TOKEN') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_DASHBOARD_DOMAIN') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_QUICKPAY_DOMAIN') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_ADMIN_DOMAIN') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_MAPBOX_ACCESS_TOKEN')
) {
    console.warn(
        `ADD FOLOWING ENVIRONMENT VARIABLES TO .env FILE:
            - REACT_APP_API_BASE
            - REACT_APP_STRIPE_TOKEN
            - REACT_APP_DASHBOARD_DOMAIN
            - REACT_APP_QUICKPAY_DOMAIN
            - REACT_APP_ADMIN_DOMAIN
            - REACT_APP_MAPBOX_ACCESS_TOKEN
        `
    );
    throw new Error('Missing required environment variable');
}

// These are the optional environment variables, we need set default values if they are not set
const OPTIONAL_ENV_VARS = {
    LOGROCKET_APP_ID: process.env.REACT_APP_LOGROCKET_APP_ID || '',
    PACKAGE_VERSION: process.env.REACT_APP_NPM_PACKAGE_VERSION || '0.0.0',
};

const ENV = {
    API_BASE: process.env.REACT_APP_API_BASE as string,
    STRIPE_TOKEN: process.env.REACT_APP_STRIPE_TOKEN as string,
    DASHBOARD_DOMAIN: process.env.REACT_APP_DASHBOARD_DOMAIN as string,
    QUICKPAY_DOMAIN: process.env.REACT_APP_QUICKPAY_DOMAIN as string,
    ADMIN_DOMAIN: process.env.REACT_APP_ADMIN_DOMAIN as string,
    MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
    ...OPTIONAL_ENV_VARS,
};

export default ENV;
