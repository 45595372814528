import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-google-places-autocomplete/dist/index.min.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Loader } from 'semantic-ui-react';

const SearchBar = (props: any) => {
    const { getSearchResultCoords } = props;

    const styles = {
        backgroundColor: '#f2f2f2',
        height: '50px',
        borderRadius: '6px',
        width: '100%',
        boxShadow: 'none',
        fontFamily: 'Lato, sans-serif',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '25px',
    };

    return (
        <GooglePlacesAutocomplete
            inputStyle={styles}
            suggestionsStyles={{
                container: {
                    width: '100%',
                    borderRadius: '0.28571429rem',
                    zIndex: 100,
                    position: 'absolute',
                    boxShadow: 'none',
                    background: 'FDD6C3',
                    border: '.8px solid #f2f2f2',
                },
                suggestion: { fontSize: 14, fontFamily: 'Lato, sans-serif;' },
            }}
            onSelect={({ description }) => {
                getSearchResultCoords(description);
            }}
            loader={<Loader inverted />}
            placeholder="Enter an address"
            apiKey={'AIzaSyAF_YJyZHBYiW-O6Cq4Jyzj1Sthopvubfc'}
        />
    );
};

export default withRouter(SearchBar);
