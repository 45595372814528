import ENV from 'env';
import {
    CREATE_CUSTOM_INVOICE_REQUESTED,
    CREATE_CUSTOM_INVOICE_SUCCESS,
    CREATE_CUSTOM_INVOICE_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialState = {
    errorMessage: '',
    invoiceSuccessMessage: '',
    rentalSuccessMessage: '',
};

export default function rentalsReducer(state = initialState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case CREATE_CUSTOM_INVOICE_REQUESTED:
            newState.errorMessage = '';
            return newState;
        case CREATE_CUSTOM_INVOICE_SUCCESS:
            newState.invoiceSuccessMessage = `Slot created: ${ENV.ADMIN_DOMAIN}spots/slot/${action.payload.pk}`;
            newState.rentalSuccessMessage = 'Good to go!';
            return newState;
        case CREATE_CUSTOM_INVOICE_FAILED:
            newState.errorMessage = action.message;
            return newState;
        default:
            return state || initialState;
    }
}
