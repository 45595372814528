import React, { useState } from 'react';
import { Button, Divider, Form, Header, Icon } from 'semantic-ui-react';
import fieldStyles from '../FormFields.module.css';
import CarOptions from './CarOptions';
import CarModal from 'components/CarModal/CarModal';
import { Car } from 'utils/types';
import { getStateFromValue } from 'utils/helpers';
import MultiRentalPricing from 'components/MultiRentalPricing/MultiRentalPricing';
import PlateAndStateField from '../PlateAndStateField/PlateAndStateField';
import useForm from 'hooks/useForm';

interface Props {
    cars: any[];
    canEdit: boolean;
    canAdd: boolean;
    removeCar: (carPk: number, rentalPk: number) => void;
    addCar: (newCar: Car, rentalPk: number, editing: boolean) => void;
    rental: any;
}

const RentalCarsField = (props: Props) => {
    const { cars, removeCar, canEdit, canAdd, addCar, rental } = props;

    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [selectedCarPk, setSelectedCarPk] = useState(0);

    const hasCar = cars.length > 0;
    const initialInputs: Car = {
        plate: '',
        state: '',
        country: 'US',
    };
    const { handleInput, inputs, handleValueChange, setInputs } = useForm(initialInputs);

    const onCarSubmit = () => {
        const car: Car = {
            ...inputs,
            plate: inputs.plate.toUpperCase(),
        };
        addCar(car, rental.pk, false);
        setInputs(initialInputs);
    };

    const removeCarFromRental = (carPk: number) => {
        removeCar(carPk, rental.pk);
    };

    const submitEdit = (newCar: Car) => {
        removeCar(selectedCarPk, rental.pk);
        addCar(newCar, rental.pk, editing);
        setEditing(false);
    };

    const submitAdding = (newCar: Car) => {
        addCar(newCar, rental.pk, editing);
        setAdding(false);
    };

    return (
        <>
            {hasCar ? (
                <>
                    <Header as="h5" className={fieldStyles.fieldName}>
                        Car
                    </Header>
                    {cars.map((car: any, index: number) => {
                        const state = car.state ? getStateFromValue(car.state, car.country)?.label : '';
                        return (
                            <div key={index}>
                                <p key={car.plate} style={{ marginBottom: '5px' }}>
                                    {state && `${state} - `}
                                    {car.plate}
                                    {canEdit && (
                                        <CarOptions
                                            cars={cars}
                                            carPkOrIndex={car.pk}
                                            removeCar={removeCarFromRental}
                                            setEditing={setEditing}
                                            setSelectedCarIndexOrPk={setSelectedCarPk}
                                        />
                                    )}
                                </p>
                                {canEdit && (
                                    <CarModal
                                        open={editing && car.pk === selectedCarPk}
                                        setOpen={setEditing}
                                        plate={car.plate}
                                        state={car.state}
                                        country={car.country}
                                        submitCar={submitEdit}
                                        header="Update License Plate"
                                        buttonText="Update"
                                    />
                                )}
                            </div>
                        );
                    })}
                </>
            ) : (
                <Form onSubmit={onCarSubmit}>
                    <Form.Field>
                        <PlateAndStateField
                            inputs={inputs}
                            handleValueChange={handleValueChange}
                            handleInput={handleInput}
                        />
                        <Button type="submit" fluid content={'Save car'} size="tiny" style={{ padding: '15px' }} />
                    </Form.Field>
                    <Divider hidden />
                </Form>
            )}

            {canEdit && canAdd && (
                <>
                    <p>
                        <a href="# " onClick={() => setAdding(true)}>
                            <Icon name="plus" />
                            Pay for another car
                        </a>
                    </p>

                    <CarModal
                        open={adding}
                        setOpen={setAdding}
                        header="Add new car"
                        buttonText="Save"
                        submitCar={submitAdding}
                        message={
                            <MultiRentalPricing
                                cars={cars}
                                rental={rental}
                                monthly={rental?.rental_type === 'monthly'}
                                source={rental?.source}
                            />
                        }
                    />
                </>
            )}
        </>
    );
};

export default RentalCarsField;
