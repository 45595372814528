import React, { useState } from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

import PlateAndStateField from './PlateAndStateField/PlateAndStateField';
import NewRentalCarsField from './CarsField/NewRentalCarsField';

interface Props {
    handleInput: (name: string, value: any) => void;
    inputs: any;
    allowNoPlate: boolean;
    invoice?: boolean;
    violation?: boolean;
}

const MultiplePlateField: React.FC<Props> = ({ handleInput, inputs, allowNoPlate, invoice, violation }) => {
    const [addPlateLater, toggleAddPlateLater] = useState(false);
    const canAddCar = inputs.cars[0].plate && !addPlateLater && !violation;

    return (
        <Form.Field>
            {!addPlateLater && <PlateAndStateField inputs={inputs} handleValueChange={handleInput} index={0} />}

            <NewRentalCarsField canAdd={canAddCar} cars={inputs.cars} handleInput={handleInput} invoice={!!invoice} />

            {allowNoPlate && inputs.cars[0].plate === '' && !invoice && (
                <Checkbox
                    style={{ margin: '5px 0px 0px 0px', fontSize: '14px' }}
                    label="I will add my license plate information later."
                    onClick={() => toggleAddPlateLater(!addPlateLater)}
                    name="noPlate"
                >
                    {' '}
                </Checkbox>
            )}
        </Form.Field>
    );
};

export default MultiplePlateField;
