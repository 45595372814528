import React from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './AboutDestinationMobile.module.css';
import { Header } from 'semantic-ui-react';

const genericAbout = (title: string) => {
    return ` Find the best parking near ${title} with Airgarage, From monthly parking to hourly parking near ${title}, simply enter your destination
    in search bar to find the right spot for you. You can also select from the top rated spots near ${title} trusted by thousands of our monthly customers.
     Select a parking spot to get started.
    `;
};
interface Props {
    title: string;
    imgUrl: string;
    aboutContent: string;
    address: string;
}

const AboutDestinationMobile: React.FC<Props> = ({ title, imgUrl, aboutContent, address }: Props) => {
    return (
        <div className={styles.container}>
            <Grid container justify={'center'}>
                {imgUrl && (
                    <Grid item xs={12} sm={10} md={5}>
                        <img className={styles.image} src={imgUrl} alt={`AirGarage parking spots near ${title}`} />
                    </Grid>
                )}
                <Grid item xs={12} md={imgUrl ? 5 : 12}>
                    <div className={styles.content}>
                        <Header size={'huge'}>
                            {`Parking near ${title}`}
                            <p className={styles.address}>{address}</p>
                        </Header>
                        <p className={styles.aboutContent}>{aboutContent || genericAbout(title)}</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default AboutDestinationMobile;
