/* Saga Selectors */
export const profileKeyState = (state: any) => state.auth.profile.pk;
export const spotPkState = (state: any) => state.spots.spot.pk;
export const spotState = (state: any) => state.spots.spot;
export const authTokenState = (state: any) => state.auth.token;
export const carsForRentalState = (state: any) => {
    const cars: any = {};
    state.rentals.rentals.forEach((rental: any) => {
        cars[rental.pk] = rental.cars;
    });
    return cars;
};

export const getDestinationListSelector = ({ destinations }: any) => destinations.destinationList;
export const getDestinationSelector = ({ destinations }: any) => destinations.destination;
