import React from 'react';
import { Card, Header } from 'semantic-ui-react';
import styles from './SpotInfo.module.css';

const Instructions = (props: any) => {
    const { spot, rentalType } = props;

    let instructions = `To book hourly parking at ${spot.name} with AirGarage, enter your phone, payment info, and license plate number on the next page.`;
    let endRentalHeader = 'Ending your rental';
    let endRental = `To end your rental, navigate to the link we text you and press 'End Rental'. We prorate by the minute after the first hour, so you never overpay.`;
    if (rentalType === 'monthly') {
        instructions = `To book monthly parking at ${spot.name} with AirGarage, simply select your start date and have your payment ready. You will be billed on the same day every month.`;
        endRentalHeader = 'Cancel anytime';
        endRental = `Cancelling your parking rental is easy. View your active rental page and cancel from there - you'll be able to park with AirGarage through the month you paid for. `;
    } else if (rentalType === 'daily') {
        instructions = `To book daily parking at ${spot.name} with AirGarage, enter your phone, payment info, and license plate number on the next page.`;
        endRentalHeader = 'Cancellation policy';
        endRental = `Be sure to double check your dates before booking as daily parking is non-refundable. You can cancel at anytime but your booking will be valid through the days you paid for.`;
    }

    return (
        <Card style={{ backgroundColor: '#F6F6F6' }} fluid>
            <Header as="h3" content="How it works" />
            <p className={styles.bodyText}> {instructions}</p>

            <Header as="h3" content={endRentalHeader} />
            <p className={styles.bodyText}>{endRental}</p>

            <Header as="h3" content="About AirGarage" />
            <p className={styles.bodyText}>
                AirGarage is a parking operator that handles customer support, payment collection, and more on{' '}
                {spot.name}'s behalf.
            </p>
        </Card>
    );
};

export default Instructions;
