import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import CarOptions from './CarOptions';
import CarModal from 'components/CarModal/CarModal';
import { Car } from 'utils/types';
import { getStateFromValue } from 'utils/helpers';

interface Props {
    cars: Car[];
    handleInput: (name: string, value: any) => void;
    invoice: boolean;
    canAdd: boolean;
}

const NewRentalCarsField = (props: Props) => {
    const { cars, handleInput, invoice, canAdd } = props;

    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [selectedCarIndex, setSelectedCarIndex] = useState(0);

    const addCarText = invoice ? 'Add another plate' : 'Pay for another car';
    const buttonText = invoice ? 'Add' : 'Save';

    const onDelete = (index: number) => {
        const newCarsArray: Array<Car> = [...cars];
        newCarsArray.splice(index, 1);
        handleInput('cars', newCarsArray);
    };

    const onChange = (newCar: Car) => {
        const newCarsArray = [...cars];
        newCarsArray[selectedCarIndex] = newCar;
        handleInput('cars', newCarsArray);
        setEditing(false);
    };

    const onAdd = (newCar: Car) => {
        const newCarsArray = [...cars, newCar];
        handleInput('cars', newCarsArray);
        setAdding(false);
    };

    return (
        <>
            {cars.length > 1 &&
                cars.map((car: Car, index: number) => {
                    if (index !== 0) {
                        const state = car.state ? getStateFromValue(car.state, car.country)?.label : '';
                        return (
                            <div key={index} style={{ marginBottom: '5px' }}>
                                <p key={car.plate}>
                                    {state && `${state} - `}
                                    {car.plate}

                                    <CarOptions
                                        cars={cars}
                                        carPkOrIndex={index}
                                        removeCar={onDelete}
                                        setEditing={setEditing}
                                        setSelectedCarIndexOrPk={setSelectedCarIndex}
                                    />
                                </p>
                                <CarModal
                                    open={editing}
                                    setOpen={setEditing}
                                    plate={car.plate}
                                    state={car.state}
                                    country={car.country}
                                    submitCar={onChange}
                                    header={'Update License Plate'}
                                    buttonText={'Update'}
                                />
                            </div>
                        );
                    } else return null;
                })}

            {canAdd && (
                <>
                    <p>
                        <a href="# " onClick={() => setAdding(true)}>
                            <Icon name="plus" />
                            {addCarText}
                        </a>
                    </p>
                    <CarModal
                        open={adding}
                        setOpen={setAdding}
                        header={addCarText}
                        buttonText={buttonText}
                        submitCar={onAdd}
                    />
                </>
            )}
        </>
    );
};

export default NewRentalCarsField;
