export const RENTALS_REQUESTED = 'RENTALS_REQUESTED';
export const RENTALS_SUCCESS = 'RENTALS_SUCCESS';
export const RENTALS_FAILED = 'RENTALS_FAILED';

export const RENTAL_REQUESTED = 'RENTAL_REQUESTED';
export const RENTAL_SUCCESS = 'RENTAL_SUCCESS';
export const RENTAL_FAILED = 'RENTAL_FAILED';

export const RENTAL_BY_CONFIRMATION_ID_REQUESTED = 'RENTAL_BY_CONFIRMATION_ID_REQUESTED';
export const RENTAL_BY_CONFIRMATION_ID_SUCCESS = 'RENTAL_BY_CONFIRMATION_ID_SUCCESS';
export const RENTAL_BY_CONFIRMATION_ID_FAILED = 'RENTAL_BY_CONFIRMATION_ID_FAILED';

export const UPDATE_RENTAL_REQUESTED = 'UPDATE_RENTAL_REQUESTED';
export const UPDATE_RENTAL_SUCCESS = 'UPDATE_RENTAL_SUCCESS';
export const UPDATE_RENTAL_FAILED = 'UPDATE_RENTAL_FAILED';

export const END_RENTAL_REQUESTED = 'END_RENTAL_REQUESTED';
export const END_RENTAL_SUCCESS = 'END_RENTAL_SUCCESS';
export const END_RENTAL_FAILED = 'END_RENTAL_FAILED';

export const REMOVE_CAR_FROM_RENTAL_REQUESTED = 'REMOVE_CAR_FROM_RENTAL_REQUESTED';
export const REMOVE_CAR_FROM_RENTAL_SUCCESS = 'REMOVE_CAR_FROM_RENTAL_SUCCESS';
export const REMOVE_CAR_FROM_RENTAL_FAILED = 'REMOVE_CAR_FROM_RENTAL_FAILED';

export const ADD_CAR_TO_RENTAL_REQUESTED = 'ADD_CAR_TO_RENTAL_REQUESTED';
export const ADD_CAR_TO_RENTAL_SUCCESS = 'ADD_CAR_TO_RENTAL_SUCCESS';
export const ADD_CAR_TO_RENTAL_FAILED = 'ADD_CAR_TO_RENTAL_FAILED';

export const SPOTS_REQUESTED = 'SPOTS_REQUESTED';
export const SPOTS_SUCCESS = 'SPOTS_SUCCESS';
export const SPOTS_FAILED = 'SPOTS_FAILED';

export const AIRPORT_SPOTS_REQUESTED = 'AIRPORT_SPOTS_REQUESTED';
export const AIRPORT_SPOTS_SUCCESS = 'AIRPORT_SPOTS_SUCCESS';
export const AIRPORT_SPOTS_FAILED = 'AIRPORT_SPOTS_FAILED';

export const SPOT_REQUESTED = 'SPOT_REQUESTED';
export const CLEAR_SPOT_REQUESTED = 'CLEAR_SPOT_REQUESTED';
export const CLEAR_SPOT_SUCCESS = 'CLEAR_SPOT_SUCCESS';
export const SPOT_SUCCESS = 'SPOT_SUCCESS';
export const SPOT_FAILED = 'SPOT_FAILED';

export const PROFILE_REQUESTED = 'PROFILE_REQUESTED';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILED = 'PROFILE_FAILED';

export const BOOK_RENTAL_REQUESTED = 'BOOK_RENTAL_REQUESTED';
export const BOOK_RENTAL_SUCCESS = 'BOOK_RENTAL_SUCCESS';
export const BOOK_RENTAL_FAILED = 'BOOK_RENTAL_FAILED';

export const CREATE_CUSTOM_INVOICE_REQUESTED = 'CREATE_CUSTOM_INVOICE_REQUESTED';
export const CREATE_CUSTOM_INVOICE_SUCCESS = 'CREATE_CUSTOM_INVOICE_SUCCESS';
export const CREATE_CUSTOM_INVOICE_FAILED = 'CREATE_CUSTOM_INVOICE_FAILED';

export const USER_DETAILS_REQUESTED = 'USER_DETAILS_REQUESTED';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILED = 'USER_DETAILS_FAILED';

export const KIOSK_RESTARTED = 'KIOSK_RESTARTED';

export const CARD_UPDATE_REQUESTED = 'CARD_UPDATE_REQUESTED';
export const CARD_UPDATE_SUCCESS = 'CARD_UPDATE_SUCCESS';
export const CARD_UPDATE_FAILED = 'CARD_UPDATE_FAILED';

export const ENTERPRISE_DETAILS_REQUESTED = 'ENTERPRISE_DETAILS_REQUESTED';
export const ENTERPRISE_DETAILS_SUCCESS = 'ENTERPRISE_DETAILS_SUCCESS';
export const ENTERPRISE_DETAILS_FAILED = 'ENTERPRISE_DETAILS_FAILED';

export const APPLY_PROMO_CODE_REQUESTED = 'APPLY_PROMO_CODE_REQUESTED';
export const APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS';
export const APPLY_PROMO_CODE_FAILED = 'APPLY_PROMO_CODE_FAILED';

export const SEND_FEEDBACK_REQUESTED = 'SEND_FEEDBACK_REQUESTED';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILED = 'SEND_FEEDBACK_FAILED';

export const SET_REMINDER_REQUESTED = 'SET_REMINDER_REQUESTED';
export const SET_REMINDER_SUCCESS = 'SET_REMINDER_SUCCESS';
export const SET_REMINDER_FAILED = 'SET_REMINDER_FAILED';

export const SUBMIT_CONTACT_FORM_REQUESTED = 'SUBMIT_CONTACT_FORM_REQUESTED';
export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS';
export const SUBMIT_CONTACT_FORM_FAILED = 'SUBMIT_CONTACT_FORM_FAILED';

export const SEARCH_COORDS_REQUESTED = 'SEARCH_COORDS_REQUESTED';
export const SEARCH_COORDS_SUCCESS = 'SEARCH_COORDS_SUCCESS';
export const SEARCH_COORDS_FAILED = 'SEARCH_COORDS_FAILED';

export const NAVIGATE_TO_RESULTS_REQUESTED = 'NAVIGATE_TO_RESULTS_REQUESTED';
export const NAVIGATE_TO_RESULTS_SUCCESS = 'NAVIGATE_TO_RESULTS_SUCCESS';
export const NAVIGATE_TO_RESULTS_FAILED = 'NAVIGATE_TO_RESULTS_FAILED';

export const DESTINATION_REQUESTED = 'DESTINATION_REQUESTED';
export const DESTINATION_SUCCESS = 'DESTINATION_SUCCESS';
export const DESTINATION_FAILED = 'DESTINATION_FAILED';

export const DESTINATION_LIST_REQUESTED = 'DESTINATION_LIST_REQUESTED';
export const DESTINATION_LIST_SUCCESS = 'DESTINATION_LIST_SUCCESS';
export const DESTINATION_LIST_FAILED = 'DESTINATION_LIST_FAILED';

export const CHECK_IN_RENTAL_REQUESTED = 'CHECK_IN_RENTAL_REQUESTED';
export const CHECK_IN_RENTAL_SUCCESS = 'CHECK_IN_RENTAL_SUCCESS';
export const CHECK_IN_RENTAL_FAILED = 'CHECK_IN_RENTAL_FAILED';

export const BLACKOUT_DATES_LIST_REQUESTED = 'BLACKOUT_DATES_LIST_REQUESTED';
export const BLACKOUT_DATES_LIST_SUCCESS = 'BLACKOUT_DATES_LIST_SUCCESS';
export const BLACKOUT_DATES_LIST_FAILED = 'BLACKOUT_DATES_LIST_FAILED';

export const VALIDATE_VISITOR_REQUESTED = 'VALIDATE_VISITOR_REQUESTED';
export const VALIDATE_VISITOR_SUCCESS = 'VALIDATE_VISITOR_SUCCESS';
export const VALIDATE_VISITOR_FAILED = 'VALIDATE_VISITOR_FAILED';

export const CLEAR_DATA = 'CLEAR_DATA';

export const LOGOUT = 'LOGOUT';

export const AUTHENTICATE_REQUESTED = 'AUTHENTICATE_REQUESTED';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED';

export const AUTO_PROMO_REQUESTED = 'AUTO_PROMO_REQUESTED';
export const AUTO_PROMO_SUCCESS = 'AUTO_PROMO_SUCCESS';
export const AUTO_PROMO_FAILED = 'AUTO_PROMO_FAILED';
