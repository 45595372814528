import {
    RENTALS_REQUESTED,
    RENTALS_SUCCESS,
    RENTALS_FAILED,
    RENTAL_REQUESTED,
    RENTAL_SUCCESS,
    RENTAL_FAILED,
    RENTAL_BY_CONFIRMATION_ID_REQUESTED,
    RENTAL_BY_CONFIRMATION_ID_SUCCESS,
    RENTAL_BY_CONFIRMATION_ID_FAILED,
    UPDATE_RENTAL_REQUESTED,
    UPDATE_RENTAL_FAILED,
    UPDATE_RENTAL_SUCCESS,
    END_RENTAL_REQUESTED,
    END_RENTAL_FAILED,
    END_RENTAL_SUCCESS,
    BOOK_RENTAL_REQUESTED,
    BOOK_RENTAL_FAILED,
    CARD_UPDATE_REQUESTED,
    CARD_UPDATE_SUCCESS,
    CARD_UPDATE_FAILED,
    APPLY_PROMO_CODE_REQUESTED,
    APPLY_PROMO_CODE_SUCCESS,
    APPLY_PROMO_CODE_FAILED,
    REMOVE_CAR_FROM_RENTAL_REQUESTED,
    REMOVE_CAR_FROM_RENTAL_FAILED,
    REMOVE_CAR_FROM_RENTAL_SUCCESS,
    ADD_CAR_TO_RENTAL_REQUESTED,
    ADD_CAR_TO_RENTAL_SUCCESS,
    ADD_CAR_TO_RENTAL_FAILED,
    CHECK_IN_RENTAL_REQUESTED,
    CHECK_IN_RENTAL_SUCCESS,
    CHECK_IN_RENTAL_FAILED,
    BLACKOUT_DATES_LIST_REQUESTED,
    BLACKOUT_DATES_LIST_SUCCESS,
    BLACKOUT_DATES_LIST_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialState = {
    rental: null,
    rentals: null,
    errorMessage: '',
    successMessage: '',
    loading: false,
    updating: false,
    creditCard: '',
    bookedDates: null,
    minEndDate: new Date(),
};

export default function rentalsReducer(state = initialState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case BLACKOUT_DATES_LIST_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case BLACKOUT_DATES_LIST_SUCCESS:
            newState.loading = false;
            newState.bookedDates = action.payload.bookedDates;
            newState.minEndDate = action.payload.minEndDate;
            return newState;
        case BLACKOUT_DATES_LIST_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case RENTALS_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case RENTALS_SUCCESS:
            newState.loading = false;
            newState.rentals = action.payload.rentals;
            return newState;
        case RENTALS_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case RENTAL_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case RENTAL_SUCCESS:
            newState.loading = false;
            newState.rental = action.payload.rental;
            return newState;
        case RENTAL_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case RENTAL_BY_CONFIRMATION_ID_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case RENTAL_BY_CONFIRMATION_ID_SUCCESS:
            newState.loading = false;
            newState.rental = action.payload.rental;
            return newState;
        case RENTAL_BY_CONFIRMATION_ID_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case BOOK_RENTAL_REQUESTED:
            newState.errorMessage = '';
            return newState;
        case BOOK_RENTAL_FAILED:
            newState.errorMessage = action.message;
            return newState;
        case UPDATE_RENTAL_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case UPDATE_RENTAL_SUCCESS:
            newState.loading = false;
            newState.rentals = action.payload.rentals;
            return newState;
        case UPDATE_RENTAL_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case END_RENTAL_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case END_RENTAL_SUCCESS:
            newState.loading = false;
            newState.rentals = action.payload.rentals;
            return newState;
        case END_RENTAL_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case REMOVE_CAR_FROM_RENTAL_REQUESTED:
            newState.errorMessage = '';
            newState.updating = true;
            return newState;
        case REMOVE_CAR_FROM_RENTAL_FAILED:
            newState.updating = false;
            newState.errorMessage = action.message;
            return newState;
        case REMOVE_CAR_FROM_RENTAL_SUCCESS:
            newState.errorMessage = '';
            newState.updating = false;
            return newState;
        case ADD_CAR_TO_RENTAL_REQUESTED:
            newState.errorMessage = '';
            newState.updating = true;
            return newState;
        case ADD_CAR_TO_RENTAL_FAILED:
            newState.updating = false;
            newState.errorMessage = action.message;
            return newState;
        case ADD_CAR_TO_RENTAL_SUCCESS:
            newState.errorMessage = '';
            newState.updating = false;
            return newState;
        case CARD_UPDATE_REQUESTED:
            newState.updating = true;
            newState.errorMessage = '';
            return newState;
        case CARD_UPDATE_SUCCESS:
            newState.updating = false;
            newState.creditCard = action.payload.creditCard.card;
            newState.successMessage = 'Your card was successfully updated.';
            return newState;
        case CARD_UPDATE_FAILED:
            newState.updating = false;
            newState.errorMessage = action.message;
            return newState;
        case APPLY_PROMO_CODE_REQUESTED:
            newState.updating = true;
            newState.errorMessage = '';
            return newState;
        case APPLY_PROMO_CODE_SUCCESS:
            newState.updating = false;
            newState.rentals = action.payload.rentals;
            newState.successMessage = 'Your coupon code was successfully applied.';
            return newState;
        case APPLY_PROMO_CODE_FAILED:
            newState.updating = false;
            newState.errorMessage = action.message;
            return newState;
        case CHECK_IN_RENTAL_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case CHECK_IN_RENTAL_SUCCESS:
            newState.loading = false;
            newState.rentals = action.payload;
            return newState;
        case CHECK_IN_RENTAL_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        default:
            return state || initialState;
    }
}
