import React from 'react';
import { Divider } from 'semantic-ui-react';
import Grid from '@material-ui/core/Grid';
import styles from './OtherDestinations.module.css';

const OtherDestinationsMobile = ({ nearbyDestinations }: any) => {
    return (
        <div className={styles.container}>
            <Divider />
            <h1 className={styles.header}>Nearby Destinations</h1>
            <Grid container justify={'center'} spacing={3}>
                <>
                    {nearbyDestinations
                        .filter((dest: any) => !!dest['image_url'])
                        .map((dest: any) => {
                            const { name, slug } = dest;
                            return (
                                <Grid item key={`Other-destination-${slug}`}>
                                    <a href={`/search/${slug}`}>
                                        <div className={styles.itemContainer}>
                                            <img className={styles.itemImage} src={dest['image_url']} alt={name} />
                                            <p className={styles.itemHeader}>{name}</p>
                                        </div>
                                    </a>
                                </Grid>
                            );
                        })}
                </>
            </Grid>
        </div>
    );
};

export default OtherDestinationsMobile;
