import React from 'react';
import styles from './DestinationMobile.module.css';
import { Image } from 'semantic-ui-react';
import DestinationSpotMap from './DestinationSpotMap';
import SearchSection from './SearchSection';

const DestinationMobileHero = (props: any) => {
    const {
        name,
        getSearchResultCoords,
        setRentalType,
        navigateToResults,
        coords,
        rentalType,
        spotsArray,
        activeSpot,
        setActiveSpot,
    } = props;

    return (
        <div style={{ background: 'white' }}>
            <nav className={styles.nav}>
                <Image src={require('assets/AirGarage.png')} style={{ height: 35 }} />
            </nav>
            <div className={styles.heroHeader}>
                <h1 className={styles.header}>
                    Find parking spots near <span style={{ color: 'orange' }}>{name}</span>
                </h1>
                <DestinationSpotMap
                    destinationName={name}
                    spotsArray={spotsArray}
                    coords={coords}
                    activeSpot={activeSpot}
                    setActiveSpot={setActiveSpot}
                    rentalType={rentalType}
                />
            </div>
            <SearchSection
                getSearchResultCoords={getSearchResultCoords}
                setRentalType={setRentalType}
                navigateToResults={navigateToResults}
                coords={coords}
                rentalType={rentalType}
            />
        </div>
    );
};

export default DestinationMobileHero;
