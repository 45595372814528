import React from 'react';
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import SearchSection from './SearchSection';
import styles from './DestinationMobile.module.css';
import { Image } from 'semantic-ui-react';
import style from '../Spots/SpotInfo.module.css';
import SpotInfo from './SpotInfo';
import { getSpotPriceByRentalType } from '../../utils/helpers';
import ENV from 'env';

const Map = ReactMapboxGl({
    attributionControl: false,
    accessToken: ENV.MAPBOX_TOKEN,
});

const DestinationHero = (props: any) => {
    const {
        name,
        getSearchResultCoords,
        setRentalType,
        navigateToResults,
        coords,
        rentalType,
        spotsArray,
        activeSpot,
        setActiveSpot,
    } = props;

    const DestinationMarker = () => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <RoomRoundedIcon style={{ color: 'gray' }} fontSize="large" />
            <div
                style={{
                    background: '#FBFBFB',
                    padding: '.2rem',
                    borderRadius: '.2rem',
                }}
            >
                {name}
            </div>
        </div>
    );

    return (
        <Map
            style={`mapbox://styles/mapbox/light-v10`}
            zoom={[14]} // 0 (zoom out) - 20 (zoom in)
            center={[coords.lng, coords.lat]}
            containerStyle={{ height: '600px', width: '100%' }}
        >
            <>
                <div className={styles.searchSectionWeb}>
                    <Image src={require('assets/AirGarage.png')} style={{ height: 35 }} />
                    <h1 className={styles.header}>
                        Find parking spots near <span style={{ color: 'orange' }}>{name}</span>
                    </h1>
                    <SearchSection
                        getSearchResultCoords={getSearchResultCoords}
                        setRentalType={setRentalType}
                        navigateToResults={navigateToResults}
                        coords={coords}
                        rentalType={rentalType}
                    />
                </div>

                <ZoomControl />
                <Marker coordinates={[coords.lng, coords.lat]} anchor="bottom">
                    <DestinationMarker />
                </Marker>

                {spotsArray.map((spot: any) => {
                    const price = getSpotPriceByRentalType(spot, rentalType);
                    return (
                        <Marker
                            key={`destination-map-marker-${spot.name}`}
                            coordinates={[spot.longitude, spot.latitude]}
                            anchor="bottom"
                            onClick={() => setActiveSpot(spot)}
                        >
                            <div className={style.priceMarker}>{price}</div>
                        </Marker>
                    );
                })}

                {activeSpot && (
                    <SpotInfo spot={activeSpot} toggleDrawer={() => setActiveSpot(null)} rentalType={rentalType} />
                )}
            </>
        </Map>
    );
};

export default DestinationHero;
