export const stripeOptions = {
    hidePostalCode: true,
    style: {
        base: {
            fontSize: '13px',
            letterSpacing: '0.025em',
            color: '#333',
            '::placeholder': {
                color: '#ccc',
            },
        },
    },
};
