import { Car } from 'utils/types';
import APIClient from './APIClient';
class CarService extends Object {
    async getOrCreateCar(data: any, token: string) {
        const result = await APIClient.post(`cars/`, data, token);
        return result;
    }
    async getRecentPlate(token: string) {
        // NB: Gets first ever user plate to avoid getting wrong bad plate again.
        let plate = '';
        let state = '';
        let country = '';
        const cars = await APIClient.get(`cars/`, token);
        if (cars.length > 0) {
            plate = cars[0]['plate'];
            state = cars[0]['state'];
            country = cars[0]['country'];
        }
        return { plate, state, country };
    }

    async getCarsPkFromPlates(cars: Car[], token = '') {
        const carsPk: string[] = [];
        for (let i = 0; i < cars.length; i++) {
            const data: Car = cars[i];
            const car = await APIClient.post(`cars/`, data, token);
            carsPk.push(car.pk);
        }
        return carsPk;
    }
}

export default new CarService();
