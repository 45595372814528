import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Divider, Grid, Loader, Message } from 'semantic-ui-react';

import { getSpotAction, clearSpotAction, validateVisitorAction } from '../actions/actions';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import VisitorForm from 'components/VisitorForm/VisitorForm';
import VisitorHeader from 'components/VisitorForm/VisitorHeader';

import styles from 'components/VisitorForm/VisitorForm.module.css';

const VisitorFormContainer = (props: any) => {
    const params = queryString.parse(props.location.search);
    let { plate, phone } = params;
    const spotId = props.match.params.spotId;

    if (Array.isArray(plate)) plate = plate[0];
    if (Array.isArray(phone)) phone = phone[0];

    const { spot, getSpot, clearSpot, loadingSpot, validateVisitor, loadingValidation, errorMessage, visitor } = props;
    const loading = !spot || loadingSpot;

    useEffect(() => {
        getSpot(spotId);
        return () => clearSpot();
    }, [clearSpot, getSpot, spotId]);

    return (
        <div className={styles.visitorFormContainer}>
            {!loading ? (
                <Grid columns={1} padded>
                    <Grid.Column>
                        <Card centered>
                            <VisitorHeader spotName={spot.name || ''} />

                            {spot.validation_instruction && !visitor ? (
                                <Message info content={spot.validation_instruction} />
                            ) : (
                                <Divider hidden={true} />
                            )}

                            {errorMessage && (
                                <Message info size="tiny" color="red" header="Error" content={errorMessage} />
                            )}

                            {loadingValidation && <Loader active inline="centered" />}
                            {!visitor ? (
                                <VisitorForm
                                    phone={phone || ''}
                                    plate={plate || ''}
                                    validateVisitor={validateVisitor}
                                    spot={spot}
                                />
                            ) : (
                                <Message color="green">
                                    The validation code was successfully applied to the car "{visitor.car_details.plate}
                                    ".
                                </Message>
                            )}
                        </Card>
                    </Grid.Column>
                </Grid>
            ) : (
                <Loader inverted active />
            )}
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.visitor.errorMessage,
        visitor: state.visitor.visitor,
        loadingValidation: state.visitor.loading,
        loadingSpot: state.spots.loading,
        spot: state.spots.spot,
    };
}

const mapDispatchToProps = {
    getSpot: getSpotAction,
    clearSpot: clearSpotAction,
    validateVisitor: validateVisitorAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitorFormContainer));
