import React from 'react';
import DatePicker from 'react-datepicker';
import { Form, Header } from 'semantic-ui-react';
import formStyles from './FormFields.module.css';
import 'react-datepicker/dist/react-datepicker.css';

interface StartDateProps {
    selectedDate: Date | null;
    handleValueChange: (name: string, value: any) => void;
    invoice: boolean | null;
    minimumBookingLength: number;
}

const StartDateField = ({ selectedDate, handleValueChange, invoice, minimumBookingLength }: StartDateProps) => {
    return (
        <>
            <Form.Field>
                <Header as="h5" className={formStyles.fieldName}>
                    Start date
                </Header>
                <Form.Input name="start" required>
                    <DatePicker
                        popperPlacement="top"
                        dateFormat="MM/dd/yyyy"
                        className={formStyles.dateField}
                        placeholderText="Start date"
                        selected={selectedDate}
                        minDate={invoice ? null : new Date()}
                        onChange={(start) => handleValueChange('start', start)}
                    >
                        {!invoice && (
                            <p className={formStyles.dateNotification}>Rental minimum: {minimumBookingLength} days</p>
                        )}
                    </DatePicker>
                </Form.Input>
            </Form.Field>
        </>
    );
};

export default StartDateField;
