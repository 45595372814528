import React from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './Footer.module.css';
import { Image, List } from 'semantic-ui-react';
import { currentYear } from 'utils/constants';

const Footer = ({ cities, nearbyDestinations }: any) => {
    return (
        <Grid container justify={'center'} className={styles.container}>
            <Grid item sm={6} xs={12} className={styles.listContainer}>
                <p className={styles.header}>Cities</p>
                <div className={styles.list}>
                    {cities.map(({ name, slug }: any) => {
                        return (
                            <a key={`${slug}`} href={`/search/${slug}`} className={styles.content}>
                                {name}
                            </a>
                        );
                    })}
                </div>
            </Grid>
            <Grid item sm={6} xs={12} className={styles.listContainer}>
                <p className={styles.header}>Destinations</p>
                <div className={styles.list}>
                    {nearbyDestinations.slice(0, 10).map(({ name, slug }: any) => {
                        return (
                            <a key={`${slug}`} href={`/search/${slug}`} className={styles.content}>
                                {name}
                            </a>
                        );
                    })}
                </div>
            </Grid>
            <Grid container item justify={'space-between'} alignItems={'center'} xs={12} className={styles.footerNav}>
                <a href="/">
                    <Image src={require('assets/airwhite.png')} style={{ height: 35, display: 'inline' }} />
                </a>
                <div>
                    <List.Item as="a" style={{ color: '#fff', margin: '0 8px' }} href="https://airgarage.com/">
                        Homepage
                    </List.Item>
                    <List.Item as="a" style={{ color: '#fff', margin: '0 8px' }} href="https://airgarage.com/terms">
                        Terms
                    </List.Item>
                    <List.Item
                        as="a"
                        style={{ color: '#fff', margin: '0 8px' }}
                        href={`/contact?source=destination-pages`}
                    >
                        Contact us
                    </List.Item>
                    <List.Item
                        as="span"
                        style={{ color: '#fff', margin: '0 8px' }}
                        href={`/contact?source=destination-pages`}
                    >
                        ©{currentYear} AirGarage, Inc
                    </List.Item>
                </div>
            </Grid>
        </Grid>
    );
};

export default Footer;
