import React from 'react';
import { Flag, FlagNameValues } from 'semantic-ui-react';

interface CountryInformation {
    name: string;
    countryCode: FlagNameValues;
    phoneCode: string;
}

const countriesInformation: CountryInformation[] = [
    {
        name: 'United States',
        countryCode: 'us',
        phoneCode: '+1',
    },
    {
        name: 'Canada',
        countryCode: 'ca',
        phoneCode: '+1',
    },
    {
        name: 'Mexico',
        countryCode: 'mx',
        phoneCode: '+52',
    },
    {
        name: 'Afghanistan',
        countryCode: 'af',
        phoneCode: '+93',
    },
    {
        name: 'Aland Islands',
        countryCode: 'ax',
        phoneCode: '+35818',
    },
    {
        name: 'Albania',
        countryCode: 'al',
        phoneCode: '+355',
    },
    {
        name: 'Algeria',
        countryCode: 'dz',
        phoneCode: '+213',
    },
    {
        name: 'American Samoa',
        countryCode: 'as',
        phoneCode: '+1684',
    },
    {
        name: 'Andorra',
        countryCode: 'ad',
        phoneCode: '+376',
    },
    {
        name: 'Angola',
        countryCode: 'ao',
        phoneCode: '+244',
    },
    {
        name: 'Anguilla',
        countryCode: 'ai',
        phoneCode: '+1264',
    },
    {
        name: 'Antigua',
        countryCode: 'ag',
        phoneCode: '+1268',
    },
    {
        name: 'Argentina',
        countryCode: 'ar',
        phoneCode: '+54',
    },
    {
        name: 'Armenia',
        countryCode: 'am',
        phoneCode: '+374',
    },
    {
        name: 'Aruba',
        countryCode: 'aw',
        phoneCode: '+297',
    },
    {
        name: 'Australia',
        countryCode: 'au',
        phoneCode: '+61',
    },
    {
        name: 'Austria',
        countryCode: 'at',
        phoneCode: '+43',
    },
    {
        name: 'Azerbaijan',
        countryCode: 'az',
        phoneCode: '+994',
    },
    {
        name: 'Bahamas',
        countryCode: 'bs',
        phoneCode: '+1242',
    },
    {
        name: 'Bahrain',
        countryCode: 'bh',
        phoneCode: '+973',
    },
    {
        name: 'Bangladesh',
        countryCode: 'bd',
        phoneCode: '+880',
    },
    {
        name: 'Barbados',
        countryCode: 'bb',
        phoneCode: '+1246',
    },
    {
        name: 'Belarus',
        countryCode: 'by',
        phoneCode: '+375',
    },
    {
        name: 'Belgium',
        countryCode: 'be',
        phoneCode: '+32',
    },
    {
        name: 'Belize',
        countryCode: 'bz',
        phoneCode: '+501',
    },
    {
        name: 'Benin',
        countryCode: 'bj',
        phoneCode: '+229',
    },
    {
        name: 'Bermuda',
        countryCode: 'bm',
        phoneCode: '+1441',
    },
    {
        name: 'Bhutan',
        countryCode: 'bt',
        phoneCode: '+975',
    },
    {
        name: 'Bolivia',
        countryCode: 'bo',
        phoneCode: '+591',
    },
    {
        name: 'Bosnia',
        countryCode: 'ba',
        phoneCode: '+387',
    },
    {
        name: 'Botswana',
        countryCode: 'bw',
        phoneCode: '+267',
    },
    {
        name: 'Bouvet Island',
        countryCode: 'bv',
        phoneCode: '+55',
    },
    {
        name: 'Brazil',
        countryCode: 'br',
        phoneCode: '+55',
    },
    {
        name: 'British Virgin Islands',
        countryCode: 'vg',
        phoneCode: '+1284',
    },
    {
        name: 'Brunei',
        countryCode: 'bn',
        phoneCode: '+673',
    },
    {
        name: 'Bulgaria',
        countryCode: 'bg',
        phoneCode: '+359',
    },
    {
        name: 'Burkina Faso',
        countryCode: 'bf',
        phoneCode: '+226',
    },
    {
        name: 'Burma',
        countryCode: 'mm',
        phoneCode: '+95',
    },
    {
        name: 'Burundi',
        countryCode: 'bi',
        phoneCode: '+257',
    },
    {
        name: 'Caicos Islands',
        countryCode: 'tc',
        phoneCode: '+1649',
    },
    {
        name: 'Cambodia',
        countryCode: 'kh',
        phoneCode: '+855',
    },
    {
        name: 'Cameroon',
        countryCode: 'cm',
        phoneCode: '+237',
    },

    {
        name: 'Cape Verde',
        countryCode: 'cv',
        phoneCode: '+238',
    },
    {
        name: 'Cayman Islands',
        countryCode: 'ky',
        phoneCode: '+1345',
    },
    {
        name: 'Central African Republic',
        countryCode: 'cf',
        phoneCode: '+236',
    },
    {
        name: 'Chad',
        countryCode: 'td',
        phoneCode: '+235',
    },
    {
        name: 'Chile',
        countryCode: 'cl',
        phoneCode: '+56',
    },
    {
        name: 'China',
        countryCode: 'cn',
        phoneCode: '+86',
    },
    {
        name: 'Christmas Island',
        countryCode: 'cx',
        phoneCode: '+61',
    },
    {
        name: 'Cocos Islands',
        countryCode: 'cc',
        phoneCode: '+61',
    },
    {
        name: 'Colombia',
        countryCode: 'co',
        phoneCode: '+57',
    },
    {
        name: 'Comoros',
        countryCode: 'km',
        phoneCode: '+269',
    },
    {
        name: 'Congo',
        countryCode: 'cd',
        phoneCode: '+243',
    },
    {
        name: 'Congo Brazzaville',
        countryCode: 'cg',
        phoneCode: '+242',
    },
    {
        name: 'Cook Islands',
        countryCode: 'ck',
        phoneCode: '+682',
    },
    {
        name: 'Costa Rica',
        countryCode: 'cr',
        phoneCode: '+506',
    },
    {
        name: 'Cote Divoire',
        countryCode: 'ci',
        phoneCode: '+225',
    },
    {
        name: 'Croatia',
        countryCode: 'hr',
        phoneCode: '+385',
    },
    {
        name: 'Cuba',
        countryCode: 'cu',
        phoneCode: '+53',
    },
    {
        name: 'Cyprus',
        countryCode: 'cy',
        phoneCode: '+357',
    },
    {
        name: 'Czech Republic',
        countryCode: 'cz',
        phoneCode: '+420',
    },
    {
        name: 'Denmark',
        countryCode: 'dk',
        phoneCode: '+45',
    },
    {
        name: 'Djibouti',
        countryCode: 'dj',
        phoneCode: '+253',
    },
    {
        name: 'Dominica',
        countryCode: 'dm',
        phoneCode: '+1767',
    },
    {
        name: 'Dominican Republic',
        countryCode: 'do',
        phoneCode: '+1809',
    },
    {
        name: 'Ecuador',
        countryCode: 'ec',
        phoneCode: '+593',
    },
    {
        name: 'Egypt',
        countryCode: 'eg',
        phoneCode: '+20',
    },
    {
        name: 'El Salvador',
        countryCode: 'sv',
        phoneCode: '+503',
    },
    {
        name: 'England',
        countryCode: 'gb eng',
        phoneCode: '+44',
    },
    {
        name: 'Equatorial Guinea',
        countryCode: 'gq',
        phoneCode: '+240',
    },
    {
        name: 'Eritrea',
        countryCode: 'er',
        phoneCode: '+291',
    },
    {
        name: 'Estonia',
        countryCode: 'ee',
        phoneCode: '+372',
    },
    {
        name: 'Ethiopia',
        countryCode: 'et',
        phoneCode: '+251',
    },
    {
        name: 'Falkland Islands',
        countryCode: 'fk',
        phoneCode: '+500',
    },
    {
        name: 'Faroe Islands',
        countryCode: 'fo',
        phoneCode: '+298',
    },
    {
        name: 'Fiji',
        countryCode: 'fj',
        phoneCode: '+679',
    },
    {
        name: 'Finland',
        countryCode: 'fi',
        phoneCode: '+358',
    },
    {
        name: 'France',
        countryCode: 'fr',
        phoneCode: '+33',
    },
    {
        name: 'French Guiana',
        countryCode: 'gf',
        phoneCode: '+594',
    },
    {
        name: 'French Polynesia',
        countryCode: 'pf',
        phoneCode: '+689',
    },
    {
        name: 'French Territories',
        countryCode: 'tf',
        phoneCode: '+262',
    },
    {
        name: 'Gabon',
        countryCode: 'ga',
        phoneCode: '+241',
    },
    {
        name: 'Gambia',
        countryCode: 'gm',
        phoneCode: '+220',
    },
    {
        name: 'Georgia',
        countryCode: 'ge',
        phoneCode: '+995',
    },
    {
        name: 'Germany',
        countryCode: 'de',
        phoneCode: '+49',
    },
    {
        name: 'Ghana',
        countryCode: 'gh',
        phoneCode: '+233',
    },
    {
        name: 'Gibraltar',
        countryCode: 'gi',
        phoneCode: '+350',
    },
    {
        name: 'Greece',
        countryCode: 'gr',
        phoneCode: '+30',
    },
    {
        name: 'Greenland',
        countryCode: 'gl',
        phoneCode: '+299',
    },
    {
        name: 'Grenada',
        countryCode: 'gd',
        phoneCode: '+1473',
    },
    {
        name: 'Guadeloupe',
        countryCode: 'gp',
        phoneCode: '+590',
    },
    {
        name: 'Guam',
        countryCode: 'gu',
        phoneCode: '+1671',
    },
    {
        name: 'Guatemala',
        countryCode: 'gt',
        phoneCode: '+502',
    },
    {
        name: 'Guinea',
        countryCode: 'gn',
        phoneCode: '+224',
    },
    {
        name: 'Guinea-Bissau',
        countryCode: 'gw',
        phoneCode: '+245',
    },
    {
        name: 'Guyana',
        countryCode: 'gy',
        phoneCode: '+592',
    },
    {
        name: 'Haiti',
        countryCode: 'ht',
        phoneCode: '+509',
    },
    {
        name: 'Heard Island',
        countryCode: 'hm',
        phoneCode: '+ ',
    },
    {
        name: 'Honduras',
        countryCode: 'hn',
        phoneCode: '+504',
    },
    {
        name: 'Hong Kong',
        countryCode: 'hk',
        phoneCode: '+852',
    },
    {
        name: 'Hungary',
        countryCode: 'hu',
        phoneCode: '+36',
    },
    {
        name: 'Iceland',
        countryCode: 'is',
        phoneCode: '+354',
    },
    {
        name: 'India',
        countryCode: 'in',
        phoneCode: '+91',
    },
    {
        name: 'Indian Ocean Territory',
        countryCode: 'io',
        phoneCode: '+246',
    },
    {
        name: 'Indonesia',
        countryCode: 'id',
        phoneCode: '+62',
    },
    {
        name: 'Iran',
        countryCode: 'ir',
        phoneCode: '+98',
    },
    {
        name: 'Iraq',
        countryCode: 'iq',
        phoneCode: '+964',
    },
    {
        name: 'Ireland',
        countryCode: 'ie',
        phoneCode: '+353',
    },
    {
        name: 'Israel',
        countryCode: 'il',
        phoneCode: '+972',
    },
    {
        name: 'Italy',
        countryCode: 'it',
        phoneCode: '+39',
    },
    {
        name: 'Jamaica',
        countryCode: 'jm',
        phoneCode: '+1876',
    },
    {
        name: 'Jan Mayen',
        countryCode: 'sj',
        phoneCode: '+47',
    },
    {
        name: 'Japan',
        countryCode: 'jp',
        phoneCode: '+81',
    },
    {
        name: 'Jordan',
        countryCode: 'jo',
        phoneCode: '+962',
    },
    {
        name: 'Kazakhstan',
        countryCode: 'kz',
        phoneCode: '+7',
    },
    {
        name: 'Kenya',
        countryCode: 'ke',
        phoneCode: '+254',
    },
    {
        name: 'Kiribati',
        countryCode: 'ki',
        phoneCode: '+686',
    },
    {
        name: 'Kuwait',
        countryCode: 'kw',
        phoneCode: '+965',
    },
    {
        name: 'Kyrgyzstan',
        countryCode: 'kg',
        phoneCode: '+996',
    },
    {
        name: 'Laos',
        countryCode: 'la',
        phoneCode: '+856',
    },
    {
        name: 'Latvia',
        countryCode: 'lv',
        phoneCode: '+371',
    },
    {
        name: 'Lebanon',
        countryCode: 'lb',
        phoneCode: '+961',
    },
    {
        name: 'Lesotho',
        countryCode: 'ls',
        phoneCode: '+266',
    },
    {
        name: 'Liberia',
        countryCode: 'lr',
        phoneCode: '+231',
    },
    {
        name: 'Libya',
        countryCode: 'ly',
        phoneCode: '+218',
    },
    {
        name: 'Liechtenstein',
        countryCode: 'li',
        phoneCode: '+423',
    },
    {
        name: 'Lithuania',
        countryCode: 'lt',
        phoneCode: '+370',
    },
    {
        name: 'Luxembourg',
        countryCode: 'lu',
        phoneCode: '+352',
    },
    {
        name: 'Macau',
        countryCode: 'mo',
        phoneCode: '+853',
    },
    {
        name: 'Macedonia',
        countryCode: 'mk',
        phoneCode: '+389',
    },
    {
        name: 'Madagascar',
        countryCode: 'mg',
        phoneCode: '+261',
    },
    {
        name: 'Malawi',
        countryCode: 'mw',
        phoneCode: '+265',
    },
    {
        name: 'Malaysia',
        countryCode: 'my',
        phoneCode: '+60',
    },
    {
        name: 'Maldives',
        countryCode: 'mv',
        phoneCode: '+960',
    },
    {
        name: 'Mali',
        countryCode: 'ml',
        phoneCode: '+223',
    },
    {
        name: 'Malta',
        countryCode: 'mt',
        phoneCode: '+356',
    },
    {
        name: 'Marshall Islands',
        countryCode: 'mh',
        phoneCode: '+692',
    },
    {
        name: 'Martinique',
        countryCode: 'mq',
        phoneCode: '+596',
    },
    {
        name: 'Mauritania',
        countryCode: 'mr',
        phoneCode: '+222',
    },
    {
        name: 'Mauritius',
        countryCode: 'mu',
        phoneCode: '+230',
    },
    {
        name: 'Mayotte',
        countryCode: 'yt',
        phoneCode: '+262',
    },
    {
        name: 'Micronesia',
        countryCode: 'fm',
        phoneCode: '+691',
    },
    {
        name: 'Moldova',
        countryCode: 'md',
        phoneCode: '+373',
    },
    {
        name: 'Monaco',
        countryCode: 'mc',
        phoneCode: '+377',
    },
    {
        name: 'Mongolia',
        countryCode: 'mn',
        phoneCode: '+976',
    },
    {
        name: 'Montenegro',
        countryCode: 'me',
        phoneCode: '+382',
    },
    {
        name: 'Montserrat',
        countryCode: 'ms',
        phoneCode: '+1664',
    },
    {
        name: 'Morocco',
        countryCode: 'ma',
        phoneCode: '+212',
    },
    {
        name: 'Mozambique',
        countryCode: 'mz',
        phoneCode: '+258',
    },
    {
        name: 'Namibia',
        countryCode: 'na',
        phoneCode: '+264',
    },
    {
        name: 'Nauru',
        countryCode: 'nr',
        phoneCode: '+674',
    },
    {
        name: 'Nepal',
        countryCode: 'np',
        phoneCode: '+977',
    },
    {
        name: 'Netherlands',
        countryCode: 'nl',
        phoneCode: '+31',
    },
    {
        name: 'Netherlandsantilles',
        countryCode: 'an',
        phoneCode: '+599',
    },
    {
        name: 'New Caledonia',
        countryCode: 'nc',
        phoneCode: '+687',
    },
    {
        name: 'New Guinea',
        countryCode: 'pg',
        phoneCode: '+675',
    },
    {
        name: 'New Zealand',
        countryCode: 'nz',
        phoneCode: '+64',
    },
    {
        name: 'Nicaragua',
        countryCode: 'ni',
        phoneCode: '+505',
    },
    {
        name: 'Niger',
        countryCode: 'ne',
        phoneCode: '+227',
    },
    {
        name: 'Nigeria',
        countryCode: 'ng',
        phoneCode: '+234',
    },
    {
        name: 'Niue',
        countryCode: 'nu',
        phoneCode: '+683',
    },
    {
        name: 'Norfolk Island',
        countryCode: 'nf',
        phoneCode: '+672',
    },
    {
        name: 'North Korea',
        countryCode: 'kp',
        phoneCode: '+850',
    },
    {
        name: 'Northern Mariana Islands',
        countryCode: 'mp',
        phoneCode: '+1670',
    },
    {
        name: 'Norway',
        countryCode: 'no',
        phoneCode: '+47',
    },
    {
        name: 'Oman',
        countryCode: 'om',
        phoneCode: '+968',
    },
    {
        name: 'Pakistan',
        countryCode: 'pk',
        phoneCode: '+92',
    },
    {
        name: 'Palau',
        countryCode: 'pw',
        phoneCode: '+680',
    },
    {
        name: 'Palestine',
        countryCode: 'ps',
        phoneCode: '+970',
    },
    {
        name: 'Panama',
        countryCode: 'pa',
        phoneCode: '+507',
    },
    {
        name: 'Paraguay',
        countryCode: 'py',
        phoneCode: '+595',
    },
    {
        name: 'Peru',
        countryCode: 'pe',
        phoneCode: '+51',
    },
    {
        name: 'Philippines',
        countryCode: 'ph',
        phoneCode: '+63',
    },
    {
        name: 'Pitcairn Islands',
        countryCode: 'pn',
        phoneCode: '+870',
    },
    {
        name: 'Poland',
        countryCode: 'pl',
        phoneCode: '+48',
    },
    {
        name: 'Portugal',
        countryCode: 'pt',
        phoneCode: '+351',
    },
    {
        name: 'Puerto Rico',
        countryCode: 'pr',
        phoneCode: '+1787',
    },
    {
        name: 'Qatar',
        countryCode: 'qa',
        phoneCode: '+974',
    },
    {
        name: 'Reunion',
        countryCode: 're',
        phoneCode: '+262',
    },
    {
        name: 'Romania',
        countryCode: 'ro',
        phoneCode: '+40',
    },
    {
        name: 'Russia',
        countryCode: 'ru',
        phoneCode: '+7',
    },
    {
        name: 'Rwanda',
        countryCode: 'rw',
        phoneCode: '+250',
    },
    {
        name: 'Saint Helena',
        countryCode: 'sh',
        phoneCode: '+290',
    },
    {
        name: 'Saint Kitts and Nevis',
        countryCode: 'kn',
        phoneCode: '+1869',
    },
    {
        name: 'Saint Lucia',
        countryCode: 'lc',
        phoneCode: '+1758',
    },
    {
        name: 'Saint Pierre',
        countryCode: 'pm',
        phoneCode: '+508',
    },
    {
        name: 'Saint Vincent',
        countryCode: 'vc',
        phoneCode: '+1784',
    },
    {
        name: 'Samoa',
        countryCode: 'ws',
        phoneCode: '+685',
    },
    {
        name: 'San Marino',
        countryCode: 'sm',
        phoneCode: '+378',
    },
    {
        name: 'Sandwich Islands',
        countryCode: 'gs',
        phoneCode: '+500',
    },
    {
        name: 'Sao Tome',
        countryCode: 'st',
        phoneCode: '+239',
    },
    {
        name: 'Saudi Arabia',
        countryCode: 'sa',
        phoneCode: '+966',
    },
    {
        name: 'Scotland',
        countryCode: 'gb sct',
        phoneCode: '+44',
    },
    {
        name: 'Senegal',
        countryCode: 'sn',
        phoneCode: '+221',
    },
    {
        name: 'Serbia',
        countryCode: 'cs',
        phoneCode: '+381',
    },
    {
        name: 'Serbia',
        countryCode: 'rs',
        phoneCode: '+381',
    },
    {
        name: 'Seychelles',
        countryCode: 'sc',
        phoneCode: '+248',
    },
    {
        name: 'Sierra Leone',
        countryCode: 'sl',
        phoneCode: '+232',
    },
    {
        name: 'Singapore',
        countryCode: 'sg',
        phoneCode: '+65',
    },
    {
        name: 'Slovakia',
        countryCode: 'sk',
        phoneCode: '+421',
    },
    {
        name: 'Slovenia',
        countryCode: 'si',
        phoneCode: '+386',
    },
    {
        name: 'Solomon Islands',
        countryCode: 'sb',
        phoneCode: '+677',
    },
    {
        name: 'Somalia',
        countryCode: 'so',
        phoneCode: '+252',
    },
    {
        name: 'South Africa',
        countryCode: 'za',
        phoneCode: '+27',
    },
    {
        name: 'South Korea',
        countryCode: 'kr',
        phoneCode: '+82',
    },
    {
        name: 'Spain',
        countryCode: 'es',
        phoneCode: '+34',
    },
    {
        name: 'Sri Lanka',
        countryCode: 'lk',
        phoneCode: '+94',
    },
    {
        name: 'Sudan',
        countryCode: 'sd',
        phoneCode: '+249',
    },
    {
        name: 'Suriname',
        countryCode: 'sr',
        phoneCode: '+597',
    },
    {
        name: 'Swaziland',
        countryCode: 'sz',
        phoneCode: '+268',
    },
    {
        name: 'Sweden',
        countryCode: 'se',
        phoneCode: '+46',
    },
    {
        name: 'Switzerland',
        countryCode: 'ch',
        phoneCode: '+41',
    },
    {
        name: 'Syria',
        countryCode: 'sy',
        phoneCode: '+963',
    },
    {
        name: 'Taiwan',
        countryCode: 'tw',
        phoneCode: '+886',
    },
    {
        name: 'Tajikistan',
        countryCode: 'tj',
        phoneCode: '+992',
    },
    {
        name: 'Tanzania',
        countryCode: 'tz',
        phoneCode: '+255',
    },
    {
        name: 'Thailand',
        countryCode: 'th',
        phoneCode: '+66',
    },
    {
        name: 'Timorleste',
        countryCode: 'tl',
        phoneCode: '+670',
    },
    {
        name: 'Togo',
        countryCode: 'tg',
        phoneCode: '+228',
    },
    {
        name: 'Tokelau',
        countryCode: 'tk',
        phoneCode: '+690',
    },
    {
        name: 'Tonga',
        countryCode: 'to',
        phoneCode: '+676',
    },
    {
        name: 'Trinidad',
        countryCode: 'tt',
        phoneCode: '+1868',
    },
    {
        name: 'Tunisia',
        countryCode: 'tn',
        phoneCode: '+216',
    },
    {
        name: 'Turkey',
        countryCode: 'tr',
        phoneCode: '+90',
    },
    {
        name: 'Turkmenistan',
        countryCode: 'tm',
        phoneCode: '+993',
    },
    {
        name: 'Tuvalu',
        countryCode: 'tv',
        phoneCode: '+688',
    },
    {
        name: 'U.A.E.',
        countryCode: 'ae',
        phoneCode: '+971',
    },
    {
        name: 'Uganda',
        countryCode: 'ug',
        phoneCode: '+256',
    },
    {
        name: 'Ukraine',
        countryCode: 'ua',
        phoneCode: '+380',
    },
    {
        name: 'United Kingdom',
        countryCode: 'gb',
        phoneCode: '+44',
    },
    {
        name: 'Uruguay',
        countryCode: 'uy',
        phoneCode: '+598',
    },
    {
        name: 'US Minor Islands',
        countryCode: 'um',
        phoneCode: '+1',
    },
    {
        name: 'US Virgin Islands',
        countryCode: 'vi',
        phoneCode: '+1340',
    },
    {
        name: 'Uzbekistan',
        countryCode: 'uz',
        phoneCode: '+998',
    },
    {
        name: 'Vanuatu',
        countryCode: 'vu',
        phoneCode: '+678',
    },
    {
        name: 'Vatican City',
        countryCode: 'va',
        phoneCode: '+379',
    },
    {
        name: 'Venezuela',
        countryCode: 've',
        phoneCode: '+58',
    },
    {
        name: 'Vietnam',
        countryCode: 'vn',
        phoneCode: '+84',
    },
    {
        name: 'Wales',
        countryCode: 'gb wls',
        phoneCode: '+44',
    },
    {
        name: 'Wallis and Futuna',
        countryCode: 'wf',
        phoneCode: '+681',
    },
    {
        name: 'Western Sahara',
        countryCode: 'eh',
        phoneCode: '+212',
    },
    {
        name: 'Yemen',
        countryCode: 'ye',
        phoneCode: '+967',
    },
    {
        name: 'Zambia',
        countryCode: 'zm',
        phoneCode: '+260',
    },
    {
        name: 'Zimbabwe',
        countryCode: 'zw',
        phoneCode: '+263',
    },
];

interface Option {
    label: string;
    value: string;
    flagCode: FlagNameValues;
}

const usOption: Option = {
    label: 'United States',
    value: '+1',
    flagCode: 'us',
};

export const getOptionsForSelect = (): Option[] => {
    const options = countriesInformation.map((c: CountryInformation) => {
        const country: Option = {
            label: c.name,
            value: c.phoneCode,
            flagCode: c.countryCode,
        };
        return country;
    });
    return options;
};

const options = getOptionsForSelect();

export const getOptionFromValue = (phoneCode: string): Option => {
    return options.find((option) => option.value === phoneCode) || usOption;
};

export const getLables = (option: any, { context }: any) => {
    const flag = <Flag name={option.flagCode} />;
    return context === 'menu' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {flag}({option.value})
        </div>
    ) : (
        flag
    );
};
