import React from 'react';
import FaqItem from './FaqItem';
import styles from './FAQ.module.css';

const Faq = (props: any) => {
    const { destination, closestParkingSpot } = props;

    const closestParkingSpotSpotLink =
        closestParkingSpot && `/spot/${closestParkingSpot.pk}/?ref=destination-page&rentalType=hourly`;
    return (
        <div className={styles.root}>
            <h1>FAQ</h1>
            {closestParkingSpotSpotLink && (
                <FaqItem active question={`Which parking lot is nearest to ${destination}?`}>
                    <p>
                        The parking lot closest to <b>{destination}</b> is{' '}
                        <a href={closestParkingSpotSpotLink}>
                            <b>{closestParkingSpot.name}</b>{' '}
                        </a>{' '}
                        at
                        <b>{` ${closestParkingSpot.address}`}</b>
                    </p>
                </FaqItem>
            )}
            <FaqItem question={'How do I start a parking rental?'}>
                To book hourly parking at any of our parking lot's, click a lot to get to the webpay form, enter your
                phone, payment info, and license plate number on the next page.
            </FaqItem>

            <FaqItem question={'How do I end my parking rental?'}>
                To end your rental, navigate to the link we text you and press 'End Rental'. We prorate by the minute
                after the first hour, so you never overpay.
            </FaqItem>

            <FaqItem question={'How does AirGarage work?'}>
                AirGarage is a parking operator that handles customer support, payment collection, and more on parking
                spot's behalf.
            </FaqItem>
        </div>
    );
};

export default Faq;
