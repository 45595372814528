import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ENV from 'env';

Sentry.init({
    dsn: 'https://445dab952346412a9459db69855b7a26@o228407.ingest.sentry.io/6585141',
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.NODE_ENV,
    integrations: [
        new BrowserTracing({
            tracingOrigins: ['localhost', '0.0.0.0', 'https://api.airgarage.com'],
        }),
    ],
    tracesSampleRate: 0.2,
    release: `webpay@${ENV.PACKAGE_VERSION}`,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
