import React, { useEffect } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Button, Modal } from 'semantic-ui-react';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Rating from '@material-ui/lab/Rating';
import { getRating, handleDynamicPricingLogInMapList } from '../../utils/helpers';
import styles from './SpotInfo.module.css';
import secondStyles from './DestinationMobileItem.module.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface Props {
    spot: any;
    toggleDrawer: (spot: any) => void;
    rentalType: string;
}
const SpotInfo = ({ spot, toggleDrawer, rentalType }: Props) => {
    const { pk, name, address, photo_url, display_price } = spot;
    const screenSizeIsWeb = useMediaQuery('(min-width:1024px)');
    const spotLink = `/spot/${pk}/?ref=destination-pages&rentalType=${rentalType}`;

    useEffect(() => {
        handleDynamicPricingLogInMapList(spot, rentalType);
        // eslint-disable-next-line
    }, []);

    const spotInfoData = () => (
        <div className={styles.container}>
            <CloseRoundedIcon className={styles.close} onClick={() => toggleDrawer(null)} />
            <div className={styles.headerSection}>
                <img src={photo_url} alt={`spot-${name}-${address}`} className={styles.image} />
                <div>
                    <h1 className={styles.name}>{name}</h1>
                    <p className={styles.address}>{address}</p>
                    <Rating readOnly defaultValue={Number(getRating([0, 9]))} precision={0.5} size="small" />
                    <div style={{ textAlign: 'left' }} className={secondStyles.spotPrice}>
                        {display_price}
                        <span style={{ color: '#717171' }}>per/hr</span>
                    </div>
                </div>
            </div>

            <Button
                content="Book Now"
                fluid
                color={'orange'}
                style={{
                    marginTop: '30px',
                }}
                href={spotLink}
            />
        </div>
    );

    return (
        <>
            {screenSizeIsWeb ? (
                <Modal onClose={toggleDrawer} size="tiny" open={!!spot} trigger={<Button>Basic Modal</Button>}>
                    {spotInfoData()}
                </Modal>
            ) : (
                <SwipeableDrawer anchor={'bottom'} open={true} onClose={toggleDrawer} onOpen={toggleDrawer}>
                    {spotInfoData()}
                </SwipeableDrawer>
            )}
        </>
    );
};

export default SpotInfo;
