import React from 'react';
import { List } from 'semantic-ui-react';
import { currentYear } from 'utils/constants';
import styles from './Footer.module.css';
import { AG_HELP_CENTER_LINK } from '../../utils/constants';

const Footer = (props: any) => {
    return (
        <div className={styles.center}>
            <p style={{ color: '#fff', marginTop: 15 }}>©{currentYear} AirGarage, Inc</p>
            <List horizontal>
                <List.Item as="a" style={{ color: '#fff' }} href="https://airgarage.com/">
                    Homepage
                </List.Item>
                <List.Item as="a" style={{ color: '#fff' }} href="https://airgarage.com/terms">
                    Terms
                </List.Item>
                <List.Item as="a" style={{ color: '#fff' }} href={AG_HELP_CENTER_LINK}>
                    Contact us
                </List.Item>
            </List>
        </div>
    );
};
export default Footer;
