import { useState, ChangeEvent } from 'react';

type GenericObject = { [key: string]: any };

function useForm<T>(initialInputs: T) {
    const [inputs, setInputs] = useState(initialInputs);
    const handleInput = (event: ChangeEvent<HTMLInputElement>, spaces: any = false) => {
        event.persist();
        const value = spaces ? event.target.value : event.target.value.trim();
        setInputs((inputs: any) => ({ ...inputs, [event.target.name]: value }));
    };
    const handleDropdown = (event: any, data: any) => {
        setInputs((inputs: any) => ({ ...inputs, [data.name]: data.value }));
    };
    // for non-input and non-dropdown changes
    const handleValueChange = (name: any, newValue: any) => {
        setInputs((inputs: any) => ({ ...inputs, [name]: newValue }));
    };
    const resetState = (inputState: any) => {
        setInputs(() => ({ ...inputState }));
    };
    return { handleInput, handleDropdown, handleValueChange, resetState, setInputs, inputs };
}

export default useForm;
