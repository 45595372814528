import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Checkbox, Form, Header } from 'semantic-ui-react';
import formStyles from './FormFields.module.css';
import 'react-datepicker/dist/react-datepicker.css';

interface EndDateProps {
    monthly: boolean | null;
    invoice: boolean | null;
    minDate: Date | null;
    inputs: any;
    handleValueChange: (name: string, value: any) => void;
    minimumBookingLength: number;
    excludeDates?: Date[];
}

const EndDateField = ({
    monthly,
    invoice,
    minDate,
    inputs,
    handleValueChange,
    excludeDates,
    minimumBookingLength,
}: EndDateProps) => {
    const [noEndDate, toggleEndDate] = useState(true);

    return (
        <>
            <Form.Field>
                <Header as="h5" className={formStyles.fieldName}>
                    End date{' '}
                </Header>
                {monthly && (
                    <Checkbox
                        defaultChecked
                        className={formStyles.checkbox}
                        toggle
                        label="Until I Cancel"
                        onClick={() => toggleEndDate(!noEndDate)}
                    />
                )}
                {monthly && noEndDate ? (
                    <div>This rental will be billed monthly and you may cancel anytime.</div>
                ) : (
                    <Form.Input name="end" required>
                        <DatePicker
                            className={formStyles.dateField}
                            placeholderText="End date"
                            minDate={minDate}
                            selected={inputs.end}
                            onChange={(end) => handleValueChange('end', end)}
                            disabled={!inputs.start}
                            excludeDates={excludeDates}
                        >
                            {!invoice && (
                                <p className={formStyles.dateNotification}>
                                    Rental minimum: {minimumBookingLength} days
                                </p>
                            )}
                        </DatePicker>
                    </Form.Input>
                )}
            </Form.Field>
        </>
    );
};

export default EndDateField;
