import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getSearchResultCoordsAction, navigateToResultsAction, getDestinationAction } from 'actions/actions';
import { getDestinationSelector } from 'selectors';
import { Loader } from 'semantic-ui-react';
import DestinationMobile from 'components/Destinations/DestinationMobile';

const DestinationModuleContainer = (props: any) => {
    const { destinationSlug } = props.match.params;
    const { getSearchResultCoords, coords, navigateToResults, getDestination } = props;
    const [rentalType, setRentalType] = useState('hourly');

    const destination = useSelector(getDestinationSelector);

    useEffect(() => {
        getDestination(destinationSlug);
    }, [destinationSlug, getDestination]);

    return (
        <>
            {destination?.name ? (
                <DestinationMobile
                    getSearchResultCoords={getSearchResultCoords}
                    coords={coords}
                    navigateToResults={navigateToResults}
                    rentalType={rentalType}
                    setRentalType={setRentalType}
                    destination={destination}
                />
            ) : (
                <Loader inverted active size={'large'}>
                    Loading
                </Loader>
            )}
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.search.errorMessage,
        loading: state.search.loading,
        coords: state.search.coords,
    };
}

const mapDispatchToProps = {
    getSearchResultCoords: getSearchResultCoordsAction,
    navigateToResults: navigateToResultsAction,
    getDestination: getDestinationAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DestinationModuleContainer));
