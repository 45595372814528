import React from 'react';
import { withRouter } from 'react-router-dom';
import { buildRentalDetails } from '../../utils/helpers';
import SpotCardResult from './SpotCardResult';

const Results = (props: any) => {
    const { spots, rentalType } = props;
    return spots.map((spot: any, index: number) => {
        const { link, price, interval } = buildRentalDetails(spot, rentalType);
        return (
            <SpotCardResult
                key={spot.pk}
                spot={spot}
                link={link}
                price={price}
                interval={interval}
                rentalType={rentalType}
            />
        );
    });
};

export default withRouter(Results);
