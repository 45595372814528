import React from 'react';
import styles from './DestinationMobile.module.css';
import SearchBar from '../SearchBar/SearchBar';
import { Button, Dropdown } from 'semantic-ui-react';
import { rentalOptions } from '../../utils/constants';

const SearchSection = (props: any) => {
    const { getSearchResultCoords, setRentalType, navigateToResults, coords, rentalType } = props;
    return (
        <div className={styles.searchSection}>
            <div className={styles.searchItem}>
                <span className={styles.searchLabel}>Destination</span>
                <SearchBar getSearchResultCoords={getSearchResultCoords} />
            </div>
            <div>
                <span className={styles.searchLabel}>Parking Type</span>
                <Dropdown
                    selection
                    fluid
                    placeholder="Hourly"
                    options={rentalOptions}
                    onChange={(e, data: any) => setRentalType(data.value)}
                    className={styles.dropdown}
                />
            </div>
            <Button
                content="Find Parking"
                fluid
                onClick={() => navigateToResults(coords, rentalType)}
                color={'orange'}
                className={styles.searchButton}
            />
        </div>
    );
};

export default SearchSection;
