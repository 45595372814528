import APIClient from './APIClient';

class DestinationService extends Object {
    async getDestinationList() {
        return await APIClient.get('destinations/', '');
    }
    async getDestinationDetail(slug: string) {
        const url = slug ? `destinations/${slug}/` : `destinations/1`;
        return await APIClient.get(url, '');
    }
}

export default new DestinationService();
