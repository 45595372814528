import React from 'react';
import DestinationMobileItem from './DestinationMobileItem';
import Carousel from '../Carousel/Carousel';
import styles from './DestinationMobileGroup.module.css';
import { Header } from 'semantic-ui-react';

interface Props {
    title: string;
    subTitle: string;
    spots: [];
    coords: any;
    rentalType: string;
}

const DestinationMobileGroup: React.FC<Props> = (props: Props) => {
    const { title, spots, coords, rentalType, subTitle } = props;

    const priceRate = rentalType === 'monthly' ? ' monthly' : ' hourly';

    if (rentalType === 'monthly') {
        spots.filter((spot) => !!spot['display_price_monthly']);
    }

    const viewAllUrl = `/spots/?lat=${coords.lat}&lng=${coords.lng}&rentalType=${rentalType}`;
    return (
        <div className={styles.container}>
            <div className={styles.groupHeader}>
                <div>
                    <Header size={'huge'}>{title}</Header>
                    <h4 className={styles.subTitle}>{subTitle}</h4>
                </div>

                <a className={styles.groupLink} href={viewAllUrl}>
                    view all
                </a>
            </div>
            <Carousel>
                {spots.map((spot) => {
                    const { pk, name, address } = spot;
                    const price = rentalType === 'monthly' ? spot['display_price_monthly'] : spot['display_price'];
                    return (
                        <DestinationMobileItem
                            key={`spot-${name}`}
                            pk={pk}
                            spotName={name}
                            spotAddress={address}
                            spot={spot}
                            imgUrl={spot['photo_url']}
                            price={price}
                            priceRate={priceRate}
                            rentalType={rentalType}
                        />
                    );
                })}
            </Carousel>
        </div>
    );
};

export default DestinationMobileGroup;
