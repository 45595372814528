import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import RentalsModuleContainer from './containers/RentalsModuleContainer';
import SpotsModuleContainer from './containers/SpotsModuleContainer';
import SpotInfoModuleContainer from './containers/SpotInfoModuleContainer';
import InvoiceModuleContainer from './containers/InvoiceModuleContainer';
import CheckInModuleContainer from './containers/CheckInModuleContainer';
import DestinationModuleContainer from './containers/DestinationModuleContainer';
import VisitorFormContainer from 'containers/VisitorFormContainer';
import SiteMap from 'containers/SiteMapContainer';
import SearchModuleContainer from 'containers/SearchModuleContainer';
import AutoPromoModuleContainer from 'containers/AutoPromoModuleContainer';
import RedirectToQuickpay from 'components/RedirectToQuickpay/RedirectToQuickpay';

import { history } from './store/index';
import ExpireLinkModuleContainer from 'containers/ExpiredLinkModuleContainer';
import ExternalRedirect from 'components/ExternalRedirect/ExternalRedirect';

const ReactRouter = () => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/expired">
                    <ExpireLinkModuleContainer />
                </Route>
                <Route path="/spots">
                    <SpotsModuleContainer />
                </Route>
                <Route path="/search">
                    <SearchModuleContainer />
                </Route>
                <Route path="/destinations/:destinationSlug?">
                    <DestinationModuleContainer />
                </Route>
                <Route path="/sitemap">
                    <SiteMap />
                </Route>
                <Route path="/spot/:id">
                    <RedirectToQuickpay />
                </Route>
                <Route path="/visitor/:spotId">
                    <VisitorFormContainer />
                </Route>
                <Route path="/invoice/:id">
                    <InvoiceModuleContainer />
                </Route>
                <Route path="/info/:id">
                    <SpotInfoModuleContainer />
                </Route>
                <Route path="/contact">
                    <ExternalRedirect url="https://help.airgarage.com" />
                </Route>
                {/* Future updates to /checkin/:id when adding more airport spots */}
                <Route path="/checkin">
                    <CheckInModuleContainer />
                </Route>
                {/* This route is used for QR code promo code */}
                <Route path="/autopromo/:spotId">
                    <AutoPromoModuleContainer />
                </Route>
                <Route path="/">
                    <RentalsModuleContainer />
                </Route>
            </Switch>
        </ConnectedRouter>
    );
};

export default ReactRouter;
