import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ENV from 'env';
import WebpayModuleContainer from 'containers/WebpayModuleContainer';

const RedirectToQuickpay = (props: any) => {
    const params = queryString.parse(props.location.search);
    const { ref, rentalType } = params;
    const spotId = props.match.params.id;
    const spotRef = ref ? ref : null;
    const spotRentalType = rentalType ? rentalType : 'hourly';
    let redirectUrl = `${ENV.QUICKPAY_DOMAIN}spots/${spotId}/?rental_type=${spotRentalType}`;

    if (spotRef) {
        redirectUrl += `&ref=${spotRef}`;
    }

    // Do not redirect illegal parking fines
    // 768 - Illegal Parking Fine
    // 941 - Beaufort Illegal Parking Fine
    // 953 - Elite Sports Illegal Parking Fine
    const illegalSpotIds = [768, 941, 953];
    if (illegalSpotIds.includes(Number(spotId))) {
        return <WebpayModuleContainer />;
    }

    window.location.replace(redirectUrl);

    return null;
};

export default withRouter(RedirectToQuickpay);
