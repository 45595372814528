import React from 'react';

import Footer from 'components/Footer/Footer';

import styles from './ExpiredLinkCard.module.css';

const ExpiredLinkCard = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.cardWrapper}>
                <span className={styles.text}>
                    Your session has expired. We've sent you a text with a link to end your rental
                </span>
            </div>
            <Footer />
        </div>
    );
};

export default ExpiredLinkCard;
