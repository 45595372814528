import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Webpay from 'components/Webpay/Webpay';
import { Loader } from 'semantic-ui-react';

import { getSpotAction, clearSpotAction, bookRentalAction, getUserDetailsAction } from '../actions/actions';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

const WebpayModuleContainer = (props: any) => {
    const params = queryString.parse(props.location.search);
    const { phone, pin, monthly, source, ref, promo, daily, rentalType, phoneCode, airportCheckin } = params;
    const {
        spot,
        getSpot,
        clearSpot,
        bookRental,
        getUserDetails,
        creditCard,
        authLoading,
        errorMessage,
        successMessage,
        promoSuccessMessage,
        promoErrorMessage,
    } = props;

    const defaultCar = {
        plate: params.plate ? params.plate : props.defaultCar.plate,
        state: params.state ? params.state : props.defaultCar.state,
        country: params.country ? params.country : props.defaultCar.country,
    };

    const monthlyRental = rentalType === 'monthly' || monthly;
    const dailyRental = rentalType === 'daily' || daily;
    const spotId = props.match.params.id;
    const monthlySoldOut = false;
    const monthlyMode = monthlyRental && !monthlySoldOut;
    useEffect(() => {
        if (pin) {
            getUserDetails(phone, pin);
        }
    }, [getUserDetails, phone, pin]);

    useEffect(() => {
        getSpot(spotId);
        return () => clearSpot();
    }, [clearSpot, getSpot, spotId]);

    const loading = !spot;
    return (
        <div>
            {!loading ? (
                <Webpay
                    spot={spot}
                    promo={promo}
                    authLoading={authLoading}
                    bookRental={bookRental}
                    monthly={monthlyMode}
                    daily={dailyRental}
                    monthlySoldOut={monthlySoldOut}
                    phone={phone}
                    defaultCar={defaultCar}
                    creditCard={creditCard}
                    error={errorMessage}
                    successMessage={successMessage}
                    source={source}
                    referrer={ref}
                    phoneCode={phoneCode}
                    airportCheckin={airportCheckin}
                    promoSuccessMessage={promoSuccessMessage}
                    promoErrorMessage={promoErrorMessage}
                />
            ) : (
                <Loader inverted active />
            )}
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.rentals.errorMessage,
        successMessage: state.rentals.successMessage,
        loading: state.spots.loading,
        authLoading: state.auth.detailsLoading,
        spot: state.spots.spot,
        defaultCar: state.auth.defaultCar,
        creditCard: state.auth.defaultCreditCard,
        promoSuccessMessage: state.promo.successMessage,
        promoErrorMessage: state.promo.errorMessage,
    };
}

const mapDispatchToProps = {
    getSpot: getSpotAction,
    clearSpot: clearSpotAction,
    bookRental: bookRentalAction,
    getUserDetails: getUserDetailsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebpayModuleContainer));
