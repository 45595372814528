import React, { useState } from 'react';
import { Loader, Checkbox } from 'semantic-ui-react';
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';
import SpotPopup from '../Spots/SpotPopup';
import style from './SpotInfo.module.css';
import ENV from 'env';

const Map = ReactMapboxGl({
    attributionControl: false,
    accessToken: ENV.MAPBOX_TOKEN,
});

const SpotMap = (props: any) => {
    const {
        spots,
        loading,
        getSpotsNearDraggedArea,
        mapCenter,
        setMapCenter,
        rentalType,
        showPopup,
        setShowPopup,
        markerId,
        setMarkerId,
        zoom,
    } = props;
    const [searchOnDrag, setSearchOnDrag] = useState(false);

    return (
        <Map
            style={`mapbox://styles/mapbox/light-v10?optimize=true`}
            onDragEnd={searchOnDrag && getSpotsNearDraggedArea}
            center={[mapCenter[1], mapCenter[0]]}
            zoom={zoom}
            containerStyle={{ height: '90vh', width: '100%', borderRadius: 10 }}
        >
            <ZoomControl />

            <Checkbox
                className={style.checkbox}
                label="Search as I move map"
                onChange={() => setSearchOnDrag(!searchOnDrag)}
                checked={searchOnDrag}
            />

            {loading && <Loader active content="Loading spots" />}

            {spots.map((spot: any, index: number) => {
                let price = spot.dynamic_price;
                if (rentalType === 'monthly') {
                    price = spot.price_monthly;
                } else if (rentalType === 'daily') {
                    price = spot.price_daily;
                }
                price = `$${(price / 100).toFixed(2)}`;
                return (
                    <Marker
                        key={index}
                        coordinates={[spot.address.longitude, spot.address.latitude]}
                        anchor="bottom"
                        onClick={() => {
                            setMapCenter([spot.address.latitude, spot.address.longitude]);
                            setMarkerId(index);
                            setShowPopup(!showPopup);
                        }}
                    >
                        <div className={style.priceMarker}>{price}</div>
                    </Marker>
                );
            })}

            {showPopup && <SpotPopup id={markerId} spots={spots} rentalType={rentalType} />}
        </Map>
    );
};

export default SpotMap;
