import { RentalOptionsData, RentType, Spot } from './constants';
import SpotsService from '../services/SpotsService';
import { CountryCode, mapCountryCodeToName, stateOptions } from 'assets/data/countriesAndStates';
import ENV from 'env';

const baseUrl = (rentalType: RentType, spotPK: string, spotRef?: string): string => {
    let url = `${ENV.QUICKPAY_DOMAIN}spots/${spotPK}/?rental_type=${rentalType}`;
    if (spotRef) {
        url += `&ref=${spotRef}`;
    }
    return url;
};

export const optionsGenerator = (spot: Spot, spotRef?: string): RentalOptionsData[] => {
    const availableRentOptions: RentalOptionsData[] = [];

    const hourlyUrl = baseUrl(RentType.hourly, spot.pk, spotRef);
    const dailyUrl = baseUrl(RentType.daily, spot.pk, spotRef);
    const monthlyUrl = baseUrl(RentType.monthly, spot.pk, spotRef);

    // Dynamically building the dropdown based on the options available on the spot selected
    if (spot.display_price) {
        const isFlatRate = spot.active_rate?.unit === RentType.flatRate;
        const value = isFlatRate ? RentType.flatRate : RentType.hourly;
        const text = isFlatRate ? `Flat Rate - ${spot.display_price}` : `Hourly - ${spot.display_price}`;

        availableRentOptions.push({ value: value, text: text, link: hourlyUrl, buttonTitle: value });
    }
    if (spot.display_price_monthly) {
        availableRentOptions.push({
            value: RentType.monthly,
            text: `Monthly - ${spot.display_price_monthly}`,
            link: monthlyUrl,
            buttonTitle: RentType.monthly,
        });
    }
    if (spot.display_price_daily) {
        availableRentOptions.push({
            value: RentType.daily,
            text: `Daily - ${spot.display_price_daily}`,
            link: dailyUrl,
            buttonTitle: 'multi-day',
        });
    }

    return availableRentOptions;
};

export const cleanExistingURLParams = (urlParams: string): string => {
    const cleanedParams = Object.values(RentType)
        .reduce(
            (cleanedParamsString, rentalTypeString) =>
                cleanedParamsString.replace(`rental_type=${rentalTypeString}`, ''),
            urlParams
        )
        .replace('?', '');

    return cleanedParams[0] === '&' || !cleanedParams ? cleanedParams : `&${cleanedParams}`;
};

export const buildRentalDetails = (spot: any, rentalType: string) => {
    let price = spot.dynamic_price;
    let interval = spot?.active_rate?.unit === RentType.flatRate ? RentType.flatRate : RentType.hourly;
    let link = baseUrl(RentType.hourly, spot.uuid, 'spot-search');
    if (rentalType === 'monthly') {
        price = spot.price_monthly;
        interval = RentType.monthly;
        link = baseUrl(RentType.monthly, spot.uuid, 'spot-search');
    } else if (rentalType === 'daily') {
        price = spot.price_daily;
        interval = RentType.daily;
        link = baseUrl(RentType.daily, spot.uuid, 'spot-search');
    }
    return { link, price, interval };
};

export const getRating = (range: any) => {
    const min = Math.ceil(range[0]);
    const max = Math.floor(range[1]);
    return '4.' + Math.floor(Math.random() * (max - min) + min);
};

export const getSpotPriceByRentalType = (spot: any, rentalType: string) => {
    let price = spot.display_price;
    if (rentalType === 'monthly') {
        price = spot.display_price_monthly;
    } else if (rentalType === 'daily') {
        price = spot.display_price_daily;
    }
    return price;
};

export const getBlackoutDatesList = async (spotPK: number): Promise<Date[] | null> => {
    const result = await SpotsService.getSpotAvailability(spotPK);
    const blackoutDatesList: Date[] = result.monthly_rental_blackout_days.map((dateString: string) => {
        const dateArray: string[] = dateString.split('-');
        const [year, month, day]: number[] = dateArray.map((item) => parseInt(item));
        return new Date(year, month - 1, day);
    });
    return blackoutDatesList;
};

export const maxDate = (dates: Date[]) => new Date(Math.max(...dates.map(Number)));

export const isPhone = (phone: string): boolean => /^[0-9 ()+-]*$/.test(phone);

export const getOnlyNumbers = (phone: string): string => {
    return phone.replace(/\D/g, '');
};

export const validateEmail = (email: string): boolean => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);

interface CheckForUserNameErrorResponse {
    hasToSetError: boolean;
    errorOrNull: string | null;
}

export const checkForUserNameError = (
    requiredUsernameType: string,
    userName: string
): CheckForUserNameErrorResponse => {
    const isValidPhone = isPhone(userName);
    const isValidEmail = validateEmail(userName) || userName === '';

    if (requiredUsernameType === 'email' && !isValidEmail)
        return { hasToSetError: true, errorOrNull: 'Please enter a valid email' };

    if (requiredUsernameType === 'phone' && !isValidPhone)
        return { hasToSetError: true, errorOrNull: 'Please enter a valid phone number' };

    if ((requiredUsernameType === 'email' && isValidEmail) || (requiredUsernameType === 'phone' && isValidPhone))
        return { hasToSetError: true, errorOrNull: null };

    return { hasToSetError: false, errorOrNull: null };
};

export const isLotClosed = (spot: any, hourlyRental: boolean): boolean => {
    return hourlyRental && spot.active_event && spot.active_event.is_lot_closed;
};

export const shouldApplySurge = (spot: any, hourlyRental: boolean): boolean => {
    return hourlyRental && spot.is_surge_price_active && !isLotClosed(spot, hourlyRental);
};

interface State {
    value: string;
    label: string;
}

export const getStateFromValue = (plateState: string, plateCountry: CountryCode = 'US'): State | undefined => {
    let state = undefined;
    const countryName = mapCountryCodeToName[plateCountry];
    stateOptions.forEach((country, i) => {
        if (country.label === countryName) {
            stateOptions[i].options.forEach((stateOption, j) => {
                if (stateOption.value === plateState || stateOption.label === plateState)
                    state = stateOptions[i].options[j];
            });
        }
    });
    return state;
};

export const handleDynamicPricingLogInMapList = (spot: any, rentalType: string) => {
    /* noop */
};
