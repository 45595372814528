import React, { useState, useEffect } from 'react';
import useForm from '../../hooks/useForm';
import { Form, Divider, Button, Loader, Message } from 'semantic-ui-react';
import MultiplePlatesField from 'components/FormFields/MultiplePlateField';
import UsernameField from 'components/FormFields/UsernameField';
import PromoField from 'components/FormFields/PromoField';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Webpay.module.css';
import MonthlyRentalFields from 'components/FormFields/MonthlyRentalFields';
import DailyRentalFields from 'components/FormFields/DailyRentalFields';
import CreditCardField from 'components/FormFields/CreditCardFields/CreditCardField';
import MultiRentalPricing from '../MultiRentalPricing/MultiRentalPricing';
import ProjectedPricing from '../ProjectedPricing/ProjectedPricing';
import ReactGA from 'react-ga';
import { checkForUserNameError, getOnlyNumbers, isPhone } from 'utils/helpers';
import { Car } from 'utils/types';

ReactGA.initialize('UA-100873220-1', { testMode: process.env.NODE_ENV === 'test' });

interface InitialInputs {
    username: string;
    phoneCode: string;
    cars: Array<Car>;
    start: Date | null;
    end: Date | null;
    promo: string;
    phone: string;
}

const WebpayForm = (props: any) => {
    const phoneFromURL = props.phone?.replace(' ', '') || '';
    const phoneCodeFromURL = props.phoneCode?.replace(' ', '') || '';

    const phoneHadCountryCode = phoneFromURL?.startsWith(phoneCodeFromURL);

    const phoneCode = phoneCodeFromURL ? `+${phoneCodeFromURL}` : '+1';
    const phone = phoneFromURL ? (phoneHadCountryCode ? phoneFromURL.replace(phoneCodeFromURL, '') : phoneFromURL) : '';

    const initialInputs: InitialInputs = {
        username: phone,
        phoneCode: phoneCode,
        cars: [{ plate: '', state: '', country: 'US' }],
        start: null,
        end: null,
        promo: props.promo || '',
        phone: '',
    };
    const elements: any = useElements();
    const stripe: any = useStripe();
    const [cardType, setCardType] = useState('default');
    const [cardError, setCardError] = useState<null | string>(null);
    const [promoError, setPromoError] = useState<null | string>(null);
    const [usernameError, setUsernameError] = useState<null | string>(null);
    const [phoneError, setPhoneError] = useState<null | string>(null);
    const [isCardComplete, setIsCardComplete] = useState(null);
    const { handleInput, inputs, setInputs, handleValueChange } = useForm(initialInputs);
    const [showLoader, setShowLoader] = useState(false);
    const isLongtermRental = props.monthlyRental || props.dailyRental;
    const requiredUsernameType = isLongtermRental ? 'email' : props.source === 'kiosk' ? 'either' : 'phone';
    const allowNoPlate = !!props.airportCheckin;
    const ref = props.referrer;
    const violation = ref === 'violation' || ref === 'note';
    const disableButton = !allowNoPlate && (!inputs.cars[0].plate || !inputs.cars[0].state);
    const spot = props.spot;

    useEffect(() => {
        handleValueChange('cars', [{ ...props.defaultCar }]);
    }, [props.defaultCar.plate, props.defaultCar.state]); // eslint-disable-line

    useEffect(() => {
        setShowLoader(false);
    }, [props.error]);

    useEffect(() => {
        const { hasToSetError, errorOrNull } = checkForUserNameError(requiredUsernameType, inputs.username);
        if (hasToSetError) setUsernameError(errorOrNull);
    }, [inputs.username, requiredUsernameType]);

    useEffect(() => {
        const { hasToSetError, errorOrNull } = checkForUserNameError('phone', inputs.phone);
        if (hasToSetError) setPhoneError(errorOrNull);
    }, [inputs.phone]);

    useEffect(() => {
        if (requiredUsernameType === 'phone' && phoneFromURL) {
            setInputs((inputs: any) => ({
                ...inputs,
                username: phone,
                phoneCode,
                start: null,
            }));
        }
        if (requiredUsernameType === 'phone' && !phoneFromURL) {
            setInputs((inputs: any) => ({ ...inputs, username: '', phoneCode, start: null }));
        }
        if (requiredUsernameType === 'email') {
            setInputs((inputs: any) => ({ ...inputs, username: '', phoneCode }));
        }
        // eslint-disable-next-line
    }, [requiredUsernameType]);

    const onSubmit = () => {
        const shortTerm = !isLongtermRental;
        const rentalType = props.dailyRental ? 'daily' : props.monthlyRental ? 'monthly' : 'hourly';
        let cardElement = elements.getElement(CardElement);
        if (props.creditCard && cardType === 'default') {
            cardElement = null;
        }
        ReactGA.event({
            category: 'ParkingSpots',
            action: 'rented',
        });
        const isValidCard = (!cardError && isCardComplete) || !cardElement;
        if (isValidCard) {
            const inputValues = inputs;
            if (isPhone(inputValues.username)) inputValues.username = getOnlyNumbers(inputValues.username);

            const phoneAndCodeDidNotChange =
                phoneFromURL && `+${phoneFromURL}` === inputValues.phoneCode + inputValues.username;
            if (phoneHadCountryCode && phoneAndCodeDidNotChange)
                inputValues.username = inputValues.phoneCode + inputValues.username;

            props.bookRental(inputValues, cardElement, stripe, inputs.cars, props.source, ref, shortTerm, rentalType);
            setShowLoader(true);
            // @ts-ignore
            window.gtag_report_conversion();
        }
        if (!isCardComplete) {
            setCardError('Please enter payment information to continue');
        }
    };

    const onUpdateCardInfo = (event: any) => {
        setCardError(event.error?.message);
        setIsCardComplete(event.complete);
        setPromoError(event.error?.message);
    };

    return (
        <Form onSubmit={onSubmit}>
            {props.error && <Message info size="tiny" color="red" header="Error" content={props.error} />}

            {isLongtermRental && (
                <UsernameField
                    inputs={inputs}
                    handleInput={handleInput}
                    handleValueChange={handleValueChange}
                    error={phoneError}
                    requiredUsernameType={'phone'}
                    hasOptionalPhone={true}
                />
            )}
            <UsernameField
                inputs={inputs}
                handleInput={handleInput}
                handleValueChange={handleValueChange}
                error={usernameError}
                requiredUsernameType={requiredUsernameType}
            />
            {props.authLoading ? (
                <Loader className={styles.orangeLoader} active inline="centered" content="Loading your data..." />
            ) : (
                <>
                    <MultiplePlatesField
                        handleInput={handleValueChange}
                        inputs={inputs}
                        allowNoPlate={allowNoPlate}
                        violation={violation}
                    />

                    <MultiRentalPricing
                        cars={inputs.cars}
                        spot={spot}
                        monthly={props.monthlyRental}
                        source={props.source}
                        daily={props.dailyRental}
                    />

                    {props.monthlyRental && (
                        <MonthlyRentalFields
                            inputs={inputs}
                            handleValueChange={handleValueChange}
                            minimum_booking_length={spot.minimum_booking_length}
                            spotPK={spot.pk}
                        />
                    )}

                    {props.dailyRental && (
                        <DailyRentalFields
                            inputs={inputs}
                            handleValueChange={handleValueChange}
                            minimum_booking_length={spot.minimum_booking_length}
                        />
                    )}

                    <CreditCardField
                        creditCard={props.creditCard}
                        cardType={cardType}
                        setCardType={setCardType}
                        onChange={onUpdateCardInfo}
                        error={cardError}
                    />
                    <Divider hidden />
                    {props.dailyRental && inputs.start && inputs.end && (
                        <ProjectedPricing cars={inputs.cars} start={inputs.start} end={inputs.end} spot={spot} />
                    )}
                    {!violation && (
                        <PromoField
                            successMessage={props.successMessage}
                            handleInput={handleInput}
                            inputs={inputs}
                            error={promoError}
                        />
                    )}
                </>
            )}
            {!showLoader ? (
                <Button
                    disabled={disableButton}
                    type="submit"
                    fluid
                    content={violation ? 'Pay fine now' : 'Start rental'}
                />
            ) : (
                <Loader active inline="centered" />
            )}
        </Form>
    );
};

export default WebpayForm;
