import React from 'react';
import { Message } from 'semantic-ui-react';
import { RentType } from 'utils/constants';

const MultiRentalPricing = (props: any) => {
    const { rental, cars, spot, monthly, source, daily } = props;
    let plates = cars.length;
    let pricePerUnit;
    let priceCalculation;

    const calculateNewPrice = (price: any) => {
        if (rental) {
            plates = plates + 1;
        }
        return ((plates * price) / 100).toFixed(2);
    };

    if (monthly) {
        pricePerUnit = (
            <>
                {spot?.display_price_monthly || '$' + (rental?.price_monthly / rental.cars.length / 100).toFixed(2)} per
                month
            </>
        );
        priceCalculation = (
            <>{calculateNewPrice(spot?.price_monthly || rental?.price_monthly / rental.cars.length)} per month</>
        );
    } else if (spot?.active_rate?.unit === RentType.flatRate) {
        pricePerUnit = <>{spot?.display_price} daily flat rate</>;
        priceCalculation = <>{calculateNewPrice(spot?.dynamic_price)} per day</>;
    } else if (daily || source === 'kiosk') {
        pricePerUnit = <>{spot?.display_price_daily ? spot?.display_price_daily : spot?.display_daily_max} per day</>;
        priceCalculation = <>{calculateNewPrice(spot?.price_daily ? spot?.price_daily : spot?.daily_max)} per day</>;
    } else {
        pricePerUnit = <>{spot?.display_price || '$' + (rental?.price_hourly / 100).toFixed(2)} per hour</>;
        priceCalculation = <>{calculateNewPrice(spot?.price_hourly || rental?.price_hourly)} per hour</>;
    }

    return plates > 1 ? (
        <Message>
            {plates} plates x {pricePerUnit} = <br></br>${priceCalculation}{' '}
        </Message>
    ) : null;
};

export default MultiRentalPricing;
