import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { getDestinationListAction } from '../actions/actions';
import SiteMap from '../components/SiteMap';

const SiteMapContainer = ({ destinations, loading, getDestinationList }: any) => {
    useEffect(() => {
        (async () => await getDestinationList())();
    }, [getDestinationList]);
    return (
        <>
            {loading ? (
                <Loader inverted active size={'large'}>
                    Loading
                </Loader>
            ) : (
                <SiteMap destinations={destinations} />
            )}
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        destinations: state.destinations.destinationList,
        loading: state.destinations.loading,
    };
}

const mapDispatchToProps = {
    getDestinationList: getDestinationListAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteMapContainer));
