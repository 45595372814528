import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Message, Loader } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import { getBlackoutDatesListAction } from '../../actions/actions';
import StartDateField from 'components/FormFields/StartDateField';
import EndDateField from 'components/FormFields/EndDateField';
import styles from './FormFields.module.css';

const MonthlyRentalFields = (props: any) => {
    // set 30 day minimum on calendar
    const { spotPK, bookedDates, minEndDate, getBlackoutDatesList, minimum_booking_length, errorMessage } = props;
    const getBlackoutDatesListMemo = useCallback(
        (spotPK) => {
            getBlackoutDatesList(spotPK);
        },
        [getBlackoutDatesList]
    );

    useEffect(() => {
        if (spotPK) {
            getBlackoutDatesListMemo(spotPK);
        }
    }, [spotPK, getBlackoutDatesListMemo]);

    if ((bookedDates && bookedDates.length >= 60) || errorMessage) {
        return (
            <Message
                info
                color="pink"
                size="tiny"
                header="Monthly passes sold out"
                content="The parking lot is sold out of monthly passes. You can still pay by the hour below."
            />
        );
    } else if (Array.isArray(bookedDates) || props.invoice) {
        return (
            <>
                <StartDateField
                    selectedDate={props.inputs.start}
                    handleValueChange={props.handleValueChange}
                    invoice={props.invoice}
                    minimumBookingLength={props.minimum_booking_length}
                />
                <EndDateField
                    monthly={true}
                    invoice={props.invoice}
                    minDate={addDays(minEndDate, minimum_booking_length)}
                    inputs={props.inputs}
                    handleValueChange={props.handleValueChange}
                    minimumBookingLength={minimum_booking_length}
                />
            </>
        );
    }
    return <Loader className={styles.orangeLoader} inverted active />;
};

function mapStateToProps(state: any) {
    return {
        bookedDates: state.rentals.bookedDates,
        minEndDate: state.rentals.minEndDate,
        errorMessage: state.rentals.errorMessage,
    };
}

const mapDispatchToProps = {
    getBlackoutDatesList: getBlackoutDatesListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyRentalFields);
