import { VALIDATE_VISITOR_REQUESTED, VALIDATE_VISITOR_SUCCESS, VALIDATE_VISITOR_FAILED } from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialVisitortate = {
    visitor: null,
    loading: false,
    errorMessage: '',
};

export default function visitorReducer(state = initialVisitortate, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case VALIDATE_VISITOR_REQUESTED:
            newState.loading = true;
            newState.error = '';
            return newState;
        case VALIDATE_VISITOR_SUCCESS:
            newState.loading = false;
            newState.visitor = action.payload;
            return newState;
        case VALIDATE_VISITOR_FAILED:
            newState.errorMessage = action.message;
            newState.visitor = null;
            newState.loading = false;
            return newState;
        default:
            return state || initialVisitortate;
    }
}
