import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Checkbox, Dropdown } from 'semantic-ui-react';
import styles from '../HourlyRental/HourlyRental.module.css';
import { timeOptions } from '../../utils/constants';

const Reminder = (props: any) => {
    const { setReminder, rentalPk, reminderSuccessMessage } = props;
    const [showTimePicker, setShowTimePicker] = useState(false);

    const selectReminderTime = (event: any, data: any) => {
        const minutes = data.value;
        setReminder(minutes, rentalPk);
    };

    return (
        <>
            <Header as="h5" className={styles.fieldName} content="Text me a reminder" />

            <Checkbox toggle onClick={() => setShowTimePicker(!showTimePicker)} label="Remind me to end my rental" />

            {showTimePicker && (
                <>
                    {reminderSuccessMessage ? (
                        <>
                            <br></br>
                            <p>{reminderSuccessMessage}</p>
                        </>
                    ) : (
                        <>
                            <br></br>
                            <Dropdown
                                placeholder="Remind me in..."
                                selection
                                fluid
                                options={timeOptions}
                                onChange={selectReminderTime}
                            />
                        </>
                    )}
                </>
            )}
            <br></br>
        </>
    );
};

export default withRouter(Reminder);
