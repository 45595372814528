import React from 'react';
import { Card, Grid } from 'semantic-ui-react';
import SpotCard from './SpotCard';
import Amenities from './Amenities';
import Instructions from './Instructions';
import SearchHeader from './SearchHeader';

const SpotInfo = (props: any) => {
    const { spot, rentalType, setRentalType, spotRef } = props;

    return (
        <>
            <Card fluid>
                <Grid padded stackable>
                    <SearchHeader spot={spot} rentalType={rentalType} setRentalType={setRentalType} />

                    <SpotCard spot={spot} rentalType={rentalType} setRentalType={setRentalType} spotRef={spotRef} />

                    <Grid.Row stretched>
                        <Grid.Column width={5}>
                            <Instructions spot={spot} rentalType={rentalType} />
                        </Grid.Column>

                        <Grid.Column width={11}>
                            <Amenities spot={spot} rentalType={rentalType} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card>
        </>
    );
};

export default SpotInfo;
