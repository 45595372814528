import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CheckIn from '../components/CheckIn/CheckIn';
import GetSpot from 'components/CheckIn/GetSpot';
import { Loader } from 'semantic-ui-react';

import {
    getSpotAction,
    getRentalByConfirmationIdAction,
    updateRentalAction,
    checkInRentalAction,
    getAirportSpotsAction,
} from '../actions/actions';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { AIRPORT_LOT } from 'utils/constants';

const CheckInModuleContainer = (props: any) => {
    const params = queryString.parse(props.location.search);
    const spotId = params.spotId;

    const {
        spots,
        spot,
        getAirportSpots,
        getSpot,
        errorMessage,
        invoiceSuccessMessage,
        rentalSuccessMessage,
        rental,
        getRental,
        updateRental,
        checkInRental,
    } = props;

    useEffect(() => {
        getAirportSpots(AIRPORT_LOT);
    }, [getAirportSpots]);

    useEffect(() => {
        if (spotId) {
            getSpot(spotId);
        }
    }, [getSpot, spotId]);

    return (
        <>
            {!spotId ? (
                <GetSpot spots={spots} />
            ) : !spot ? (
                <Loader inverted active />
            ) : (
                <CheckIn
                    spot={spot}
                    rental={rental}
                    getRental={getRental}
                    updateRental={updateRental}
                    checkInRental={checkInRental}
                    rentalSuccessMessage={rentalSuccessMessage}
                    invoiceSuccessMessage={invoiceSuccessMessage}
                    errorMessage={errorMessage}
                />
            )}
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        spots: state.spots.spots,
        spot: state.spots.spot,
        rental: state.rentals.rental,
        errorMessage: state.rentals.errorMessage,
    };
}

const mapDispatchToProps = {
    getAirportSpots: getAirportSpotsAction,
    getSpot: getSpotAction,
    getRental: getRentalByConfirmationIdAction,
    updateRental: updateRentalAction,
    checkInRental: checkInRentalAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckInModuleContainer));
