import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Icon, Message, Loader } from 'semantic-ui-react';
import UsernameField from 'components/FormFields/UsernameField';
import PlateAndStateField from 'components/FormFields/PlateAndStateField/PlateAndStateField';

const UpdateRentalForm = (props: any) => {
    const {
        inputs,
        handleInput,
        usernameError,
        buttonDisabled,
        errorMessage,
        addPlateAndEmail,
        showLoader,
        handleValueChange,
        rental,
    } = props;

    useEffect(() => {
        if (rental) {
            const car = rental && rental.cars_detail[0] ? rental.cars_detail[0] : null;

            if (rental && rental.renter_email && rental.renter_email !== 'anonymous@user.com')
                handleValueChange('username', rental.renter_email);
            if (car) {
                const newCar = {
                    plate: car.plate || inputs.cars[0].plate,
                    state: car.state || inputs.cars[0].state,
                };
                handleValueChange('cars', [newCar]);
            }
        }
        // eslint-disable-next-line
    }, [rental]);

    return (
        <Form onSubmit={() => addPlateAndEmail()}>
            {errorMessage && <Message color="red" content={errorMessage} />}

            <p>
                <Icon name="check" /> Reservation found! <br /> Please enter your email and license plate.
            </p>
            <Form.Field
                disabled={
                    rental &&
                    rental.renter_email &&
                    rental.renter_email !== 'anonymous@user.com' &&
                    rental.renter_email !== 'None'
                }
            >
                <UsernameField
                    inputs={inputs}
                    handleInput={handleInput}
                    error={usernameError}
                    requiredUsernameType={'email'}
                />
            </Form.Field>

            <PlateAndStateField inputs={inputs} handleValueChange={handleValueChange} index={0} />

            {!showLoader ? (
                <Form.Button content={'Continue'} fluid disabled={buttonDisabled} name="update-continue" />
            ) : (
                <Loader active inline="centered" />
            )}
        </Form>
    );
};

export default withRouter(UpdateRentalForm);
