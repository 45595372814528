import React from 'react';
import Rating from '@material-ui/lab/Rating';
import styles from './DestinationMobileItem.module.css';
import { getRating, handleDynamicPricingLogInMapList } from '../../utils/helpers';
import { Label } from 'semantic-ui-react';

interface Props {
    pk: number;
    spotName: string;
    spotAddress: string;
    imgUrl: string;
    price: number;
    priceRate: string;
    rentalType: string;
    spot: any;
}

const DestinationMobileItem: React.FC<Props> = (props: Props) => {
    const { pk, spotName, spotAddress, imgUrl, price, priceRate, rentalType, spot } = props;
    const spotLink = `/spot/${pk}/?ref=destination-pages&rentalType=${rentalType}`;

    return (
        <a href={spotLink} onClick={() => handleDynamicPricingLogInMapList(spot, rentalType)}>
            <div className={styles.container}>
                <img className={styles.spotImage} src={imgUrl} alt={`${spotName} - ${spotAddress}`} />

                <div className={styles.cardContent}>
                    <h2 className={`${styles.spotName} ${styles.hideOverflow}`}>{spotName}</h2>
                    {pk % 2 === 0 ? (
                        <Label className={styles.label} size={'tiny'} content="POPULAR" color="pink" />
                    ) : (
                        <Label className={styles.label} size={'tiny'} content="GOOD PRICE" color="blue" />
                    )}
                    <div className={styles.spotFooter}>
                        <Rating readOnly defaultValue={Number(getRating([0, 9]))} precision={0.5} size="small" />
                        <div className={styles.spotPrice}>
                            {price}
                            <span className={styles.spotPriceInterval}>{priceRate}</span>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default DestinationMobileItem;
