import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, Grid, Card, Divider, Image } from 'semantic-ui-react';
import useForm from '../../hooks/useForm';
import CheckInHeader from './CheckInHeader';
import Footer from '../Footer/Footer';
import AggregatorLogos from './AggregatorLogos';
import { Link } from 'react-router-dom';

interface InitialInputs {
    spotId: string;
}

const GetSpot = (props: any) => {
    const { spots } = props;
    const initialInputs: InitialInputs = {
        spotId: '',
    };
    const { handleDropdown, inputs } = useForm(initialInputs);
    const history = useHistory();
    const spotsOptions = spots.map((item: any) => ({ value: item.uuid, text: item.name }));

    return (
        <>
            <Grid columns={1} padded>
                <Grid.Column>
                    <Image src={require('../../assets/airwhite.png')} rounded alt="AirGarage" centered />
                    <Card centered>
                        <CheckInHeader />
                        <Form.Field>
                            <Form.Dropdown
                                name="spotId"
                                onChange={handleDropdown}
                                placeholder="Select an Airport Parking Lot"
                                options={spotsOptions}
                                value={inputs.spotId}
                                selection
                                search
                                required
                                fluid
                            />
                        </Form.Field>
                        <Divider hidden={true} />
                        <Form.Button
                            name="start"
                            content={'Start'}
                            fluid
                            icon="external"
                            onClick={() => history.push(`?spotId=${inputs.spotId}`)}
                        />
                        <Divider />
                        <Link to={`/contact?source=check-in`}>Need help? Contact support.</Link>
                    </Card>
                </Grid.Column>
            </Grid>
            <div style={{ backgroundColor: '#D36230' }}>
                <AggregatorLogos />
                <Footer />
            </div>
        </>
    );
};

export default withRouter(GetSpot);
