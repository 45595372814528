import React from 'react';
import { Message } from 'semantic-ui-react';
import { RentType } from 'utils/constants';

const InfoHeader = (props: any) => {
    const { rental, violation, creditCard } = props;
    const daily = rental.rental_type === 'daily';
    const monthly = rental.rental_type === 'monthly';
    const validAllDay = rental.source === 'kiosk' || rental.rental_type === RentType.flatRate;
    const activeNow = rental.state === 'ACTIVE';
    const cancelled = !!rental.cancelled_date;
    const cancelledBeforeStart = cancelled && rental.cancelled_date < rental.start_date;
    const overdue = rental.overdue;
    const noPlate = rental.cars.length === 0;

    if (overdue && !creditCard) {
        const message = 'Please update your payment method below or your vehicle may be immobilized.';
        return <Message color="red" header="Card payment failed." content={message} />;
    } else if (daily) {
        if (noPlate) {
            const message = `Enter your license plate below when you have it ready.`;
            return <Message warning header="Your parking has been reserved." content={message} />;
        }
        const message = `Your parking reservation is confirmed.`;
        return <Message success header="Good to go!" content={message} />;
    } else if (monthly && !cancelled) {
        const message = `Your monthly rental is ${rental.state.toLowerCase()}.`;
        return <Message success header="Good to go!" content={message} />;
    } else if (monthly && cancelledBeforeStart) {
        const message = `Your rental was cancelled before its start date.`;
        return <Message warning header="Rental Cancelled" content={message} />;
    } else if (monthly && cancelled) {
        const message = `Your rental has been cancelled and you will not be billed again. You can continue to use the parking spot until your end date.`;
        return <Message warning header="Rental Cancelled" content={message} />;
    } else if (!monthly && !activeNow) {
        const message = `Your rental is ${rental.state.toLowerCase()}`;
        return <Message info content={message} />;
    } else if (validAllDay && !violation) {
        const message = 'Your parking is valid until end of day.';
        return <Message success header="Good to go!" content={message} />;
    } else if (violation) {
        const message = 'Your fine has been paid.';
        return <Message success header="Thank you!" content={message} />;
    } else {
        const message =
            "You can close this window. We will text you a link you can use to end your rental when you're ready to leave.";
        return <Message success header="Good to go!" content={message} />;
    }
};

export default InfoHeader;
