import {
    SPOTS_REQUESTED,
    SPOTS_SUCCESS,
    SPOTS_FAILED,
    SPOT_REQUESTED,
    SPOT_FAILED,
    SPOT_SUCCESS,
    CLEAR_SPOT_REQUESTED,
    CLEAR_SPOT_SUCCESS,
    AIRPORT_SPOTS_REQUESTED,
    AIRPORT_SPOTS_SUCCESS,
    AIRPORT_SPOTS_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};
const initialSpotState = {
    spots: [],
    spot: null,
    loading: false,
    errorMessage: '',
    selectedSpot: null,
};

export default function spotsReducer(state = initialSpotState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case SPOTS_REQUESTED:
            newState.loading = true;
            return newState;
        case SPOTS_SUCCESS:
            newState.loading = false;
            newState.spots = action.payload.spots;
            newState.selectedSpot = action.payload.selectedSpot;
            return newState;
        case SPOTS_FAILED:
            newState.errorMessage = action.message;
            newState.spots = [];
            newState.loading = false;
            return newState;
        case SPOT_REQUESTED:
            newState.loading = true;
            return newState;
        case SPOT_SUCCESS:
            newState.loading = false;
            newState.spot = action.payload.spot;
            return newState;
        case CLEAR_SPOT_REQUESTED:
            newState.loading = true;
            return newState;
        case CLEAR_SPOT_SUCCESS:
            newState.loading = false;
            newState.spot = action.payload.spot;
            return newState;
        case SPOT_FAILED:
            newState.errorMessage = action.message;
            newState.spot = null;
            newState.loading = false;
            return newState;
        case AIRPORT_SPOTS_REQUESTED:
            newState.loading = true;
            return newState;
        case AIRPORT_SPOTS_SUCCESS:
            newState.loading = false;
            newState.spots = action.payload.spots;
            return newState;
        case AIRPORT_SPOTS_FAILED:
            newState.errorMessage = action.message;
            newState.spots = [];
            newState.loading = false;
            return newState;
        default:
            return state || initialSpotState;
    }
}
