import APIClient from './APIClient';

class ContactFormService extends Object {
    async submitContactForm(data: any, token: string) {
        const result = await APIClient.post(`contact`, data, token);
        return result;
    }
}

export default new ContactFormService();
