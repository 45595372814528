import { CountryCode } from 'assets/data/countriesAndStates';
import { Car } from 'utils/types';
import {
    RENTALS_REQUESTED,
    RENTAL_REQUESTED,
    RENTAL_BY_CONFIRMATION_ID_REQUESTED,
    SPOTS_REQUESTED,
    AIRPORT_SPOTS_REQUESTED,
    PROFILE_REQUESTED,
    CHECK_IN_RENTAL_REQUESTED,
    UPDATE_RENTAL_REQUESTED,
    LOGOUT,
    SPOT_REQUESTED,
    CLEAR_SPOT_REQUESTED,
    BOOK_RENTAL_REQUESTED,
    CREATE_CUSTOM_INVOICE_REQUESTED,
    USER_DETAILS_REQUESTED,
    END_RENTAL_REQUESTED,
    KIOSK_RESTARTED,
    CARD_UPDATE_REQUESTED,
    ADD_CAR_TO_RENTAL_REQUESTED,
    REMOVE_CAR_FROM_RENTAL_REQUESTED,
    SEND_FEEDBACK_REQUESTED,
    SET_REMINDER_REQUESTED,
    SUBMIT_CONTACT_FORM_REQUESTED,
    SEARCH_COORDS_REQUESTED,
    NAVIGATE_TO_RESULTS_REQUESTED,
    APPLY_PROMO_CODE_REQUESTED,
    DESTINATION_LIST_REQUESTED,
    DESTINATION_REQUESTED,
    BLACKOUT_DATES_LIST_REQUESTED,
    VALIDATE_VISITOR_REQUESTED,
    AUTHENTICATE_REQUESTED,
    AUTO_PROMO_REQUESTED,
    ENTERPRISE_DETAILS_REQUESTED,
} from './constants';

export function getBlackoutDatesListAction(id: number) {
    return { type: BLACKOUT_DATES_LIST_REQUESTED, payload: { id } };
}

export function getRentalsAction(id: number, confirmationId?: string) {
    return { type: RENTALS_REQUESTED, payload: { id, confirmationId } };
}

export function getRentalAction(id: number) {
    return { type: RENTAL_REQUESTED, payload: { id } };
}

export function getRentalByConfirmationIdAction(confirmationId: string) {
    return { type: RENTAL_BY_CONFIRMATION_ID_REQUESTED, payload: { confirmationId } };
}

export function getSpotsAction(coords: any, rentalType: string) {
    return { type: SPOTS_REQUESTED, payload: { coords, rentalType } };
}

export function getAirportSpotsAction(classification: string) {
    return { type: AIRPORT_SPOTS_REQUESTED, payload: { classification } };
}

export function getSpotAction(id: number) {
    return { type: SPOT_REQUESTED, payload: { id } };
}

export function clearSpotAction() {
    return { type: CLEAR_SPOT_REQUESTED };
}

export function getProfileAction() {
    return { type: PROFILE_REQUESTED };
}

export function addCarToRentalAction(car: Car, rentalPk: number, editingCar: boolean) {
    return { type: ADD_CAR_TO_RENTAL_REQUESTED, payload: { car, rentalPk, editingCar } };
}

export function removeCarFromRentalAction(carPk: number, rentalPk: number) {
    return { type: REMOVE_CAR_FROM_RENTAL_REQUESTED, payload: { carPk, rentalPk } };
}

export function updateRentalAction(pk: number, body: any) {
    return { type: UPDATE_RENTAL_REQUESTED, payload: { body, pk } };
}

export function endRentalAction(pk: number) {
    return { type: END_RENTAL_REQUESTED, payload: { pk } };
}

export function bookRentalAction(
    inputs: any,
    cardElement: any,
    stripe: any,
    cars: Car[],
    source: string,
    ref: string,
    shortTerm: boolean,
    rentalType: string
) {
    return {
        type: BOOK_RENTAL_REQUESTED,
        payload: { inputs, cardElement, stripe, cars, source, ref, shortTerm, rentalType },
    };
}

export function createCustomInvoiceAction(inputs: any) {
    return { type: CREATE_CUSTOM_INVOICE_REQUESTED, payload: { inputs } };
}

export function getUserDetailsAction(phone: string, pin: string) {
    return { type: USER_DETAILS_REQUESTED, payload: { phone, pin } };
}

export function restartKioskAction(pk: number) {
    return { type: KIOSK_RESTARTED, payload: { pk } };
}

export function getEnterpriseDetailsAction() {
    return { type: ENTERPRISE_DETAILS_REQUESTED };
}
export function updateCardAction(cardElement: any, stripe: any) {
    return { type: CARD_UPDATE_REQUESTED, payload: { cardElement, stripe } };
}

export function applyPromoCodeAction(promoCode: string, rentalPk: number) {
    return { type: APPLY_PROMO_CODE_REQUESTED, payload: { promoCode, rentalPk } };
}

export function sendFeedbackAction(renterPhone: any, feedback: string, spot: string, rating: any) {
    return { type: SEND_FEEDBACK_REQUESTED, payload: { renterPhone, feedback, spot, rating } };
}

export function setReminderAction(minutes: any, pk: number) {
    return { type: SET_REMINDER_REQUESTED, payload: { minutes, pk } };
}

export function submitContactFormAction(body: any) {
    return { type: SUBMIT_CONTACT_FORM_REQUESTED, payload: body };
}

export function getSearchResultCoordsAction(description: any) {
    return { type: SEARCH_COORDS_REQUESTED, payload: { description } };
}

export function navigateToResultsAction(coords: any, rentalType: string) {
    return { type: NAVIGATE_TO_RESULTS_REQUESTED, payload: { coords, rentalType } };
}

export function getDestinationListAction() {
    return { type: DESTINATION_LIST_REQUESTED };
}

export function getDestinationAction(slug: string) {
    return { type: DESTINATION_REQUESTED, payload: { slug } };
}

export function checkInRentalAction(inputs: any, rental: any) {
    return { type: CHECK_IN_RENTAL_REQUESTED, payload: { inputs, rental } };
}

export function validateVisitorAction(
    code: number,
    phone: string,
    plate: string,
    spotPk: number,
    state: string,
    country: CountryCode,
    validation_source: string
) {
    return {
        type: VALIDATE_VISITOR_REQUESTED,
        payload: { code, phone, plate, spotPk, state, country, validation_source },
    };
}

export function logoutAction() {
    return { type: LOGOUT };
}

export function authenticateAction(username: string, pin: string, slot_uuid: string) {
    return { type: AUTHENTICATE_REQUESTED, payload: { username, pin, slot_uuid } };
}

export function autoPromoAction(spotId: string, promo: string) {
    return { type: AUTO_PROMO_REQUESTED, payload: { spotId, promo } };
}
