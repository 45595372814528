import React, { useState, useEffect } from 'react';
import { Header, Icon, Button } from 'semantic-ui-react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import fieldStyles from '../FormFields.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import { stripeOptions } from './stripeOptions';

const EditCreditCardField = (props: any) => {
    const elements: any = useElements();
    const stripe: any = useStripe();
    const [card, showCard] = useState(false);
    const [completedCard, completeCard] = useState(false);
    const updatedCard = (event: any) => {
        completeCard(event.complete);
    };

    useEffect(() => {
        showCard(props.overdue);
    }, [props.overdue]);

    return (
        <>
            <a href="# " onClick={() => showCard(!card)}>
                <Icon name="credit card" />
                Edit your payment method
            </a>
            {card && (
                <>
                    <Header as="h5" className={fieldStyles.fieldName}>
                        Secure Payment
                    </Header>
                    <div className={fieldStyles.fieldInput} style={{ marginBottom: '10px' }}>
                        <CardElement onChange={updatedCard} options={stripeOptions} />
                    </div>
                    <Button
                        size="tiny"
                        onClick={() => completedCard && props.updateCard(elements.getElement(CardElement), stripe)}
                    >
                        Update Card
                    </Button>
                </>
            )}
            <br></br>
        </>
    );
};

export default EditCreditCardField;
