import React, { useState, useEffect } from 'react';
import useForm from '../../hooks/useForm';
import { Form, Divider, Button } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';

import UsernameField from 'components/FormFields/UsernameField';
import ValidationCodeField from 'components/FormFields/ValidationCodeField/ValidationCodeField';
import PlateAndStateField from 'components/FormFields/PlateAndStateField/PlateAndStateField';
import { getOnlyNumbers, isPhone } from 'utils/helpers';
import VisitorService from 'services/VisitorService';
import { VISITOR_FORM_VALIDATION_SOURCE } from 'utils/constants';
import { CountryCode } from 'assets/data/countriesAndStates';
import ENV from 'env';

interface InitialInputs {
    username: string;
    plate: string;
    code: string;
    state: string;
    country: CountryCode;
}

interface VisitorFormProps {
    phone: string;
    plate: string;
    spot: any;
    validateVisitor: (
        code: string,
        phone: string,
        plate: string,
        spotPk: number,
        state: string,
        country: CountryCode,
        validation_source: string
    ) => {};
}

const VisitorForm = ({ phone, plate, spot, validateVisitor }: VisitorFormProps) => {
    const initialInputs: InitialInputs = {
        username: phone || '',
        plate: plate || '',
        code: '',
        state: '',
        country: 'US',
    };

    const { handleInput, inputs, handleValueChange } = useForm(initialInputs);
    const [phoneError, setPhoneError] = useState<null | string>(null);
    const [codeError, setCodeError] = useState<null | string>(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
        const isValidPhone = isPhone(inputs.username);
        if (!isValidPhone) setPhoneError('Please enter a valid phone number');
        if (isValidPhone) setPhoneError(null);
        if (inputs.username && isValidPhone && inputs.code && inputs.state && inputs.plate) setIsSubmitDisabled(false);
        else setIsSubmitDisabled(true);
    }, [inputs]);

    useEffect(() => {
        if (codeError) setCodeError(null);
    }, [inputs.code]); // eslint-disable-line

    const onSubmit = async () => {
        let validatedCode;
        try {
            validatedCode = await VisitorService.validateCode(encodeURIComponent(inputs.code), spot.pk);
            if (!validatedCode.pk) throw new Error();
        } catch (error) {
            return setCodeError('Please enter a valid code');
        }
        const phone = getOnlyNumbers(inputs.username);
        validateVisitor(
            validatedCode.pk,
            phone,
            inputs.plate,
            spot.pk,
            inputs.state,
            inputs.country,
            VISITOR_FORM_VALIDATION_SOURCE
        );
    };

    return (
        <Form onSubmit={onSubmit}>
            <UsernameField
                inputs={inputs}
                handleInput={handleInput}
                error={phoneError}
                requiredUsernameType={'phone'}
                hasCountryCode={false}
            />
            <Divider hidden />

            <PlateAndStateField handleInput={handleInput} handleValueChange={handleValueChange} inputs={inputs} />
            <Divider hidden />

            <ValidationCodeField handleInput={handleInput} inputs={inputs} error={codeError} spot={spot.name} />
            <Divider hidden />

            <Button type="submit" fluid content={'Register'} disabled={isSubmitDisabled} />

            <Divider />

            <a href={`${ENV.QUICKPAY_DOMAIN}spots/${spot.pk}`}>Not a visitor? Pay here instead</a>
        </Form>
    );
};

export default VisitorForm;
