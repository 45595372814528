import {
    SUBMIT_CONTACT_FORM_REQUESTED,
    SUBMIT_CONTACT_FORM_SUCCESS,
    SUBMIT_CONTACT_FORM_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialState = {
    errorMessage: '',
    successMessage: '',
    loading: false,
};

export default function contactReducer(state = initialState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case SUBMIT_CONTACT_FORM_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case SUBMIT_CONTACT_FORM_SUCCESS:
            newState.loading = false;
            newState.successMessage =
                'Your contact form has been submitted! We will get back to you as soon as we can.';
            return newState;
        case SUBMIT_CONTACT_FORM_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        default:
            return state || initialState;
    }
}
