import React from 'react';
import { Modal, Header, Button, Form } from 'semantic-ui-react';
import useForm from '../../hooks/useForm';
import PlateAndStateField from 'components/FormFields/PlateAndStateField/PlateAndStateField';
import styles from './CarModal.module.css';
import { Car } from 'utils/types';
import { CountryCode } from 'assets/data/countriesAndStates';

interface Props {
    plate?: string;
    state?: string;
    country?: CountryCode;
    header: string;
    buttonText: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    submitCar: (car: Car) => void;
    message?: React.ReactElement;
}

const CarModal = (props: Props) => {
    const { open, setOpen, plate, state, country, submitCar, header, buttonText, message } = props;
    const initialInputs: Car = {
        plate: plate || '',
        state: state || '',
        country: country || 'US',
    };
    const { handleInput, inputs, handleValueChange, setInputs } = useForm(initialInputs);
    const disableButton = inputs.state === '' || inputs.plate === '';

    const onSubmit = () => {
        const car: Car = {
            ...inputs,
            plate: inputs.plate.toUpperCase(),
        };
        submitCar(car);
        setInputs(initialInputs);
    };

    return (
        <>
            <Modal
                closeIcon
                open={open}
                onClose={() => {
                    setOpen(false);
                    setInputs(initialInputs);
                }}
                size="tiny"
                id={styles.modal}
            >
                <Header content={header} id={styles.header} />
                <Modal.Content id={styles.content}>
                    {message && message}
                    <Form>
                        <PlateAndStateField
                            inputs={inputs}
                            handleInput={handleInput}
                            handleValueChange={handleValueChange}
                        />
                        <Button disabled={disableButton} onClick={onSubmit} style={{ width: '100%' }}>
                            {buttonText}
                        </Button>
                    </Form>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default CarModal;
