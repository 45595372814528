import React from 'react';
import { Header, Card, Image, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { RentType } from 'utils/constants';

const SpotHeader = (props: any) => {
    const { spot, kiosk, monthly, daily, invoice, referrer, source } = props;
    const violation = referrer === 'violation';
    const free_until_hourly_event =
        spot?.active_event && spot?.active_event?.unit === RentType.freeUntilHourly && !violation;
    let priceInfo;

    const displayPriceMin = (
        <>
            {spot.display_daily_min} daily minimum <br />{' '}
        </>
    );

    if (monthly) {
        priceInfo = <>{spot.display_price_monthly} per month</>;
    } else if (spot?.active_rate?.unit === RentType.flatRate && !violation) {
        priceInfo = <>{spot.display_price} daily flat rate</>;
    } else if (violation) {
        priceInfo = <>{spot.display_price}</>;
    } else if (daily) {
        priceInfo = (
            <>
                {spot.display_price_daily} per {spot.charge_by_night ? 'night' : 'day'}
            </>
        );
    } else if (props.source === 'kiosk') {
        priceInfo = <>{spot.display_daily_max} per day</>;
    } else if (free_until_hourly_event) {
        priceInfo = (
            <>
                Free parking until {spot.active_event.price_transition_time} <br /> {spot.active_event.price_formatted}{' '}
                {spot.active_event.is_effective_flat_rate ? `flat rate` : `per hour`} after{' '}
                {spot.active_event.price_transition_time}
            </>
        );
    } else {
        priceInfo = (
            <>
                {spot.display_price} per hour <br />
                {spot.daily_min && spot.daily_min !== spot.price_hourly && displayPriceMin} {spot.display_daily_max}{' '}
                daily max
            </>
        );
    }

    let header = 'Park with AirGarage';
    if (invoice && !source) {
        header = 'Create Custom Invoice';
    } else if (referrer === 'violation') {
        header = '';
    }

    return kiosk ? (
        <>
            <Header as="h1" content={'Pay Here for Public Parking'} textAlign="center" />
            <Header as="h2" content={spot.name} textAlign="center" />
            <Header as="h3" content={priceInfo} textAlign="center" />
        </>
    ) : (
        <>
            <Card.Header>
                <Image
                    src={spot.photo_url ? spot.photo_url : require('assets/share.png')}
                    rounded
                    alt="AirGarage"
                    size="medium"
                    centered
                />
                <Header as="h3" color="orange" textAlign="center">
                    {header}
                </Header>
            </Card.Header>

            <Card.Meta>
                <Header as="h5" textAlign="center" content={spot?.name} />
                {!invoice && (
                    <Message>
                        <Header
                            as="h6"
                            textAlign="center"
                            content={violation ? 'Fine Amount' : 'Current Rate'}
                            style={{
                                letterSpacing: 2,
                                textTransform: 'uppercase',
                                color: 'rgb(101,101,101)',
                                marginBottom: 5,
                            }}
                        />
                        <Header as={free_until_hourly_event ? 'h4' : 'h3'} textAlign="center" content={priceInfo} />
                    </Message>
                )}
            </Card.Meta>
            <br></br>
        </>
    );
};

export default withRouter(SpotHeader);
