import ENV from 'env';
import LogRocket from 'logrocket';

class LogRocketServer {
    init() {
        if (ENV.LOGROCKET_APP_ID) LogRocket.init(ENV.LOGROCKET_APP_ID);
    }
    identify(identifier: string) {
        if (ENV.LOGROCKET_APP_ID) LogRocket.identify(identifier);
    }
}

export default new LogRocketServer();
