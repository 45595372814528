import React from 'react';
import { Card, Grid, Divider, Button } from 'semantic-ui-react';
import RentalCard from '../RentalCard/RentalCard';
import Footer from '../Footer/Footer';
import { AG_HELP_CENTER_LINK } from '../../utils/constants';

const Rentals = (props: any) => {
    const hasRentals = props.rentals?.length > 0;
    const lastIndex = hasRentals ? props.rentals.length - 1 : 0;
    const kiosk = hasRentals && props.rentals[lastIndex].source === 'kiosk';

    return (
        <Grid centered padded>
            <Divider style={{ height: 20 }} hidden />

            {props.rentals?.length === 0 && (
                <Card>
                    <h4>Book your first parking rental with AirGarage.</h4>
                    <Button onClick={() => (window.location.href = '/spots/')}>Find a spot</Button>
                </Card>
            )}

            {props.rentals?.map((rental: any) => (
                <RentalCard
                    rental={rental}
                    key={rental.pk}
                    addCar={props.addCar}
                    removeCar={props.removeCar}
                    errorMessage={props.errorMessage}
                    successMessage={props.successMessage}
                    updateRental={props.updateRental}
                    endRental={props.endRental}
                    restartKiosk={props.restartKiosk}
                    updateCard={props.updateCard}
                    updating={props.updating}
                    sendFeedback={props.sendFeedback}
                    setReminder={props.setReminder}
                    reminderSuccessMessage={props.reminderSuccessMessage}
                    applyPromoCode={props.applyPromoCode}
                    creditCard={props.creditCard}
                    promoSuccessMessage={props.promoSuccessMessage}
                    promoErrorMessage={props.promoErrorMessage}
                />
            ))}

            {!kiosk && (
                <>
                    <Grid.Row>
                        <a style={{ color: '#fff' }} href={AG_HELP_CENTER_LINK}>
                            ⓘ Get help
                        </a>
                    </Grid.Row>

                    <Grid.Row>
                        <Button
                            size="tiny"
                            color="orange"
                            onClick={() => (window.location.href = '/')}
                            content="Rental History"
                        />
                        <Button
                            size="tiny"
                            color="orange"
                            onClick={() => (window.location.href = '/spots/')}
                            content="View Spots"
                        />
                        {!!props.authToken && (
                            <Button size="tiny" color="orange" onClick={() => props.logout()} content="Logout" />
                        )}
                    </Grid.Row>

                    <Footer />
                </>
            )}
        </Grid>
    );
};

export default Rentals;
