import APIClient from './APIClient';

class SpotsService extends Object {
    async getSpots(coords: any, rentalType: string) {
        const result = await APIClient.get(
            `spots/?lite=true&lat=${coords[0]}&lon=${coords[1]}&rentalDuration=${rentalType}`,
            ''
        );
        return result.filter((spot: any) => spot.hidden === false);
    }
    async getSpot(spotId: number | string) {
        return await APIClient.get(`spots/${spotId}/`, '');
    }
    async getSpotAvailability(spotId: number) {
        return await APIClient.get(`spots/${spotId}/availability/`, '');
    }
    async getAirportSpots(classification: string) {
        const result = await APIClient.get(`spots/?lite=true&classification=${classification}`);
        return result.filter((spot: any) => spot.hidden === false);
    }
}

export default new SpotsService();
