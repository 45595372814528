import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Grid, Image, Divider } from 'semantic-ui-react';
import CheckInHeader from './CheckInHeader';
import CheckInForm from './CheckInForm';
import UpdateRentalForm from './UpdateRentalForm';
import Footer from '../Footer/Footer';
import AggregatorLogos from './AggregatorLogos';
import useForm from '../../hooks/useForm';
import { Link } from 'react-router-dom';
import { validateEmail } from 'utils/helpers';
import { Car } from 'utils/types';

interface InitialInputs {
    username: string;
    cars: Array<Car>;
    confirmationCode: string;
}

const CheckIn = (props: any) => {
    const { spot, errorMessage, getRental, rental, checkInRental } = props;

    const initialInputs: InitialInputs = {
        username: '',
        cars: [{ plate: '', state: '', country: 'US' }],
        confirmationCode: '',
    };
    const { handleInput, inputs, handleValueChange } = useForm(initialInputs);
    const [showLoader, setShowLoader] = useState(false);
    const [usernameError, setUsernameError] = useState<null | string>(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const requiredUsernameType = 'email';

    useEffect(() => {
        const validEmail = validateEmail(inputs.username);
        const isEmail = validEmail || inputs.username === '';
        if (requiredUsernameType === 'email' && !isEmail) {
            setUsernameError('Please enter a valid email');
        }
        if (requiredUsernameType === 'email' && isEmail) {
            setUsernameError(null);
        }
    }, [inputs.username, requiredUsernameType]);

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (!rental && submitted) {
            getRental(inputs.confirmationCode);
        }
    }, [getRental, rental, inputs, submitted]);

    const onSubmit = (event: any) => {
        setButtonDisabled(true);
        setSubmitted(true);
        setShowLoader(true);
        setButtonDisabled(false);
    };

    useEffect(() => {
        setShowLoader(false);
    }, [errorMessage, rental]);

    const addPlateAndEmail = (event: any) => {
        setButtonDisabled(true);
        if (inputs.username && inputs.cars) {
            checkInRental(inputs, rental);
        }
    };

    return (
        <>
            <Grid columns={1} padded>
                <Grid.Column>
                    <Image src={require('../../assets/airwhite.png')} rounded alt="AirGarage" centered />
                    <Card centered>
                        <CheckInHeader spot={spot} />
                        {!rental ? (
                            <CheckInForm
                                spot={spot}
                                inputs={inputs}
                                handleInput={handleInput}
                                onSubmit={onSubmit}
                                buttonDisabled={buttonDisabled}
                                errorMessage={errorMessage}
                                showLoader={showLoader}
                            />
                        ) : (
                            <UpdateRentalForm
                                spot={spot}
                                inputs={inputs}
                                handleInput={handleInput}
                                handleValueChange={handleValueChange}
                                addPlateAndEmail={addPlateAndEmail}
                                usernameError={usernameError}
                                rental={rental}
                                buttonDisabled={buttonDisabled}
                                errorMessage={errorMessage}
                                showLoader={showLoader}
                            />
                        )}
                        <Divider />
                        {!rental ? (
                            <Link to={`/spot/${spot.uuid}?airportCheckin=true&daily=true`}>
                                Don't have a confirmation code? Pay for a spot here.
                            </Link>
                        ) : (
                            <Link to={`/contact?source=check-in`}>Need help? Contact support.</Link>
                        )}
                    </Card>
                </Grid.Column>
            </Grid>
            <div style={{ backgroundColor: '#D36230' }}>
                <AggregatorLogos />
                <Footer />
            </div>
        </>
    );
};

export default withRouter(CheckIn);
