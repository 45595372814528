import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Grid, Button, Loader } from 'semantic-ui-react';
import SpotHeader from '../SpotHeader/SpotHeader';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from '../Footer/Footer';
import useForm from '../../hooks/useForm';
import { Form, Message } from 'semantic-ui-react';
import AggregatorIntegrationForm from './AggregatorIntegrationForm';
import InvoiceForm from './InvoiceForm';
import { checkForUserNameError } from 'utils/helpers';
import { Car } from 'utils/types';

interface InitialInputs {
    username: string;
    description: string;
    cars: Array<Car>;
    price: number;
    type: string;
    commission: number | null;
    start: Date | null;
    end: Date | null;
    is_enterprise: boolean | null;
    enterprise_detail: string | null;
    payment_methods: Array<string> | null;
    rental_type: string | null;
}

const Invoice = (props: any) => {
    const {
        spot,
        createCustomInvoice,
        invoiceSuccessMessage,
        rentalSuccessMessage,
        error,
        source,
        enterpriseDetails,
        enterpriseDetailsErrorMessage,
    } = props;
    const initialInputs: InitialInputs = {
        username: '',
        description: '',
        cars: [{ plate: '', state: '', country: 'US' }],
        price: 0,
        type: '',
        commission: spot.commission_monthly,
        start: null,
        end: null,
        is_enterprise: false,
        enterprise_detail: null,
        payment_methods: null,
        rental_type: null,
    };
    const { handleInput, inputs, setInputs, handleValueChange, handleDropdown } = useForm(initialInputs);
    const [showLoader, setShowLoader] = useState(false);
    const [usernameError, setUsernameError] = useState<null | string>(null);
    const [priceError, setPriceError] = useState<null | string>(null);
    const [requiredUsernameType, setRequiredUsernameType] = useState('email');
    const buttonDisabled = inputs.cars[0].state === '';

    useEffect(() => {
        setShowLoader(false);
    }, [error, invoiceSuccessMessage]);

    useEffect(() => {
        const validNumber = !isNaN(inputs.price);
        if (!validNumber) {
            setPriceError('Please enter a valid dollar amount');
        } else {
            setPriceError(null);
        }
    }, [inputs.price]);

    useEffect(() => {
        const { hasToSetError, errorOrNull } = checkForUserNameError(requiredUsernameType, inputs.username);
        if (hasToSetError) setUsernameError(errorOrNull);
    }, [inputs.username, requiredUsernameType]);

    const onSubmit = (event: any) => {
        const data = source ? { ...inputs, source: source } : inputs;
        event.preventDefault();
        createCustomInvoice(data);
        setShowLoader(true);
        setInputs(initialInputs);
    };

    return (
        <Grid columns={1} padded>
            <Grid.Column>
                <Card centered>
                    <SpotHeader spot={spot} invoice={true} source={source} />

                    <Form onSubmit={onSubmit}>
                        {error && <Message info size="tiny" color="red" header="Error" content={error} />}
                        {invoiceSuccessMessage && (
                            <Message
                                info
                                size="tiny"
                                color="green"
                                header="Success"
                                content={!source ? invoiceSuccessMessage : rentalSuccessMessage}
                            />
                        )}
                        {source ? (
                            <AggregatorIntegrationForm
                                inputs={inputs}
                                handleInput={handleInput}
                                usernameError={usernameError}
                                handleValueChange={handleValueChange}
                                setRequiredUsernameType={setRequiredUsernameType}
                                source={source}
                            />
                        ) : (
                            <InvoiceForm
                                spot={spot}
                                inputs={inputs}
                                handleDropdown={handleDropdown}
                                handleInput={handleInput}
                                usernameError={usernameError}
                                priceError={priceError}
                                handleValueChange={handleValueChange}
                                enterpriseDetails={enterpriseDetails}
                                enterpriseDetailsErrorMessage={enterpriseDetailsErrorMessage}
                            />
                        )}

                        {!showLoader ? (
                            <Button type="submit" disabled={buttonDisabled} fluid content={'Confirm'} />
                        ) : (
                            <Loader active inline="centered" />
                        )}
                    </Form>
                </Card>
                {<Footer />}
            </Grid.Column>
        </Grid>
    );
};

export default withRouter(Invoice);
