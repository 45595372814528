import APIClient from './APIClient';

class PaymentService extends Object {
    async createToken(cardElement: any, stripe: any) {
        return await stripe.createToken(cardElement);
    }
    async addCreditCard(cardElement: any, stripe: any, authToken: string) {
        const stripeToken = await stripe.createToken(cardElement);
        const data = { token: stripeToken.token.id };
        return await APIClient.post(`credit_cards/`, data, authToken);
    }
    async getCreditCard(token: string) {
        const creditCard = await APIClient.get(`credit_cards/`, token);
        return creditCard[0]['friendly_format'];
    }
}

export default new PaymentService();
