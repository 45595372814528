import React from 'react';
import { Header } from 'semantic-ui-react';
import Paper from '@material-ui/core/Paper';
import IconLabel from './IconLabel';
import Carousel from '../Carousel/Carousel';
import styles from 'components/Spots/Amenities.module.css';

const Amenities = (props: any) => {
    const { spot, rentalType } = props;

    return (
        <>
            {spot && spot.pk === 774 && rentalType === 'daily' && (
                <>
                    <Header as="h1" content="Free Airport Shuttle Included for Bookings" />
                    <p>Shuttles run on the following schedule:</p>
                    <li>4 AM - 8 AM every 20 minutes</li>
                    <li>8 AM - 5:30 PM every 30 minutes</li>
                    <li>5:30 PM - 9:30 PM every 20 minutes</li>
                    <li>9:30 PM - 1 AM every 30 minutes</li>
                    <li>Last run at 12:30 AM</li>
                </>
            )}
            <Header as="h1" content="Amenities" />
            <Carousel>
                <Paper className={styles.amenityPaper}>
                    <IconLabel icon="clock outline" text="Available 24/7" />
                </Paper>
                <Paper className={styles.amenityPaper}>
                    <IconLabel icon="car" text="Self-parking" />
                </Paper>
                <Paper className={styles.amenityPaper}>
                    <IconLabel icon="map marker alternate" text="Centrally located" />
                </Paper>
                <Paper className={styles.amenityPaper}>
                    <IconLabel icon="lock" text="Trusted & secure" />
                </Paper>
                <Paper className={styles.amenityPaper}>
                    <IconLabel icon="credit card outline" text="Touchless, Covid-safe" />
                </Paper>
                <Paper className={styles.amenityPaper}>
                    <IconLabel icon="calendar alternate outline" text="Free cancellation" />
                </Paper>
                <Paper className={styles.amenityPaper}>
                    <IconLabel icon="lightning" text="Instant booking" />
                </Paper>
                <Paper className={styles.amenityPaper}>
                    <IconLabel icon="heart" text="24/7 Customer support" />
                </Paper>
            </Carousel>
        </>
    );
};

export default Amenities;
