import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import styles from './HourlyRental.module.css';
import moment from 'moment';
import Reminder from '../Webpay/Reminder';
import CountdownTimer from '../Webpay/Timer';
import { RentType } from 'utils/constants';

const HourlyRental = (props: any) => {
    const { rental, endRental, restartKiosk, setReminder, reminderSuccessMessage, kiosk, violation } = props;

    const formattedStartTime = moment.unix(rental.start_date).format('h:mm A');
    const formattedEndTime =
        rental.rental_type === RentType.freeUntilHourly && rental.cancelled_date < rental.end_date
            ? moment.unix(rental.cancelled_date).format('h:mm A')
            : moment.unix(rental.end_date).format('h:mm A');
    const formattedCreatedAtTime = moment.unix(rental.created_at).format('h:mm A');

    const flatRate = rental.rental_type === RentType.flatRate;

    let priceHeader = 'Hourly Rate';
    if (violation) {
        priceHeader = 'Fine Amount';
    } else if (flatRate || kiosk || rental?.is_effective_flat_rate) {
        priceHeader = 'Flat Rate';
    }

    let price = rental.price_hourly;
    if (flatRate) {
        price = rental?.paid;
    } else if (kiosk && !flatRate) {
        price = rental.daily_max;
    }

    return (
        <>
            {rental.state === 'ACTIVE' && !flatRate && !kiosk && !violation && (
                <>
                    <CountdownTimer startTime={rental?.created_at} />
                    <Button
                        color="red"
                        onClick={() => {
                            endRental(rental.pk);
                        }}
                        content="End rental"
                    />
                    <Reminder
                        rentalPk={rental.pk}
                        setReminder={setReminder}
                        reminderSuccessMessage={reminderSuccessMessage}
                    />
                </>
            )}
            {!kiosk && (
                <>
                    <Header as="h5" className={styles.fieldName} content="Time" />
                    {rental.rental_type === RentType.freeUntilHourly
                        ? `${rental.formatted_start_date} at ${formattedCreatedAtTime}`
                        : `${rental.formatted_start_date} at ${formattedStartTime}`}

                    {rental.state === 'ENDED' && !flatRate && (
                        <>
                            {rental.formatted_end_date !== rental.formatted_start_date ? (
                                <p>
                                    {' '}
                                    - {rental.formatted_end_date} at {formattedEndTime}
                                </p>
                            ) : (
                                ' - ' + formattedEndTime
                            )}
                        </>
                    )}
                    <Header as="h5" className={styles.fieldName} content={priceHeader} />
                    <p>
                        {rental.rental_type === RentType.freeUntilHourly && rental.start_date > rental.created_at ? (
                            <>
                                <>Free parking until {formattedStartTime}</>
                                <br />
                                <>
                                    ${(price / 100).toFixed(2)}{' '}
                                    {rental.is_effective_flat_rate ? `flat rate` : `per hour`} after{' '}
                                    {formattedStartTime}
                                </>
                                <br />
                            </>
                        ) : (
                            <>${(price / 100).toFixed(2)}</>
                        )}

                        {rental.promo_code && <span> minus promo for {rental.promo_code_info} off</span>}
                    </p>
                </>
            )}
            {(rental.state !== 'ACTIVE' || (flatRate && !!rental?.paid)) && (
                <>
                    <Header as="h5" className={styles.fieldName} content="Paid" />
                    <p>{rental?.paid > 0 ? '$' + (rental?.paid / 100).toFixed(2) : '$0'}</p>
                </>
            )}
            {rental.state === 'ACTIVE' && kiosk && (
                <Button
                    onClick={() => {
                        restartKiosk(rental.spot);
                    }}
                    content="Done"
                />
            )}
        </>
    );
};

export default HourlyRental;
