import React from 'react';
import { withRouter } from 'react-router-dom';
import { Rating, Header, Form, Button, Modal } from 'semantic-ui-react';
import fieldStyles from './FormFields.module.css';

const RatingField = (props: any) => {
    const { rating, setStars, setFeedback, submitForm, open, setOpen } = props;

    return (
        <>
            <Header as="h5" className={fieldStyles.fieldName} content="Rating" />
            <Rating icon="star" size="huge" maxRating={5} rating={rating} onRate={setStars} />
            {rating && (
                <Modal
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    size="mini"
                    trigger={
                        <Header
                            as="a"
                            color="blue"
                            size="small"
                            content="Leave feedback about this rental"
                            style={{ marginTop: 20 }}
                        />
                    }
                >
                    <Modal.Header>Help us improve</Modal.Header>
                    <Modal.Content>
                        <Form onSubmit={submitForm}>
                            <Form.TextArea
                                label="How can we improve?"
                                onChange={(event, data) => setFeedback(data.value)}
                                placeholder="Let us know what would make your experience better..."
                            />
                            <Form.Field fluid control={Button}>
                                Submit Feedback
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                </Modal>
            )}
        </>
    );
};

export default withRouter(RatingField);
