import React from 'react';
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import styles from './DestinationSpotMap.module.css';
import style from '../Spots/SpotInfo.module.css';
import SpotInfo from './SpotInfo';
import { getSpotPriceByRentalType } from '../../utils/helpers';
import ENV from 'env';

const Map = ReactMapboxGl({
    attributionControl: false,
    accessToken: ENV.MAPBOX_TOKEN,
});

interface Props {
    coords: {
        lat: number;
        lng: number;
    };
    destinationName: string;
    spotsArray: any[];
    activeSpot: any;
    setActiveSpot: (spot: any) => void;
    rentalType: string;
}

const DestinationSpotMap: React.FC<Props> = (props: Props) => {
    const { destinationName, coords, spotsArray, activeSpot, setActiveSpot, rentalType } = props;

    const DestinationMarker = () => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <RoomRoundedIcon style={{ color: 'gray' }} fontSize="large" />
            <div
                style={{
                    background: '#FBFBFB',
                    padding: '.2rem',
                    borderRadius: '.2rem',
                }}
            >
                {destinationName}
            </div>
        </div>
    );

    return (
        <Map
            style={`mapbox://styles/mapbox/light-v10`}
            zoom={[14]} // 0 (zoom out) - 20 (zoom in)
            center={[coords.lng, coords.lat]}
            className={styles.map}
        >
            <>
                <ZoomControl />
                <Marker coordinates={[coords.lng, coords.lat]} anchor="bottom">
                    <DestinationMarker />
                </Marker>

                {spotsArray.map((spot: any) => {
                    const price = getSpotPriceByRentalType(spot, rentalType);
                    return (
                        <Marker
                            key={`destination-map-marker-${spot.name}-mobile`}
                            coordinates={[spot.longitude, spot.latitude]}
                            anchor="bottom"
                            onClick={() => setActiveSpot(spot)}
                        >
                            <div className={style.priceMarker}>{price}</div>
                        </Marker>
                    );
                })}

                {activeSpot && (
                    <SpotInfo spot={activeSpot} toggleDrawer={() => setActiveSpot(null)} rentalType={rentalType} />
                )}
            </>
        </Map>
    );
};

export default DestinationSpotMap;
