import React from 'react';
import { Container, Header, Image, List } from 'semantic-ui-react';
import styles from 'components/SiteMap/SiteMap.module.css';
import Grid from '@material-ui/core/Grid';
import { currentYear } from 'utils/constants';
import { AG_HELP_CENTER_LINK } from '../../utils/constants';

const SiteMap = ({ destinations }: any) => {
    return (
        <div style={{ background: 'white', minHeight: '100vh', minWidth: '100vw' }}>
            <Container>
                <nav className={styles.nav}>
                    <Image src={require('assets/AirGarage.png')} style={{ height: 35 }} />
                </nav>
                <Header as={'h1'}>Site Map</Header>
                <div className={styles.linkContainer}>
                    {destinations.map((destination: any, index: number) => {
                        return (
                            <div key={`site-map-item-${index}`} className={`${styles.linkItem} ${styles.hideOverflow}`}>
                                <a className={styles.link} href={`/search/${destination.slug}`}>
                                    {destination.name}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </Container>
            <Grid container item justify={'space-between'} alignItems={'center'} xs={12} className={styles.footerNav}>
                <a href="/">
                    <Image src={require('assets/airwhite.png')} style={{ height: 35, display: 'inline' }} />
                </a>
                <div>
                    <List.Item as="a" style={{ color: '#fff', margin: '0 8px' }} href="https://airgarage.com/">
                        Homepage
                    </List.Item>
                    <List.Item as="a" style={{ color: '#fff', margin: '0 8px' }} href="https://airgarage.com/terms">
                        Terms
                    </List.Item>
                    <List.Item as="a" style={{ color: '#fff', margin: '0 8px' }} href={AG_HELP_CENTER_LINK}>
                        Contact us
                    </List.Item>
                    <List.Item
                        as="span"
                        style={{ color: '#fff', margin: '0 8px' }}
                        href={`/contact?source=destination-pages`}
                    >
                        ©{currentYear} AirGarage, Inc
                    </List.Item>
                </div>
            </Grid>
        </div>
    );
};

export default SiteMap;
