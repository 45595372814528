import APIClient from './APIClient';

class RentalService extends Object {
    async getRentals(profile: number, token: string) {
        const result = await APIClient.get(`slots?renter=${profile}`, token);
        return result['results'];
    }

    async newGetRentals() {
        const response = await APIClient.newGet(`slots`);
        return response;
    }

    async getRental(rental: number | string, token?: string) {
        const result = [];
        const resultObject = await APIClient.get(`slots/${rental}/`, token!);
        result.push(resultObject);
        return result;
    }
    async getRentalByConfirmationId(confirmationId: string, token?: string) {
        const resultObject = await APIClient.get(`slots/?confirmation_id=${confirmationId}`, token!);
        const rental = resultObject.results[0];
        return rental;
    }
    async endRental(rental: number, token: string) {
        return await APIClient.delete(`slots/${rental}/`, token);
    }
    async updateRental(rental: number, body: any, token: string, queryParams?: any) {
        let baseURL = `slots/${rental}/`;
        if (queryParams) {
            baseURL = `slots/${rental}/?${queryParams}`;
        }
        return await APIClient.patch(baseURL, body, token);
    }
    async bookRental(data: any, token: string) {
        return await APIClient.post(`slots/`, data, token);
    }
    async sendFeedback(body: any, rating: any) {
        let webhookURL = '';
        if (rating > 3) {
            // PraiseBot
            webhookURL = 'https://hooks.slack.com/services/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';
        } else if (rating < 4) {
            // RageBot
            webhookURL = 'https://hooks.slack.com/services/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';
        }
        const response = await fetch(webhookURL, {
            method: 'POST',
            body,
        });
        const result = await response.json();
        if (!response.ok) {
            const text = JSON.stringify(result).replace(/["{}[\],\\]/g, ' ');
            throw new Error(text);
        }
        return result;
    }
}
export default new RentalService();
