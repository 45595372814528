import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import styles from './FAQ.module.css';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/Remove';

const FaqItem = (props: any) => {
    const { question, children, active } = props;
    const [expanded, setExpanded] = useState(active);
    const expandIcon = expanded ? <RemoveIcon /> : <AddIcon />;

    return (
        <Accordion
            variant={'outlined'}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            className={styles.faq}
        >
            <AccordionSummary expandIcon={expandIcon} aria-controls="panel1a-content" className={styles.header}>
                {question}
            </AccordionSummary>
            <AccordionDetails className={styles.content}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default FaqItem;
