import APIClient from './APIClient';

class PromoCodeService extends Object {
    async applyPromoCode(data: any, token: string) {
        const response = await APIClient.post(`promo`, data, token);
        return response;
    }

    async autoPromoCode(data: any, token = '') {
        return APIClient.newPost(`auto_promo`, data, token);
    }
}

export default new PromoCodeService();
