export type CountryCode = 'US' | 'CA' | 'MX';
export type CountryName = 'United States' | 'Canada' | 'Mexico';

interface State {
    value: string;
    label: string;
}
interface StateOption {
    label: CountryName;
    options: State[];
}

const usStates: State[] = [
    {
        label: 'Alabama',
        value: 'AL',
    },
    {
        label: 'Alaska',
        value: 'AK',
    },
    {
        label: 'American Samoa',
        value: 'AS',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'District Of Columbia',
        value: 'DC',
    },
    {
        label: 'Federated States Of Micronesia',
        value: 'FM',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Guam',
        value: 'GU',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Marshall Islands',
        value: 'MH',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Northern Mariana Islands',
        value: 'MP',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Palau',
        value: 'PW',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Puerto Rico',
        value: 'PR',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virgin Islands',
        value: 'VI',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
    {
        label: 'US Government',
        value: 'GV',
    },
];

const mexicoStates: State[] = [
    {
        label: 'Aguascalientes',
        value: 'AG',
    },
    {
        label: 'Baja California',
        value: 'BC',
    },
    {
        label: 'Baja California Sur',
        value: 'BS',
    },
    {
        label: 'Campeche',
        value: 'CM',
    },
    {
        label: 'Chiapas',
        value: 'CS',
    },
    {
        label: 'Chihuahua',
        value: 'CH',
    },
    {
        label: 'Coahuila',
        value: 'CO',
    },
    {
        label: 'Colima',
        value: 'CL',
    },
    {
        label: 'Federal District',
        value: 'DF',
    },
    {
        label: 'Durango',
        value: 'DG',
    },
    {
        label: 'Guanajuato',
        value: 'GT',
    },
    {
        label: 'Guerrero',
        value: 'GR',
    },
    {
        label: 'Hidalgo',
        value: 'HG',
    },
    {
        label: 'Jalisco',
        value: 'JA',
    },
    {
        label: 'Mexico',
        value: 'EM',
    },
    {
        label: 'Michoacán',
        value: 'MI',
    },
    {
        label: 'Morelos',
        value: 'MO',
    },
    {
        label: 'Nayarit',
        value: 'NA',
    },
    {
        label: 'Nuevo León',
        value: 'NL',
    },
    {
        label: 'Oaxaca',
        value: 'OA',
    },
    {
        label: 'Puebla',
        value: 'PU',
    },
    {
        label: 'Querétaro',
        value: 'QT',
    },
    {
        label: 'Quintana Roo',
        value: 'QR',
    },
    {
        label: 'San Luis Potosí',
        value: 'SL',
    },
    {
        label: 'Sinaloa',
        value: 'SI',
    },
    {
        label: 'Sonora',
        value: 'SO',
    },
    {
        label: 'Tabasco',
        value: 'TB',
    },
    {
        label: 'Tamaulipas',
        value: 'TM',
    },
    {
        label: 'Tlaxcala',
        value: 'TL',
    },
    {
        label: 'Veracruz',
        value: 'VE',
    },
    {
        label: 'Yucatán',
        value: 'YU',
    },
    {
        label: 'Zacatecas',
        value: 'ZA',
    },
];

const canadaStates: State[] = [
    {
        value: 'AB',
        label: 'Alberta',
    },
    {
        value: 'BC',
        label: 'British Columbia',
    },
    {
        value: 'MB',
        label: 'Manitoba',
    },
    {
        value: 'NB',
        label: 'New Brunswick',
    },
    {
        value: 'NL',
        label: 'Newfoundland and Labrador',
    },
    {
        value: 'NS',
        label: 'Nova Scotia',
    },
    {
        value: 'NU',
        label: 'Nunavut',
    },
    {
        value: 'NT',
        label: 'Northwest Territories',
    },
    {
        value: 'ON',
        label: 'Ontario',
    },
    {
        value: 'PE',
        label: 'Prince Edward Island',
    },
    {
        value: 'QC',
        label: 'Quebec',
    },
    {
        value: 'SK',
        label: 'Saskatchewan',
    },
    {
        value: 'YT',
        label: 'Yukon',
    },
];

export const stateOptions: StateOption[] = [
    {
        label: 'United States',
        options: usStates,
    },
    {
        label: 'Mexico',
        options: mexicoStates,
    },
    {
        label: 'Canada',
        options: canadaStates,
    },
];

export const mapStateNameToCountry: Record<string, CountryCode> = {
    Alabama: 'US',
    Alaska: 'US',
    'American Samoa': 'US',
    Arizona: 'US',
    Arkansas: 'US',
    California: 'US',
    Colorado: 'US',
    Connecticut: 'US',
    Delaware: 'US',
    'District Of Columbia': 'US',
    'Federated States Of Micronesia': 'US',
    Florida: 'US',
    Georgia: 'US',
    Guam: 'US',
    Hawaii: 'US',
    Idaho: 'US',
    Illinois: 'US',
    Indiana: 'US',
    Iowa: 'US',
    Kansas: 'US',
    Kentucky: 'US',
    Louisiana: 'US',
    Maine: 'US',
    'Marshall Islands': 'US',
    Maryland: 'US',
    Massachusetts: 'US',
    Michigan: 'US',
    Minnesota: 'US',
    Mississippi: 'US',
    Missouri: 'US',
    Montana: 'US',
    Nebraska: 'US',
    Nevada: 'US',
    'New Hampshire': 'US',
    'New Jersey': 'US',
    'New Mexico': 'US',
    'New York': 'US',
    'North Carolina': 'US',
    'North Dakota': 'US',
    'Northern Mariana Islands': 'US',
    Ohio: 'US',
    Oklahoma: 'US',
    Oregon: 'US',
    Palau: 'US',
    Pennsylvania: 'US',
    'Puerto Rico': 'US',
    'Rhode Island': 'US',
    'South Carolina': 'US',
    'South Dakota': 'US',
    Tennessee: 'US',
    Texas: 'US',
    Utah: 'US',
    Vermont: 'US',
    'Virgin Islands': 'US',
    Virginia: 'US',
    Washington: 'US',
    'West Virginia': 'US',
    Wisconsin: 'US',
    Wyoming: 'US',
    'US Government': 'US',
    Aguascalientes: 'MX',
    'Baja California': 'MX',
    'Baja California Sur': 'MX',
    Campeche: 'MX',
    Chiapas: 'MX',
    Chihuahua: 'MX',
    Coahuila: 'MX',
    Colima: 'MX',
    'Federal District': 'MX',
    Durango: 'MX',
    Guanajuato: 'MX',
    Guerrero: 'MX',
    Hidalgo: 'MX',
    Jalisco: 'MX',
    Mexico: 'MX',
    Michoacán: 'MX',
    Morelos: 'MX',
    Nayarit: 'MX',
    'Nuevo León': 'MX',
    Oaxaca: 'MX',
    Puebla: 'MX',
    Querétaro: 'MX',
    'Quintana Roo': 'MX',
    'San Luis Potosí': 'MX',
    Sinaloa: 'MX',
    Sonora: 'MX',
    Tabasco: 'MX',
    Tamaulipas: 'MX',
    Tlaxcala: 'MX',
    Veracruz: 'MX',
    Yucatán: 'MX',
    Zacatecas: 'MX',
    Alberta: 'CA',
    'British Columbia': 'CA',
    Manitoba: 'CA',
    'New Brunswick': 'CA',
    'Newfoundland and Labrador': 'CA',
    'Nova Scotia': 'CA',
    Nunavut: 'CA',
    'Northwest Territories': 'CA',
    Ontario: 'CA',
    'Prince Edward Island': 'CA',
    Quebec: 'CA',
    Saskatchewan: 'CA',
    Yukon: 'CA',
};

export const mapCountryCodeToName: Record<CountryCode, CountryName> = {
    US: 'United States',
    MX: 'Mexico',
    CA: 'Canada',
};
