import React, { useEffect, useState } from 'react';
import { Card, Grid, Message, Loader, Header, Icon } from 'semantic-ui-react';
import styles from './RentalCard.module.css';
import fieldStyles from '../FormFields/FormFields.module.css';
import HourlyRental from '../HourlyRental/HourlyRental';
import LongTermRental from '../LongTermRental/LongTermRental';
import PromoField from 'components/FormFields/PromoField';
import EditCreditCardField from 'components/FormFields/CreditCardFields/EditCreditCardField';
import InfoHeader from '../InfoHeader/InfoHeader';
import RentalCarsField from 'components/FormFields/CarsField/RentalCarsField';
import RatingField from 'components/FormFields/RatingField';
import * as Sentry from '@sentry/react';
import { AGGREGATOR_SOURCE_LIST, ANOTHER_PROMO_ALREADY_APPLIED } from 'utils/constants';

const RentalCard = (props: any) => {
    const {
        rental,
        errorMessage,
        updateRental,
        endRental,
        restartKiosk,
        updateCard,
        successMessage,
        updating,
        addCar,
        removeCar,
        sendFeedback,
        setReminder,
        reminderSuccessMessage,
        applyPromoCode,
        creditCard,
        promoSuccessMessage,
        promoErrorMessage,
    } = props;
    const [rating, setRating] = useState(props.rental.rating || null);
    const [feedback, setFeedback] = useState(null);
    const [open, setOpen] = useState(false);
    const noPlate = rental.cars.length === 0;
    const canEditRental = rental?.state === 'ACTIVE' || rental?.state === 'UPCOMING';
    const canAddCar =
        !AGGREGATOR_SOURCE_LIST.includes(rental.source) &&
        rental.rental_type !== 'daily' &&
        rental.rental_type !== 'flat rate';
    const kiosk = rental.source === 'kiosk';
    const violation = rental.ref === 'violation';

    const setStars = (event: any, data: any) => {
        const rating = data.rating;
        setRating(rating);
        updateRental(rental.pk, { rating: rating });
    };

    const submitForm = () => {
        sendFeedback(rental.renter_phone, feedback, rental.spot_name, rating);
        setOpen(false);
    };
    const promoErrorHeader = promoErrorMessage?.includes(ANOTHER_PROMO_ALREADY_APPLIED)
        ? 'Unable to apply coupon code'
        : 'Coupon code is not valid.';

    const promoErrorContent = promoErrorMessage?.includes(ANOTHER_PROMO_ALREADY_APPLIED)
        ? 'You have already applied a different coupon code'
        : 'Please try a different coupon code.';

    useEffect(() => {
        Sentry.setTag('spot_pk', rental.spot);
        Sentry.setTag('rental_type', rental.rental_type);
    }, [rental.spot, rental.rental_type]);

    return (
        <Grid.Row>
            <Card className={styles.card}>
                {errorMessage && <Message color="red">{errorMessage}</Message>}

                {updating ? (
                    <Loader color="orange" active inline="centered" />
                ) : (
                    <InfoHeader rental={rental} violation={violation} creditCard={creditCard} />
                )}
                {promoSuccessMessage && rental.state === 'ACTIVE' && (
                    <Message info color="green" size="tiny" header="Coupon code applied successfully" />
                )}

                {promoErrorMessage && rental.state === 'ACTIVE' && (
                    <Message info color="red" size="tiny" header={promoErrorHeader} content={promoErrorContent} />
                )}

                {noPlate && (
                    <RentalCarsField
                        cars={rental.cars_detail}
                        canEdit={canEditRental}
                        canAdd={canAddCar}
                        removeCar={removeCar}
                        addCar={addCar}
                        rental={rental}
                    />
                )}

                {rental?.short_term ? (
                    <HourlyRental
                        rental={rental}
                        endRental={endRental}
                        errorMessage={errorMessage}
                        restartKiosk={restartKiosk}
                        setReminder={setReminder}
                        reminderSuccessMessage={reminderSuccessMessage}
                        kiosk={kiosk}
                        violation={violation}
                    />
                ) : (
                    <LongTermRental rental={rental} endRental={endRental} errorMessage={errorMessage} />
                )}
                {!kiosk && !violation && (
                    <>
                        <Header as="h5" className={fieldStyles.fieldName} content="Spot" />
                        <p>
                            <a href={'https://www.google.com/maps/place/' + rental?.spot_address}>
                                <Icon name="map marker alternate" />
                                {rental?.spot_name}
                            </a>
                        </p>

                        {!noPlate && (
                            <RentalCarsField
                                cars={rental.cars_detail}
                                canEdit={canEditRental}
                                canAdd={canAddCar}
                                removeCar={removeCar}
                                addCar={addCar}
                                rental={rental}
                            />
                        )}

                        {canEditRental && rental?.ended === false && (
                            <PromoField applyPromoCode={applyPromoCode} rental={rental} error={errorMessage} />
                        )}
                        {((canEditRental && !rental?.short_term) || rental?.overdue) && (
                            <EditCreditCardField
                                updateCard={updateCard}
                                updating={updating}
                                overdue={rental?.overdue}
                            />
                        )}

                        {successMessage && <Message success color="green" content={successMessage} />}

                        {rental?.state === 'ENDED' && (
                            <RatingField
                                rating={rating}
                                setStars={setStars}
                                setFeedback={setFeedback}
                                submitForm={submitForm}
                                open={open}
                                setOpen={setOpen}
                            />
                        )}
                    </>
                )}

                {violation && (
                    <>
                        <Header as="h5" content="What's next?" />
                        <p>Nothing, you're good to go - but we hope you continue to park with us.</p>
                        <p>
                            As a courtesy to you, we will text you automated reminders to start a rental if our
                            enforcement team notices your car without one.
                        </p>
                        <p>This way, you won't receive any more violation tickets (or be towed)!</p>
                    </>
                )}
            </Card>
        </Grid.Row>
    );
};

export default RentalCard;
