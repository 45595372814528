import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { getSpotAction } from 'actions/actions';
import { withRouter } from 'react-router-dom';
import SpotInfo from 'components/Spots/SpotInfo';
import MetaTags from 'components/MetaTags/MetaTags';
import Footer from 'components/Footer/Footer';
import queryString from 'query-string';

const SpotInfoModuleContainer = (props: any) => {
    const { spot, getSpot } = props;
    const spotId = props.match.params.id;
    const params = queryString.parse(props.location.search);
    const spotRef = params.ref ? params.ref : 'spot-info';
    const defaultSpotRentalType = params.rentalType ? params.rentalType : spot?.display_price ? 'hourly' : 'daily';
    const [rentalType, setRentalType] = useState(defaultSpotRentalType);

    useEffect(() => {
        getSpot(spotId);
    }, [getSpot, spotId]);

    let meta = { title: '', description: '' };

    if (spot && rentalType === 'monthly') {
        meta = {
            title: `Book monthly parking in  ${spot.address.city} with AirGarage at ${spot.name}`,
            description: `AirGarage helps you find cheap parking in ${spot.address.city}. Find and reserve monthly parking at one of our parking lots or parking garages and save money on parking.`,
        };
    } else if (spot && !params.rental_type) {
        meta = {
            title: `${spot.address.city} parking at ${spot.name} with AirGarage`,
            description: `AirGarage helps you find cheap parking in ${spot.address.city}. Find parking at one of our parking lots or parking garages and save money on parking.`,
        };
    } else if (spot && rentalType === 'airport') {
        meta = {
            title: `Book cheap parking at San Francisco International Airport at ${spot.name}`,
            description:
                'AirGarage finds you the best deals at parking lots and garages near SFO. You are always guaranteed a spot near San Francisco International Airport.',
        };
    }

    return (
        <div>
            {spot ? (
                <>
                    <SpotInfo spot={spot} rentalType={rentalType} setRentalType={setRentalType} spotRef={spotRef} />
                    <MetaTags title={meta.title} description={meta.description} />
                    <Footer />
                </>
            ) : (
                <Loader inverted active content="Loading map" />
            )}
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.spots.errorMessage,
        loading: state.spots.loading,
        spot: state.spots.spot,
    };
}

const mapDispatchToProps = {
    getSpot: getSpotAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpotInfoModuleContainer));
