import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rentalsReducer from './rentals.reducers';
import spotsReducer from './spots.reducers';
import authReducer from './auth.reducers';
import remindersReducer from './reminders.reducers';
import contactReducer from './contact.reducers';
import invoicesReducer from './invoices.reducers';
import searchReducer from './search.reducers';
import destinationReducer from './destination.reducers';
import visitorReducer from './visitor.reducers';
import promoReducer from './promo.reducers';
import { CLEAR_DATA } from '../actions/constants';
import enterpriseDetailsReducer from './enterpriseDetails.reducers';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    rentals: rentalsReducer,
    spots: spotsReducer,
    reminders: remindersReducer,
    contact: contactReducer,
    invoices: invoicesReducer,
    search: searchReducer,
    destinations: destinationReducer,
    visitor: visitorReducer,
    promo: promoReducer,
    enterpriseDetails: enterpriseDetailsReducer,
});

const rootReducer = (state: any, action: any) => {
    switch (action.type) {
        case CLEAR_DATA:
            state.rentals = null;
            state.profile = null;
            state.spots = [];
            state.reminders = null;
            state.invoices = null;
            state.search = null;
            state.destinations = null;
            state.visitor = null;
            state.promo = null;
            state.enterpriseDetails = null;
    }
    return appReducer(state, action);
};

export default rootReducer;
