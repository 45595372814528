import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, Header, Message } from 'semantic-ui-react';
import fieldStyles from './FormFields.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { getLables, getOptionFromValue, getOptionsForSelect } from 'assets/data/countriesPhoneCodes';
import { countryCodeStyles, customStyles, customTheme } from './customSelectStyles';
import { isPhone } from 'utils/helpers';

interface Props {
    handleInput: (event: ChangeEvent<HTMLInputElement>) => void;
    handleValueChange?: (name: string, value: any) => void;
    inputs: any;
    error: null | string;
    requiredUsernameType: string;
    invoice?: boolean;
    hasCountryCode?: boolean;
    hasOptionalPhone?: boolean;
}

const UsernameField: React.FC<Props> = ({
    handleInput,
    handleValueChange,
    inputs,
    requiredUsernameType,
    error,
    invoice,
    hasCountryCode = true,
    hasOptionalPhone = false,
}) => {
    const [hasOnlyNumbers, setHasOnlyNumbers] = useState(false);

    let onSelect = (event: any) => {};
    if (handleValueChange) {
        onSelect = (event: any) => {
            if (event) {
                handleValueChange('phoneCode', event.value);
            }
        };
    }

    const getCountrySelector = () => {
        return (
            <Select
                options={getOptionsForSelect()}
                onChange={onSelect}
                isSearchable={true}
                name="phoneCode"
                placeholder=""
                theme={customTheme}
                styles={{ ...customStyles, ...countryCodeStyles }}
                defaultValue={getOptionFromValue(inputs.phoneCode)}
                formatOptionLabel={getLables}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                }}
            />
        );
    };

    const getInternationalNumberAlert = () => {
        return (
            <Message
                info
                color="pink"
                size="tiny"
                content="Support for international phone numbers is coming soon. You can still start your rental through this website. However, you will not receive important logistical text messages from AirGarage - like reminders to end your rental."
            />
        );
    };

    useEffect(() => {
        if (isPhone(inputs.username)) setHasOnlyNumbers(true);
        else setHasOnlyNumbers(false);
    }, [inputs.username]);

    return (
        <>
            {requiredUsernameType === 'email' && (
                <Form.Field>
                    <Header as="h5" className={fieldStyles.fieldName}>
                        Email
                    </Header>
                    <Form.Input
                        icon="mail"
                        iconPosition="left"
                        placeholder="Email"
                        name="username"
                        onChange={handleInput}
                        required
                        error={error}
                        value={inputs.username}
                    />
                </Form.Field>
            )}
            {requiredUsernameType === 'either' && (
                <Form.Field>
                    <Header as="h5" className={fieldStyles.fieldName}>
                        Phone or Email
                    </Header>
                    {hasOnlyNumbers && hasCountryCode && inputs.phoneCode !== '+1' && getInternationalNumberAlert()}
                    <div className={fieldStyles.complexField}>
                        {hasOnlyNumbers && hasCountryCode && getCountrySelector()}
                        <Form.Input
                            placeholder="Phone number or email"
                            name="username"
                            onChange={handleInput}
                            value={inputs.username}
                            required
                            error={error}
                            icon={inputs.username === '' ? 'user circle' : !hasOnlyNumbers ? 'mail' : undefined}
                            iconPosition={!hasOnlyNumbers ? 'left' : undefined}
                        />
                    </div>
                </Form.Field>
            )}
            {requiredUsernameType === 'phone' && (
                <Form.Field>
                    <Header as="h5" className={fieldStyles.fieldName}>
                        Phone
                    </Header>
                    {inputs.phoneCode !== '+1' && hasCountryCode && getInternationalNumberAlert()}
                    <div className={fieldStyles.complexField}>
                        {hasCountryCode && getCountrySelector()}
                        <Form.Input
                            placeholder="Phone number"
                            name={hasOptionalPhone ? 'phone' : 'username'}
                            onChange={handleInput}
                            value={hasOptionalPhone ? inputs.phone : inputs.username}
                            required
                            error={error}
                        />
                    </div>
                </Form.Field>
            )}
            {requiredUsernameType === 'CAP' && (
                <Form.Field>
                    <Header as="h5" className={fieldStyles.fieldName}>
                        USER PROFILE
                    </Header>
                    <Form.Input name="username" value={'anonymous@user.com'} disabled required error={error} />
                </Form.Field>
            )}
        </>
    );
};

export default UsernameField;
