import React from 'react';
import { Button, Header, Grid, Divider } from 'semantic-ui-react';
import styles from './LongTermRental.module.css';

const LongTermRental = (props: any) => {
    const { rental, endRental } = props;
    const dailyRental = rental?.rental_type === 'daily';
    const cancelledBeforeStart = !!rental?.cancelled_date && rental?.cancelled_date < rental?.start_date;
    const priceMonthly = Number((rental.price_monthly / 100).toFixed(2));
    const numCars = rental.cars.length;

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h5" className={styles.fieldName} content="Dates" />
                        {cancelledBeforeStart ? (
                            <p>Your rental was cancelled on {rental?.formatted_cancelled_date}.</p>
                        ) : (
                            <p>
                                {rental.formatted_start_date} -{' '}
                                {rental.end_date ? rental.formatted_end_date : ' Until I Cancel '}
                            </p>
                        )}
                        {rental?.ended === false && !dailyRental && (
                            <span>
                                <Button
                                    testId="end"
                                    size="mini"
                                    style={{ fontSize: '10px', padding: '7px' }}
                                    onClick={() => {
                                        endRental(rental.pk);
                                    }}
                                    color="red"
                                >
                                    Cancel rental
                                </Button>
                            </span>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider hidden />
            {dailyRental ? (
                <div>
                    <Header as="h5" className={styles.fieldName} content="Total" />
                    <p>
                        ${(rental.balance / 100).toFixed(2)}
                        {rental.promo_code && <span> minus promo for {rental.promo_code_info} off</span>}
                    </p>
                </div>
            ) : (
                <div>
                    <Header as="h5" className={styles.fieldName} content="Monthly rate" />

                    <p>
                        <small>total:</small> ${(priceMonthly * numCars).toFixed(2)}
                        {numCars > 1 && <i className={styles.subtext}>(${priceMonthly.toFixed(2)} per car)</i>}
                    </p>
                    {rental.promo_code && (
                        <i className={styles.subtext}> Promo for {rental.promo_code_info} was applied</i>
                    )}
                </div>
            )}

            {rental?.phone && (
                <>
                    <Header as="h5" className={styles.fieldName} content="Phone" />
                    <p>{rental.renter_phone}</p>
                </>
            )}
        </>
    );
};

export default LongTermRental;
