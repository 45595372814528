import {
    SEARCH_COORDS_REQUESTED,
    SEARCH_COORDS_SUCCESS,
    SEARCH_COORDS_FAILED,
    DESTINATION_SUCCESS,
    DESTINATION_REQUESTED,
    DESTINATION_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialState = {
    errorMessage: '',
    successMessage: '',
    loading: false,
    coords: null,
};

export default function searchReducer(state = initialState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case SEARCH_COORDS_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case SEARCH_COORDS_SUCCESS:
            newState.loading = false;
            newState.coords = action.payload.coords;
            return newState;
        case SEARCH_COORDS_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;

        case DESTINATION_REQUESTED:
            newState.loading = true;
            return newState;

        case DESTINATION_SUCCESS: {
            // When a new destination is set the search bar should reflect the location of the destination
            const lat = action.payload.destination.latitude;
            const lng = action.payload.destination.longitude;
            newState.coords = { lat, lng };
            return newState;
        }

        case DESTINATION_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;

        default:
            return state || initialState;
    }
}
