import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Invoice from 'components/Invoice/Invoice';
import { Loader } from 'semantic-ui-react';

import { getSpotAction, createCustomInvoiceAction, getEnterpriseDetailsAction } from 'actions/actions';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

const InvoiceModuleContainer = (props: any) => {
    const params = queryString.parse(props.location.search);
    const {
        spot,
        getSpot,
        errorMessage,
        invoiceSuccessMessage,
        rentalSuccessMessage,
        createCustomInvoice,
        getEnterpriseDetails,
        enterpriseDetails,
        enterpriseDetailsErrorMessage,
    } = props;
    const { source } = params;
    const spotId = props.match.params.id;

    useEffect(() => {
        getSpot(spotId);
        getEnterpriseDetails();
    }, [getSpot, spotId, getEnterpriseDetails]);

    const loading = !spot;

    if (loading) {
        return <Loader inverted active />;
    } else {
        return (
            <Invoice
                spot={spot}
                createCustomInvoice={createCustomInvoice}
                rentalSuccessMessage={rentalSuccessMessage}
                invoiceSuccessMessage={invoiceSuccessMessage}
                error={errorMessage}
                source={source}
                enterpriseDetails={enterpriseDetails}
                enterpriseDetailsErrorMessage={enterpriseDetailsErrorMessage}
            />
        );
    }
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.invoices.errorMessage,
        invoiceSuccessMessage: state.invoices.invoiceSuccessMessage,
        rentalSuccessMessage: state.invoices.rentalSuccessMessage,
        spot: state.spots.spot,
        enterpriseDetails: state.enterpriseDetails.details,
        enterpriseDetailsErrorMessage: state.enterpriseDetails.errorMessage,
    };
}

const mapDispatchToProps = {
    getSpot: getSpotAction,
    createCustomInvoice: createCustomInvoiceAction,
    getEnterpriseDetails: getEnterpriseDetailsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceModuleContainer));
