import APIClient from './APIClient';

class InvoiceService extends Object {
    async createCustomInvoice(data: any, token = '') {
        const response = await APIClient.post(`slots/`, data, token);
        return response;
    }

    async getEnterpriseDetails(token = '') {
        const response = await APIClient.get(`enterprise_details/`, token);
        return response;
    }
}

export default new InvoiceService();
