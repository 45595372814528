function createTimeOptions() {
    const timeOptions = [
        { value: 30, text: '30 minutes' },
        { value: 60, text: '1 hour' },
        { value: 90, text: '1.5 hours' },
        { value: 120, text: '2 hours' },
        { value: 180, text: '3 hours' },
        { value: 240, text: '4 hours' },
        { value: 300, text: '5 hours' },
    ];
    return timeOptions;
}

export const timeOptions = createTimeOptions();

export const rentalOptions = [
    { value: 'hourly', text: 'Hourly' },
    { value: 'monthly', text: 'Monthly' },
    { value: 'daily', text: 'Airport' },
];

export enum RentType {
    monthly = 'monthly',
    enterprise = 'enterprise',
    daily = 'daily',
    hourly = 'hourly',
    flatRate = 'flat rate',
    freeUntilHourly = 'free_until_hourly',
}

enum IntervalOption {
    ONE_TIME = 'one_time',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    ALWAYS_ON = 'always_on',
}

export interface Spot {
    display_price: string | null;
    display_price_monthly: string | null;
    display_price_daily: string | null;
    pk: string;
    uuid: string;
    active_rate: null | {
        unit: RentType;
        interval: IntervalOption;
    };
}

export interface RentalOptionsData {
    value: string;
    text: string;
    link: string;
    buttonTitle: string;
}

export const contactTopics = [
    { value: 'Incorrect or Unrecognized Charge', text: 'Incorrect or Unrecognized Charge' },
    { value: 'Forgot to End Rental', text: 'Forgot to End Rental' },
    { value: 'Bug or Error', text: 'Bug or Error' },
    { value: 'Incorrect Parking Ticket', text: 'Incorrect Parking Ticket' },
    { value: 'Question About Rental', text: 'Question About Rental' },
    { value: 'Car Immobilized or Towed', text: 'Car Immobilized or Towed' },
    { value: 'Discount / Validation Code Issue', text: 'Discount / Validation Code Issue' },
    { value: 'Other', text: 'Other' },
];

export const invoiceTypes = [
    { value: 'Subscription', text: 'Subscription' },
    { value: 'Invoice', text: 'One-time invoice' },
    { value: 'Log', text: 'Log an outside payment' },
];

export const AGGREGATOR_SOURCE_LIST = ['SpotHero', 'CheapAirportParking', 'AirportParkingReservations'];

export const VISITOR_FORM_VALIDATION_SOURCE = 'visitor-form';

export const currentYear = new Date().getFullYear();

export const AG_HELP_CENTER_LINK = 'http://help.airgarage.com/';

export const ANOTHER_PROMO_ALREADY_APPLIED = 'Another discount code already applied to this rental.';

export const AIRPORT_LOT = 'Airport Lot';

export const paymentMethodOptions = [
    { key: 'us_bank_account', value: 'us_bank_account', text: 'Bank Account (Recurring Payments)' },
    { key: 'card', value: 'card', text: 'Credit or Debit Card' },
    { key: 'paper_check', value: 'paper_check', text: 'Paper Check' },
    { key: 'ach_credit_transfer', value: 'ach_credit_transfer', text: 'Wire Transfer (One-Time Payment)' },
];

export const rentalTypeOptions = [
    { value: 'hourly', text: 'Hourly' },
    { value: 'daily', text: 'Daily' },
    { value: 'monthly', text: 'Monthly' },
    { value: 'flat rate', text: 'Flat Rate' },
];
