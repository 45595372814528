import React from 'react';
import ReactRouter from './Router';
import { Provider } from 'react-redux';
import store from './store/index';
import 'semantic-ui-less/semantic.less';
import Fullstory from 'react-fullstory';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import MetaTags from './components/MetaTags/MetaTags';
import TagManager from 'react-gtm-module';
import AnalyticService from 'services/AnalyticService';
import LogRocketService from 'services/LogRocketService';
import ENV from 'env';

const stripePromise = loadStripe(ENV.STRIPE_TOKEN);

const tagManagerArgs = {
    gtmId: 'GTM-PKRHB35',
};

function App() {
    AnalyticService.initialize();
    LogRocketService.init();
    TagManager.initialize(tagManagerArgs);
    return (
        <Provider store={store}>
            <Elements stripe={stripePromise}>
                <Fullstory org={'9WGEE'} />
                <ReactRouter />
                <MetaTags />
            </Elements>
        </Provider>
    );
}

export default App;
