import {
    ENTERPRISE_DETAILS_REQUESTED,
    ENTERPRISE_DETAILS_SUCCESS,
    ENTERPRISE_DETAILS_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};
const initialSpotState = {
    details: [],
    loading: false,
    errorMessage: '',
};

export default function enterpriseDetailsReducer(state = initialSpotState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case ENTERPRISE_DETAILS_REQUESTED:
            newState.loading = true;
            return newState;
        case ENTERPRISE_DETAILS_SUCCESS:
            newState.loading = false;
            newState.details = action.payload;
            return newState;
        case ENTERPRISE_DETAILS_FAILED:
            newState.errorMessage = action.message;
            newState.details = [];
            newState.loading = false;
            return newState;
        default:
            return state || initialSpotState;
    }
}
