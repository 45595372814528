import amplitude from 'amplitude-js';

interface DynamicEventData {
    spot: number;
    rental_type: string;
    dynamic_pricing_strategy: string;
    price_hourly: number;
    dynamic_price?: number;
    display_price?: string;
    experiment_name?: string | null;
    segment_name?: string | null;
}

const isProd = process.env.NODE_ENV === 'production';

class AnalyticServer extends Object {
    initialize() {
        if (isProd) amplitude.getInstance().init('be1a142dbf138c100b5f1cfb11dedd88');
    }

    setUserId(userId: string) {
        if (isProd) amplitude.setUserId(userId);
    }

    logEvent(text: string, data?: any) {
        if (isProd) amplitude.getInstance().logEvent(text, data);
    }

    logDynamicPriceEvent(
        text: string,
        spotPk: number,
        rentalType: string,
        dynamicPricingStrategy: string,
        hourlyPrice: number,
        dynamicPrice?: number,
        displayPrice?: string,
        experiment_name?: string | null,
        segment_name?: string | null
    ) {
        const data: DynamicEventData = {
            spot: spotPk,
            rental_type: rentalType,
            dynamic_pricing_strategy: dynamicPricingStrategy,
            price_hourly: hourlyPrice,
        };
        if (dynamicPrice) data.dynamic_price = dynamicPrice;
        if (displayPrice) data.display_price = displayPrice;
        if (experiment_name) data.experiment_name = experiment_name;
        if (segment_name) data.segment_name = segment_name;

        if (isProd) amplitude.getInstance().logEvent(text, data);
    }
}

export default new AnalyticServer();
