import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = (props: any) => {
    const { title, description, spot } = props;

    return spot ? (
        <Helmet>
            <title>{`Book parking at ${spot.name}`}</title>
            <meta name="title" content={`AirGarage at ${spot.name}`} />
            <meta name="description" content={`Park with AirGarage in ${spot.address.city} ${spot.address.state}`} />
            <meta property="og:title" content={`Park at ${spot.name}`} />
            <meta
                property="og:description"
                content={`Park at ${spot.name} with AirGarage in ${spot.address.city} ${spot.address.state}`}
            />
            <meta property="og:image" content={spot.photo_url} />
            <meta property="og:url" content={window.location.href} />
        </Helmet>
    ) : (
        <Helmet>
            <title>{title ? title : 'Book parking with AirGarage'}</title>
            <meta
                name="description"
                content={description ? description : 'Find and book hourly or monthly parking near you.'}
            />
        </Helmet>
    );
};

export default MetaTags;
