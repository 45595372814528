import React, { ChangeEvent } from 'react';
import { Form, Header } from 'semantic-ui-react';
import fieldStyles from '../FormFields.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import { mapStateNameToCountry, stateOptions } from '../../../assets/data/countriesAndStates';
import Select from 'react-select';
import { customStyles, customTheme } from '../customSelectStyles';
import { Car } from 'utils/types';
import { getStateFromValue } from 'utils/helpers';

interface Props {
    inputs: any;
    index?: number;
    handleValueChange: (name: string, value: any) => void;
    handleInput?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const PlateAndStateField: React.FC<Props> = ({ handleInput, inputs, handleValueChange, index }) => {
    const onSelect = (event: any) => {
        if (!index?.toString()) {
            handleValueChange('state', event.value);
            handleValueChange('country', mapStateNameToCountry[event.label] || 'US');
        } else {
            const cars: Car[] = inputs.cars;
            cars[index].state = event.value;
            cars[index].country = mapStateNameToCountry[event.label] || 'US';
            handleValueChange('cars', cars);
        }
    };

    const onPlateChange = (event: any) => {
        if (handleInput) handleInput(event);
        else if (index?.toString()) {
            const cars: Car[] = inputs.cars;
            cars[index].plate = event.target.value.toUpperCase();
            handleValueChange('cars', cars);
        }
    };

    const stateValue = index?.toString()
        ? getStateFromValue(inputs.cars[index].state, inputs.cars[index].country)
        : getStateFromValue(inputs.state, inputs.country);

    const plateValue = index?.toString() ? inputs.cars[index].plate : inputs.plate;

    return (
        <>
            <Form.Field>
                <Header as="h5" className={fieldStyles.fieldName}>
                    Car
                </Header>
                <Form.Input
                    placeholder="License plate number"
                    name="plate"
                    onChange={onPlateChange}
                    required
                    value={plateValue}
                    icon={'car'}
                    iconPosition="left"
                />

                <Select
                    options={stateOptions}
                    onChange={onSelect}
                    isSearchable={true}
                    name="state"
                    placeholder="State / Province"
                    theme={customTheme}
                    styles={customStyles}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    value={stateValue}
                />
            </Form.Field>
        </>
    );
};

export default PlateAndStateField;
