import React from 'react';
import { Divider, Image, Card, Label } from 'semantic-ui-react';
import { getRating, handleDynamicPricingLogInMapList } from '../../utils/helpers';
import styles from './SpotInfo.module.css';

const SpotCardResult = (props: { spot: any; link: string; price: number; interval: string; rentalType: string }) => {
    const { spot, link, price, interval, rentalType } = props;
    const spotPageUrl = `${spot['webflow_page_url']}/?rentalType=${rentalType}` || link;

    return (
        <a
            href={spotPageUrl}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => handleDynamicPricingLogInMapList(spot, rentalType)}
        >
            <Card fluid style={{ padding: 0 }}>
                <Card.Content>
                    <Image
                        floated="left"
                        src={spot.photo_url ? spot.photo_url : require('assets/share.png')}
                        rounded
                        className={styles.cardImagePreview}
                    />
                    <Card.Header>
                        <div style={{ float: 'right' }}>
                            <p className={styles.priceText}>
                                <strong style={{ color: '#000' }}>${(price / 100).toFixed(2)}</strong> {interval}
                            </p>
                        </div>

                        {spot.active_rentals > 0 ? (
                            <Label content="POPULAR" color="pink" />
                        ) : (
                            <Label content="GOOD PRICE" color="blue" />
                        )}
                        <Label icon="star" content={getRating([5, 9])} color="yellow" />
                    </Card.Header>
                    <h3 style={{ color: '#000' }}>{spot.name} </h3>
                    <Card.Meta>
                        {spot.address.address_line1} {spot.address.city}, {spot.address.state} {spot.address.zipcode}
                    </Card.Meta>
                </Card.Content>
                <Divider />
            </Card>
        </a>
    );
};

export default SpotCardResult;
