import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import StartDateField from 'components/FormFields/StartDateField';
import EndDateField from 'components/FormFields/EndDateField';

const DailyRentalFields = (props: any) => {
    // set 30 day minimum on calendar
    const blockedMinimumDate = [];
    if (props.inputs.start) {
        for (let i = 0; i < props.minimum_booking_length; i++) {
            const blockedDay = addDays(props.inputs.start, i);
            blockedMinimumDate.push(blockedDay);
        }
    }

    return (
        <>
            <StartDateField
                selectedDate={props.inputs.start}
                handleValueChange={props.handleValueChange}
                invoice={props.invoice}
                minimumBookingLength={props.minimum_booking_length}
            />
            <EndDateField
                monthly={false}
                invoice={props.invoice}
                minDate={props.inputs.start ? props.inputs.start : new Date()}
                inputs={props.inputs}
                handleValueChange={props.handleValueChange}
                minimumBookingLength={props.minimum_booking_length}
                excludeDates={props.invoice ? [] : blockedMinimumDate}
            />
        </>
    );
};

export default withRouter(DailyRentalFields);
