import {
    DESTINATION_LIST_REQUESTED,
    DESTINATION_LIST_SUCCESS,
    DESTINATION_LIST_FAILED,
    DESTINATION_REQUESTED,
    DESTINATION_SUCCESS,
    DESTINATION_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialState = {
    errorMessage: '',
    successMessage: '',
    loading: false,
    destination: {},
    destinationList: [],
};

export default function DestinationReducer(state = initialState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        // Get Destination
        case DESTINATION_REQUESTED:
            newState.loading = true;
            return newState;

        case DESTINATION_SUCCESS: {
            newState.loading = false;
            const destination = action.payload.destination;
            newState.destination = {
                ...destination,
                nearbyDestinations: destination.nearby_destinations,
                placeType: destination.place_type,
                imageUrl: destination.image_url,
                secondImageUrl: destination.second_image_url,
            };
            return newState;
        }

        case DESTINATION_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;

        // List Destinations
        case DESTINATION_LIST_REQUESTED:
            newState.loading = true;
            return newState;

        case DESTINATION_LIST_SUCCESS:
            newState.loading = false;
            newState.destinationList = action.payload.destinations;
            return newState;

        case DESTINATION_LIST_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;

        default:
            return state || initialState;
    }
}
