import {
    PROFILE_REQUESTED,
    PROFILE_SUCCESS,
    PROFILE_FAILED,
    USER_DETAILS_REQUESTED,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAILED,
    LOGOUT,
    AUTHENTICATE_REQUESTED,
    AUTHENTICATE_SUCCESS,
    AUTHENTICATE_FAILED,
} from '../actions/constants';
import Cookies from 'js-cookie';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

const initialState = {
    token: '',
    profile: null,
    errorMessage: '',
    successMessage: '',
    loading: false,
    detailsLoading: false,
    defaultCreditCard: '',
    defaultCar: { plate: '', state: '', country: '' },
};

export default function authReducer(state = initialState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case PROFILE_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case PROFILE_SUCCESS:
            newState.loading = false;
            newState.profile = action.payload.profile;
            newState.token = Cookies.get('airgarage_auth');
            return newState;
        case PROFILE_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case USER_DETAILS_REQUESTED:
            newState.detailsLoading = true;
            newState.errorMessage = '';
            return newState;
        case USER_DETAILS_SUCCESS:
            newState.detailsLoading = false;
            newState.defaultCreditCard = action.payload.creditCard;
            newState.defaultCar = action.payload.car;
            return newState;
        case USER_DETAILS_FAILED:
            newState.detailsLoading = false;
            newState.errorMessage = action.message;
            return newState;
        case AUTHENTICATE_REQUESTED:
            newState.detailsLoading = true;
            newState.errorMessage = '';
            return newState;
        case AUTHENTICATE_SUCCESS:
            newState.detailsLoading = false;
            newState.token = action.payload.token;
            return newState;
        case AUTHENTICATE_FAILED:
            newState.detailsLoading = false;
            newState.errorMessage = action.message;
            return newState;
        case LOGOUT:
            newState.profile = null;
            newState.token = '';
            Cookies.remove('airgarage_auth', { path: '/', expires: 2000 });
            Cookies.remove('airgarage_auth', { domain: 'airgara.ge', path: '/', expires: 2000 });
            Cookies.remove('airgarage_auth', { domain: 'airgarage.com', path: '/', expires: 2000 });
            return newState;
        default:
            return state || initialState;
    }
}
