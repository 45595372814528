import ENV from 'env';
import Cookies from 'js-cookie';

const getToken = async (): Promise<string> => (await Cookies.get('airgarage_auth')) || '';

class APIClient extends Object {
    async getHeaders(token: string, file = false) {
        token = token || (await getToken());
        const headers: any = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) {
            headers['Authorization'] = 'Token ' + token;
        }
        if (file) {
            headers['Content-Disposition'] = 'attachment; filename=picture';
        }
        return headers;
    }

    async get(endpoint: string, token = '') {
        const response = await fetch(ENV.API_BASE + endpoint, {
            method: 'GET',
            headers: await this.getHeaders(token),
            credentials: 'include',
        });
        const result = await response.json();
        if (!response.ok) {
            const text = JSON.stringify(result).replace(/["{}[\],\\]/g, ' ');
            throw new Error(text);
        }
        return result;
    }

    async newGet(endpoint: string, token = '') {
        const response = await fetch(ENV.API_BASE + endpoint, {
            method: 'GET',
            headers: await this.getHeaders(token),
            credentials: 'include',
        });
        return response;
    }

    async post(endpoint: string, params: object, token = '') {
        const body = JSON.stringify(params);
        const response = await fetch(ENV.API_BASE + endpoint, {
            method: 'POST',
            headers: await this.getHeaders(token),
            credentials: 'include',
            body,
        });
        const result = await response.json();
        if (!response.ok) {
            const text = JSON.stringify(result).replace(/["{}[\],\\]/g, ' ');
            throw new Error(text);
        }
        return result;
    }

    async newPost(endpoint: string, body: any, token = '') {
        return fetch(ENV.API_BASE + endpoint, {
            method: 'POST',
            headers: await this.getHeaders(token),
            body: JSON.stringify(body),
            credentials: 'include',
        });
    }

    async postFile(endpoint: string, file: any, token = '') {
        try {
            const response = await fetch(ENV.API_BASE + endpoint, {
                method: 'POST',
                headers: await this.getHeaders(token, true),
                body: file,
            });
            const responseJSON = await response.json();
            return responseJSON;
        } catch (error) {
            return { error: error.message };
        }
    }

    async patch(endpoint: string, params: object, token = '') {
        const body = JSON.stringify(params);
        const response = await fetch(ENV.API_BASE + endpoint, {
            method: 'PATCH',
            headers: await this.getHeaders(token),
            credentials: 'include',
            body,
        });
        const result = await response.json();
        if (!response.ok) {
            const text = JSON.stringify(result).replace(/["{}[\],\\]/g, ' ');
            throw new Error(text);
        }
        return result;
    }

    async delete(endpoint: string, token: string) {
        const response = await fetch(ENV.API_BASE + endpoint, {
            method: 'DELETE',
            headers: await this.getHeaders(token),
            credentials: 'include',
        });
        if (response.status === 204) {
            return { result: 'success' };
        }
        return { result: 'failed' };
    }
}
export default new APIClient();
