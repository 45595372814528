import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Message, Loader } from 'semantic-ui-react';

const CheckInForm = (props: any) => {
    const { inputs, handleInput, onSubmit, buttonDisabled, errorMessage, showLoader } = props;

    return (
        <Form onSubmit={() => onSubmit()}>
            {errorMessage && (
                <Message
                    color="red"
                    content={"Error: We couldn't find a rental with that confirmation code. Please try again later."}
                />
            )}

            <p>Check in with confirmation code</p>
            <Form.Field>
                <Form.Input
                    placeholder="Confirmation Code"
                    name="confirmationCode"
                    onChange={handleInput}
                    value={inputs.confirmationCode}
                    required
                />
            </Form.Field>

            {!showLoader ? (
                <Form.Button content={'Continue'} fluid disabled={buttonDisabled} name="form-continue" />
            ) : (
                <Loader active inline="centered" />
            )}
        </Form>
    );
};

export default withRouter(CheckInForm);
