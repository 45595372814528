import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Rentals from 'components/Rentals/Rentals';
import { Loader } from 'semantic-ui-react';
import {
    getRentalsAction,
    updateRentalAction,
    endRentalAction,
    logoutAction,
    restartKioskAction,
    updateCardAction,
    addCarToRentalAction,
    removeCarFromRentalAction,
    sendFeedbackAction,
    setReminderAction,
    applyPromoCodeAction,
    authenticateAction,
} from 'actions/actions';
import { withRouter } from 'react-router';
import queryString from 'query-string';

const RentalsModuleContainer = (props: any) => {
    const {
        getRentals,
        authenticate,
        rentals,
        updateRental,
        endRental,
        loading,
        authToken,
        logout,
        addCar,
        removeCar,
        errorMessage,
        restartKiosk,
        updateCard,
        successMessage,
        updating,
        sendFeedback,
        setReminder,
        reminderSuccessMessage,
        applyPromoCode,
        creditCard,
        promoSuccessMessage,
        promoErrorMessage,
    } = props;
    const params = queryString.parse(props.location.search);
    const { rental, username, pin, confirmation_id } = params;
    const loadingRentals = loading || rentals == null;

    const handleGetRentals = useCallback(
        (rental?: any) => {
            if (rental) {
                getRentals(rental, confirmation_id);
            } else {
                getRentals();
            }
        },
        [getRentals, confirmation_id]
    );

    const refetchRentals = () => {
        rental && getRentals(rental, confirmation_id);
    };

    useEffect(() => {
        if (pin && username) {
            authenticate(username, pin, rental);
        }
    }, [authenticate, username, pin, rental]);

    useEffect(() => {
        if (!authToken && (!username || !pin)) {
            // This part of the flow will be use to handle when user are using sessions, so getRentals should
            // be called right away.
            handleGetRentals(rental);
        } else if (authToken) {
            // This part of the flow will be use to handle when user are authenticated, so we need to make sure
            // that getRentals is call after authentication request.
            handleGetRentals(rental);
        }
    }, [handleGetRentals, getRentals, authToken, username, pin, rental]);

    useEffect(() => {
        document.addEventListener('visibilitychange', refetchRentals);

        return () => {
            document.removeEventListener('visibilitychange', refetchRentals);
        };
    });

    return (
        <div>
            {!loadingRentals ? (
                <Rentals
                    authToken={authToken}
                    logout={logout}
                    updateRental={updateRental}
                    endRental={endRental}
                    rentals={rentals}
                    addCar={addCar}
                    removeCar={removeCar}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    restartKiosk={restartKiosk}
                    updateCard={updateCard}
                    updating={updating}
                    sendFeedback={sendFeedback}
                    setReminder={setReminder}
                    reminderSuccessMessage={reminderSuccessMessage}
                    applyPromoCode={applyPromoCode}
                    creditCard={creditCard}
                    promoSuccessMessage={promoSuccessMessage}
                    promoErrorMessage={promoErrorMessage}
                />
            ) : (
                <Loader inverted active />
            )}
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        authToken: state.auth.token,
        errorMessage: state.rentals.errorMessage,
        successMessage: state.rentals.successMessage,
        loading: state.rentals.loading,
        rentals: state.rentals.rentals,
        updating: state.rentals.updating,
        reminderSuccessMessage: state.reminders.successMessage,
        creditCard: state.rentals.creditCard,
        promoSuccessMessage: state.promo.successMessage,
        promoErrorMessage: state.promo.errorMessage,
    };
}

const mapDispatchToProps = {
    getRentals: getRentalsAction,
    authenticate: authenticateAction,
    updateRental: updateRentalAction,
    endRental: endRentalAction,
    addCar: addCarToRentalAction,
    removeCar: removeCarFromRentalAction,
    logout: logoutAction,
    restartKiosk: restartKioskAction,
    updateCard: updateCardAction,
    sendFeedback: sendFeedbackAction,
    setReminder: setReminderAction,
    applyPromoCode: applyPromoCodeAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RentalsModuleContainer));
