import React from 'react';
import { withRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Header } from 'semantic-ui-react';
import MutiplePlatesField from 'components/FormFields/MultiplePlateField';
import UsernameField from 'components/FormFields/UsernameField';
import fieldStyles from '../FormFields/FormFields.module.css';
import { invoiceTypes, paymentMethodOptions, rentalTypeOptions } from '../../utils/constants';
import DailyRentalFields from 'components/FormFields/DailyRentalFields';
import MonthlyRentalFields from 'components/FormFields/MonthlyRentalFields';

const InvoiceForm = (props: any) => {
    const {
        spot,
        source,
        handleDropdown,
        inputs,
        handleInput,
        usernameError,
        priceError,
        handleValueChange,
        enterpriseDetails,
    } = props;

    const enterpriseOptions = enterpriseDetails.map((item: any) => ({ value: item.pk, text: item.category }));

    const priceLabel = inputs.type === 'Subscription' ? 'Price Per Car' : 'Price';

    const daily = rentalTypeOptions[1];

    return (
        <>
            <Form.Field>
                <Header as="h5" className={fieldStyles.fieldName}>
                    Invoice Type
                </Header>
                <Form.Dropdown
                    name="type"
                    onChange={handleDropdown}
                    placeholder="Select a Type"
                    options={invoiceTypes}
                    value={inputs.type}
                    selection
                    search
                    required
                />
            </Form.Field>
            <UsernameField
                inputs={inputs}
                handleInput={handleInput}
                error={usernameError}
                requiredUsernameType={'email'}
                invoice={true}
            />
            <Form.Field>
                <Header as="h5" className={fieldStyles.fieldName} content="Description" />
                <Form.Input
                    placeholder={'Broxton Ave Food Truck Parking'}
                    name="description"
                    onChange={handleInput}
                    value={inputs.description}
                    required
                />
            </Form.Field>
            <Form.Field>
                <Header as="h5" className={fieldStyles.fieldName}>
                    {priceLabel} (Cents)
                </Header>
                <Form.Input
                    placeholder="10000"
                    name="price"
                    onChange={handleInput}
                    value={inputs.price}
                    required
                    error={priceError}
                />
            </Form.Field>
            <Form.Field>
                <Header as="h5" className={fieldStyles.fieldName} content="Commission Percentage" />
                <Form.Input
                    placeholder={spot.commission_monthly}
                    name="commission"
                    onChange={handleInput}
                    value={inputs.commission}
                    required
                />
            </Form.Field>
            {inputs.type === 'Subscription' ? (
                <MonthlyRentalFields inputs={inputs} handleValueChange={handleValueChange} invoice={true} />
            ) : (
                <DailyRentalFields inputs={inputs} handleValueChange={handleValueChange} invoice={true} />
            )}
            <MutiplePlatesField handleInput={handleValueChange} inputs={inputs} allowNoPlate={source} invoice={true} />
            {inputs.type === 'Invoice' && (
                <>
                    <Form.Field>
                        <Header as="h5" className={fieldStyles.fieldName} content="Payment Methods" />
                        <Form.Dropdown
                            placeholder="Select Payment Methods"
                            multiple
                            selection
                            options={paymentMethodOptions}
                            name="payment_methods"
                            onChange={handleDropdown}
                            value={inputs.payment_methods}
                            search
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <Header as="h5" className={fieldStyles.fieldName} content="Rental Type" />
                        <Form.Dropdown
                            placeholder={daily.text}
                            defaultValue={daily.value}
                            selection
                            options={rentalTypeOptions}
                            name="rental_type"
                            onChange={handleDropdown}
                            value={inputs.rental_type}
                            search
                        />
                    </Form.Field>
                </>
            )}
            <Form.Field>
                <Form.Checkbox
                    label="Is Enterprise Rental"
                    onChange={() => handleValueChange('is_enterprise', !inputs.is_enterprise)}
                    checked={inputs.is_enterprise}
                />
            </Form.Field>
            {inputs.is_enterprise ? (
                <Form.Field>
                    <Header as="h5" className={fieldStyles.fieldName}>
                        Pick a Enterprise category
                    </Header>
                    <Form.Dropdown
                        name="enterprise_detail"
                        onChange={handleDropdown}
                        placeholder="Select an enterprise category"
                        options={enterpriseOptions}
                        value={inputs.enterprise_detail}
                        selection
                        search
                        required
                    />
                </Form.Field>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default withRouter(InvoiceForm);
