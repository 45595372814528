import React from 'react';
import { withRouter } from 'react-router-dom';
import Timer from 'react-compound-timer';

const CountdownTimer = (props: any) => {
    const { startTime } = props;

    // calculate elapsed time
    const now = Math.floor(Date.now() / 1000);
    const initialTime = Math.floor(now - startTime) * 1000;

    return (
        <>
            <div style={{ textAlign: 'center', fontSize: '28px', fontWeight: 'bold' }}>
                <Timer initialTime={initialTime} formatValue={(value) => `${value < 10 ? `0${value}` : value}`}>
                    <Timer.Hours /> : <Timer.Minutes /> : <Timer.Seconds />
                </Timer>
                <h3 style={{ textAlign: 'center', padding: '10px' }}>Parking Duration</h3>
            </div>
        </>
    );
};

export default withRouter(CountdownTimer);
